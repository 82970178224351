import React, { PureComponent } from 'react';
import './style.css';

class LoadingLight extends PureComponent {
  render() {
    return <span className='loading-light' />;
  }
}

export default LoadingLight;
