import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './style.css';

const SubscriptionSignupHeader = (props) => (
  <Row className='subscription-signup-header-container'>
    {props.isTopLevelComponent ? (
      <Col
        className={`desktop-sub-signup-header ${
          props.fullBackgroundColor ? 'fullBackground' : ''
        }`}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <h1>{props.title}</h1>
        <p>{props.subtitle}</p>
      </Col>
    ) : null}
    <Col className='stepTitle'>
      <p>
        Step 1 of 3: <br /> Choose Your Bump Box® Subscription
      </p>
    </Col>
    <Col
      className={`desktop-sub-signup-header-actions ${
        props.fullBackgroundColor ? 'fullBackground' : ''
      }`}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <Row>
        <Col
          xs={{ span: 4, offset: 4 }}
          sm={{ span: 4, offset: 4 }}
          md={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div className='toggle-wrapper'>
            <Button
              variant='none'
              className={`toggle monthly ${
                props.showMonthlyPlans ? 'active' : ''
              }`}
              onClick={props.viewMonthlyPlans}
              aria-label='Toggle Monthly Plans'
              aria-controls={
                props.subBoxContainerId
                  ? props.subBoxContainerId
                  : 'sub-wrapper'
              }
            >
              <h2>MONTHLY</h2>
            </Button>
            <Button
              variant='none'
              className={`toggle bundled ${
                !props.showMonthlyPlans ? 'active' : ''
              }`}
              onClick={props.viewBundledPlans}
              aria-label='Toggle Prepaid Plans'
              aria-controls={
                props.subBoxContainerId
                  ? props.subBoxContainerId
                  : 'sub-wrapper'
              }
            >
              <h2>PREPAID</h2>
            </Button>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default React.memo(SubscriptionSignupHeader);
