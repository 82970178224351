import React from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TypeaheadToggleButton = ({ isOpen, onClick }) => (
  <button
    type='button'
    className='toggle'
    onClick={onClick}
    onMouseDown={(e) => {
      e.preventDefault();
    }}
  >
    {isOpen ? (
      <FontAwesomeIcon className='icon-alignment' icon={faAngleUp} />
    ) : (
      <FontAwesomeIcon className='icon-alignment' icon={faAngleDown} />
    )}
  </button>
);

export default TypeaheadToggleButton;
