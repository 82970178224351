import React from 'react';
import { Button } from 'react-bootstrap';
import './style.css';

function determineClassNames(props) {
  if (props.whiteButton) return 'ctaButton whiteButton';
  if (props.largerWidth && !props.blackButton) return 'ctaButton largerWidth';
  if (!props.largerWidth && props.blackButton) return 'ctaButton blackButton';
  if (props.largerWidth && props.blackButton)
    return 'ctaButton largerWidth blackButton';
  return 'ctaButton';
}

const CtaButton = (props) => (
  <Button
    id={props.id}
    disabled={props.disabled}
    className={determineClassNames(props)}
    onClick={props.onClick}
  >
    {props.buttonText}
  </Button>
);

export default CtaButton;
