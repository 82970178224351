import React, { PureComponent } from 'react';
import Logger from '../../../services/Logger';

const WithErrorHandler = (Component) => {
  class ErrorHandler extends PureComponent {
    componentDidCatch = (error, errorInfo) => {
      Logger.error(error, { extra: errorInfo });
    };

    render() {
      return <Component {...this.props} />; // eslint-disable-line
    }
  }
  return ErrorHandler;
};

export default WithErrorHandler;
