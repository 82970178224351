import React from 'react';
import { Row, Col } from 'react-bootstrap';

import './style.css';

export default function CompanyLocations() {
  return (
    <Row className='companyLocations'>
      <Col xs={12} sm={12} md={12} lg={12}>
        <h3 className='mb-4'>Our Locations</h3>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} className='mb-4 companyLocations-map'>
        <h4>Main Office</h4>
        <p>
          7719 N Pioneer Lane
          <br />
          Peoria, IL 61615
          <br />
        </p>
        <iframe
          title='map'
          frameBorder={0}
          src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJscRqM0dbCogRvwj0Kbykb3w&key=AIzaSyD493Brq16lX5An0Z4W2qx9SAv0G0G8xts'
          allowFullScreen
        />
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} className='companyLocations-map'>
        <h4>Daytona Office</h4>
        <p>
          1635 S. Ridgewood Ave, Unit 208 & 209
          <br />
          South Daytona, FL 32119
          <br />
        </p>
        <iframe
          title='map'
          frameBorder={0}
          src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJgcAiWsDZ5ogRn341UdLvPz8&key=AIzaSyD493Brq16lX5An0Z4W2qx9SAv0G0G8xts'
          allowFullScreen
        />
      </Col>
    </Row>
  );
}
