import React from 'react';
import { Col, Form } from 'react-bootstrap';
import './style.css';

const CustomizeSubDropdown = (props) => (
  <Col sm={8} md={8} lg={8} className='customizeBoxDropdownCol'>
    Select the subscription you would like to customize
    <Form.Group controlId='customizeBoxDropdown'>
      <Form.Control
        name='customizeBoxDropdown'
        aria-label='customizeBoxDropdown'
        value={props.subToCustomize}
        onChange={(e) => props.handleSubCustomizeSelect(e)}
        as='select'
        aria-describedby='customizeBoxDropdown-feedback'
      >
        {props.eligibleSubscriptions
          ? props.eligibleSubscriptions.map((sub) => (
              <option key={sub._id} value={sub._id}>
                {`${sub.plan.title} - ${sub.shippingAddress.name.first}
          ${sub.shippingAddress.name.last.charAt(0)}.`}
              </option>
            ))
          : null}
      </Form.Control>
    </Form.Group>
  </Col>
);

export default CustomizeSubDropdown;
