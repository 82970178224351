const pixelId = '10187636';
const projectId = '10000';

window.dotq = window.dotq || [];
function dotq(data) {
  window.dotq.push(data);
}

const purchaseComplete = (purchaseData) => {
  dotq({
    projectId,
    properties: {
      pixelId,
      qstrings: {
        et: 'custom',
        ea: 'Purchase',
        el: 'Purchase',
        gv: parseFloat(purchaseData.total, 10).toString(),
      },
    },
  });
};

const addToCart = (itemToAddWithData) => {
  dotq({
    projectId,
    properties: {
      pixelId,
      qstrings: {
        et: 'custom',
        ea: 'AddToCart',
        ev: itemToAddWithData.eventValue,
        el: 'Add to cart',
        gv: `${itemToAddWithData.amountToCharge}`,
        // eslint-disable-next-line camelcase
        product_id: itemToAddWithData.productId,
      },
    },
  });
};

const viewedProduct = (productData, isProduct) => {
  let eventValue = 'product';
  let conversionValue;
  // eslint-disable-next-line camelcase
  const product_id = productData._id;
  if (!isProduct) {
    eventValue = 'subscription';
    conversionValue = productData.costWithCoupon || productData.actualCost;
  } else {
    conversionValue = productData.actualCost
      ? productData.actualCost
      : productData.originalCost;
  }
  dotq({
    projectId,
    properties: {
      pixelId,
      qstrings: {
        et: 'custom',
        ea: 'ViewProduct',
        ev: eventValue,
        el: 'Viewed product',
        gv: `${conversionValue}`,
        // eslint-disable-next-line camelcase
        product_id,
      },
    },
  });
};

export default {
  purchaseComplete,
  addToCart,
  viewedProduct,
};
