import React from 'react';
import './style.css';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import ExternalImage from '../product/ExternalImage';
import MoneyUtils from '../../services/MoneyUtils';

const ProductDetailModal = (props) => {
  const product = props.products[props.productToShow];
  const { buttonText, isButtonDisabled } = props;
  const imageUrl =
    product.media && product.media.length
      ? product.media[0].url
      : 'https://s3.amazonaws.com/bitsy-product-images/default-product-image.png';
  return (
    <Modal
      show={props.showProductDetails}
      onHide={props.closeModal}
      className='productDetailModal'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Row>
          <Col md={6} lg={6}>
            <div>
              <ExternalImage
                src={imageUrl}
                alt={product.name}
                title={`${product.name} Photo`}
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <Row>
              <Col className='h4'>{product.name}</Col>
            </Row>
            {props.showSlashedPrice ? (
              <Row>
                <Col
                  xs={{ span: 4, offset: 2 }}
                  sm={{ span: 4, offset: 2 }}
                  md={{ span: 2, offset: 0 }}
                  lg={{ span: 2, offset: 0 }}
                >
                  <div className='priceSlashed'>
                    ${MoneyUtils.formatAsMoney(product.originalCost)}
                  </div>
                </Col>
                <Col xs={4} sm={4} md={8} lg={8} className='productPrice'>
                  ${MoneyUtils.formatAsMoney(product.actualCost)}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={4} sm={4} md={8} lg={8} className='productPrice'>
                  ${MoneyUtils.formatAsMoney(product.actualCost)}
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className='center-align'>
            <Button
              disabled={isButtonDisabled}
              onClick={props.buttonAction}
              variant='primary'
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <div
              className='close-details center-align'
              onClick={props.closeModal}
              role='button'
              tabIndex={0}
              onKeyDown={props.closeModal}
            >
              Close Details
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ProductDetailModal);
