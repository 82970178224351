import gql from 'graphql-tag';

// QUERIES
const allPressReleasesQuery = gql`
  query allPressReleases {
    allPressReleases {
      _id
      publishedOn
      isSelfPublished
      title
      mediaUrl
      pressUrl
      providers
    }
  }
`;

const cartItemCountQuery = gql`
  query cartItemCount($customerIdForAdmin: String) {
    cartItemCount(customerIdForAdmin: $customerIdForAdmin) {
      _id
      numberOfItems
      hasSubscription
    }
  }
`;

const cartInfo = gql`
  mutation cartInfo(
    $fetchCartWithPendingPurchase: Boolean
    $customerIdForAdmin: String
    $referralCode: String
    $streetAddress: String
    $aptDetails: String
    $city: String
    $state: NonEmptyString
    $zipCode: NonEmptyString
    $country: NonEmptyString
  ) {
    cartInfo(
      fetchCartWithPendingPurchase: $fetchCartWithPendingPurchase
      customerIdForAdmin: $customerIdForAdmin
      referralCode: $referralCode
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
    ) {
      _id
      hasPointsApplied
      userId
      purchaser {
        guestCheckoutEmail
        email
        phoneNumber
        name {
          first
          last
        }
        isSubscribedToNewsletter
        isAgreedToTermsAndConditions
      }
      babyBirthday
      dueDateUnknown
      babyGender
      pregnancyStatus
      subscriberDiscountAmount
      giftMessage
      shippingInsurance {
        cost
      }
      isCartDigitalOnly
      eligibleForReferral
      productsAndDetails {
        _id
        isAddedCouponProduct
        isBundledGWPProduct
        product {
          _id
          name
          brand
          actualCost
          originalCost
          showVariationsAsMultipleItems
          isDropShip
          isComposite
          sku
          urlName
          tags {
            _id
            description
            isInternal
            bitsyParent {
              _id
              description
            }
            bumpChildren {
              _id
              description
            }
          }
          media {
            color
            url
            variation
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
            bundledGWPCost
          }
        }
        variation
        color
        quantity
        amountToCharge
        promoItem
      }
      coupon {
        _id
        addedProductsOptions {
          _id
          name
          sku
          media {
            color
            url
            variation
            altTag
          }
          totalQuantity
          isOutOfStock
          originalCost
        }
        code
        discountPercentage
        discountAmount
        freeShipping
        finalCost
        exclusivePlans {
          _id
        }
      }
      giftCards {
        code
        originalAmount
        amountRemaining
      }
      subscription {
        isGift
        plan {
          _id
          title
          costWithCoupon
          sku
          actualCost
          originalCost
          actualCostAsShipping
          type
          months
          image
          productUrlPrefix
          linkUrl
          imageUrl
          couponToAutoPopulate {
            _id
          }
          bulletPoints {
            point1
          }
        }
        babyBirthday
      }
      shippingAddress {
        name {
          first
          last
        }
        phoneNumber
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
        shippingRegion
      }
      dateToEmail
      firstBoxShippingAddress {
        name {
          first
          last
        }
        phoneNumber
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
      }
      firstBoxShippingMethod
      isBrownBoxSelected
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
        shippingInsuranceCost
      }
    }
  }
`;

const getSubscriptionQuery = gql`
  query getSubscription($customerIdForAdmin: String, $subscriptionId: String!) {
    getSubscription(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
    ) {
      _id
      status
      isPurchaser
      giftEmailStatus
      isGift
      shippingInsurance {
        cost
      }
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        sku
        actualCost
        originalCost
        actualCostAsShipping
        type
        linkUrl
        imageUrl
      }
      babyGender
      babyBirthday
      pregnancyStatus
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
      purchaser {
        email
      }
    }
  }
`;

const subscriptionInProgressQuery = gql`
  query subscriptionInProgress(
    $customerIdForAdmin: String!
    $isGift: Boolean!
  ) {
    subscriptionInProgress(
      customerIdForAdmin: $customerIdForAdmin
      isGift: $isGift
    ) {
      _id
      status
      giftEmailStatus
      isGift
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
        actualCostAsShipping
      }
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
      coupon {
        code
        discountPercentage
        discountAmount
      }
      isBrownBoxSelected
      giftCards {
        code
        originalAmount
        amountRemaining
      }
    }
  }
`;

const productQuery = gql`
  query productInfo(
    $productId: String
    $productUrlName: String
    $customerIdForAdmin: String
  ) {
    productInfo(
      productId: $productId
      productUrlName: $productUrlName
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      name
      description
      metaDescription
      descriptionComponents
      sku
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      tagsToDisplay
      tagToRenderUnder
      freeReturns
      discountDisplayText
      brand
      shouldShowAddToNextBoxForProduct
      isOutOfStock
      media {
        color
        url
        variation
        altTag
      }
      tags {
        ${/* _id here will result in null tags */ ''}
        description
        isInternal
        bitsyParent {
          description
        }
        bumpParent {
          description
        }
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
        dmeAddOnCost
      }
      quantityDictionaryAsString
      createdAt
      subscriptionPlan {
        _id
        provider
        months
        title
        orderToShow
        costWithCoupon
          sku
        actualCost
        originalCost
        tagType
        isHidden
        minimumMonths
        actualCostAsShipping
        image
          productUrlPrefix
          linkUrl
          imageUrl
        couponToAutoPopulate {
          _id
          code
          discountPercentage
          discountAmount
          freeShipping
          addedProducts {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
          addedProductsOptions {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
        }
        bulletPoints {
          point1
          point2
          point3
          point4
        }
      }
      discounted
      error {
        key
        value
      }
    }
  }
`;

const getProductsQuery = gql`
  query getProducts(
    $sortFilter: String
    $searchText: String
    $tag: String
    $tagDescription: String
    $minCost: Float
    $maxCost: Float
    $offset: Int
    $limit: Int
    $provider: String
    $customerIdForAdmin: String
  ) {
    getProducts(
      sortFilter: $sortFilter
      searchText: $searchText
      tag: $tag
      tagDescription: $tagDescription
      minCost: $minCost
      maxCost: $maxCost
      offset: $offset
      limit: $limit
      provider: $provider
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      name
      isActive
      description
      ingredients
      originalCost
      actualCost
      urlName
      averageRating
      quantityDictionaryAsString
      totalQuantity
      variationName
      showColorsAsMultipleItems
      showVariationsAsMultipleItems
      isDropShip
      isComposite
      isOutOfStock
      discountDisplayText
      brand
      sku
      media {
        color
        url
        variation
        altTag
      }
      tags {
        _id
        description
        isInternal
        bitsyParent {
          _id
          description
        }
        bumpParent {
          _id
          description
        }
      }
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
        dmeAddOnCost
      }
      subscriptionPlan {
        _id
        provider
        months
        title
        orderToShow
        costWithCoupon
        sku
        actualCost
        originalCost
        tagType
        isHidden
        minimumMonths
        actualCostAsShipping
        image
        productUrlPrefix
        linkUrl
        imageUrl
        couponToAutoPopulate {
          _id
          code
          discountPercentage
          discountAmount
          freeShipping
          addedProducts {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
          addedProductsOptions {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
        }
        bulletPoints {
          point1
          point2
          point3
          point4
        }
      }
      discounted
      totalNumberOfProducts
      __typename @skip(if: true)
    }
  }
`;

const availablePointsQuery = gql`
  query availablePointsQuery($customerIdForAdmin: String) {
    availablePoints(customerIdForAdmin: $customerIdForAdmin) {
      _id
      availablePoints
    }
  }
`;

const userInfoQuery = gql`
  query userInfoQuery(
    $customerIdForAdmin: String
    $includeDmeFormInfo: Boolean
  ) {
    userInfo(
      customerIdForAdmin: $customerIdForAdmin
      includeDmeFormInfo: $includeDmeFormInfo
    ) {
      _id
      email
      phoneNumber
      accountType
      activeCompanies
      subscriberDiscountAmount
      name {
        first
        last
      }
      availablePoints
      points {
        pointsDifference
        expirationDate
        usedOrRefundedDate
      }
      giftCards {
        code
        originalAmount
        amountRemaining
        providers
      }
      addresses {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      creditCards {
        brand
        last4
        expMonth
        expYear
      }
      isVerified
      referralCode
      purchases {
        _id
        confirmationCode
        provider
        paidStatus
        shippingInsurance {
          cost
        }
        costs {
          subTotal
          pointsDiscount
          discount
          shipping
          taxesAndFees
          overallTotal
        }
        productsAndDetails {
          _id
          product {
            _id
            name
            brand
            urlName
            isActive
            isTrash
            isOnBumpBoxes
            isOnBitsyBoxes
            media {
              color
              url
            }
            tags {
              _id
              description
              isInternal
              bitsyParent {
                _id
                description
              }
              bumpChildren {
                _id
                description
              }
            }
            quantityDetails {
              variation
              color
              quantity
            }
          }
          variation
          color
          quantity
          amountToCharge
        }
        subscription {
          isGift
          lastOrderShipStationTrackingNumber
          lastOrderShipStationCarrierCode
          renewOn
          pregnancyStatus
          shippingInsurance {
            cost
          }
          recipientData {
            name {
              first
              last
            }
            email
          }
          plan {
            _id
            title
            type
            months
            image
            linkUrl
            imageUrl
          }
        }
        refunds {
          returnMethod
          giftCard {
            code
          }
          amountRefunded
          dateRefunded
        }
        order {
          _id
          status
          shipStationTrackingNumber
          shipStationCarrierCode
          shippingAddress {
            country
          }
          billingAddress {
            city
            state
            zipCode
          }
        }
        createdAt
      }
      subscriptions {
        _id
        giftEmailStatus
        status
        confirmationCode
        lastOrderShipStationTrackingNumber
        lastOrderShipStationCarrierCode
        provider
        renewOn
        pregnancyStatus
        scheduledCancel
        numberOfOrders
        credits
        extensions
        shippingInsurance {
          cost
        }
        recurringAddOns {
          amountToCharge
          product {
            _id
            name
            isActive
            description
            ingredients
            originalCost
            actualCost
            urlName
            averageRating
            totalQuantity
            isDropShip
            isComposite
            tagsToDisplay
            tagToRenderUnder
            discounted
            brand
            ownPromoData {
              isSubPromo
              isProductPromo
              isEverythingPromo
              isPostPurchasePromo
              isPreCheckoutPromo
              isVitaminPromo
              expiresOn
              validOn
              isAddOn
              isRecurringAddOn
              cost
              header
              subHeader
              buttonText
              promoDescription
            }
            media {
              color
              url
              altTag
            }
            tags {
              _id
              description
              isInternal
              bitsyParent {
                _id
                description
              }
              bumpChildren {
                _id
                description
              }
            }
          }
        }
        plan {
          _id
          provider
          months
          title
          type
          costWithCoupon
          sku
          actualCost
          originalCost
          minimumMonths
          image
          linkUrl
          imageUrl
        }
        babyGender
        babyBirthday
        monthsToSkip
        isSkipCurrentMonth
        currentOrderMonth
        recipientData {
          name {
            first
            last
          }
          email
          dateToEmail
        }
        firstBoxShippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        firstBoxShippingMethod
        shippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        billingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        paymentMethod {
          brand
          last4
          expMonth
          expYear
        }
        canMakePayment
        usedCoupons {
          _id
        }
        addOnsWithDetails {
          quantity
          replacementItem
          color
          variation
          amountToCharge
          product {
            _id
            name
            isActive
            description
            ingredients
            originalCost
            actualCost
            urlName
            averageRating
            isDropShip
            isComposite
            tagsToDisplay
            tagToRenderUnder
            discounted
            brand
            isOutOfStock
            totalQuantity
            tags {
              _id
              description
              isInternal
            }
            media {
              color
              url
              altTag
            }
          }
        }
      }
      reviews {
        _id
        product {
          _id
          name
          isOnBumpBoxes
          isOnBitsyBoxes
          media {
            color
            url
          }
        }
        stars
        description
        isActive
        createdAt
        updatedAt
      }
      registry {
        _id
        subscription {
          _id
        }
      }
      insurance {
        dmeFormStep
        dateOfBirth
        insuranceType
        dueDate
        obgynPhoneNumber
        memberId
        pumpAddress {
          name {
            first
            last
          }
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        obgynFullName {
          first
          last
        }
        isAgreedToMomEssentials
      }
      matchedPartner {
        name
        urlName
        isOnBumpBoxes
        isPartnerApiEnabled
      }
      isAgreedToTermsAndConditions
      providers
      subscriptionsWithFailedPurchase
    }
  }
`;

const allShippingOptionsQuery = gql`
  query allShippingOptions {
    allShippingOptions {
      _id
      title
      cost
      isInternational
      timeToShipInBusinessDays
      shippingRegion
    }
  }
`;

const allPlansQuery = gql`
  query allPlans {
    allPlans {
      _id
      provider
      months
      title
      orderToShow
      costWithCoupon
      sku
      actualCost
      originalCost
      tagType
      isHidden
      minimumMonths
      bulletPoints {
        point1
        point2
        point3
        point4
      }
    }
  }
`;

const plansForProviderQuery = gql`
  query plansForProvider($provider: String!) {
    plansForProvider(provider: $provider) {
      _id
      provider
      months
      title
      type
      orderToShow
      costWithCoupon
      sku
      actualCost
      originalCost
      tagType
      isHidden
      minimumMonths
      selectSubMessage
      circleMessage
      isDefault
      couponToAutoPopulate {
        _id
        code
        discountPercentage
        discountAmount
        freeShipping
        addedProducts {
          _id
          name
          sku
          description
          descriptionComponents
          ingredients
          colors
          variations
          originalCost
          actualCost
          urlName
          brand
          media {
            url
          }
        }
        addedProductsOptions {
          _id
          name
          sku
          description
          descriptionComponents
          ingredients
          colors
          variations
          originalCost
          actualCost
          urlName
          brand
          media {
            url
          }
        }
      }
      bulletPoints {
        point1
        point2
        point3
        point4
      }
      highlights {
        order
        text
        className
        showForReferralsOrNotOrBoth
      }
    }
  }
`;

const orderInfoQuery = gql`
  query orderInfo(
    $orderConfirmationCode: String!
    $customerIdForAdmin: String
  ) {
    orderInfo(
      orderConfirmationCode: $orderConfirmationCode
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      confirmationCode
      shipStationTrackingNumber
      shipStationCarrierCode
      billingAddress {
        name {
          first
        }
      }
      purchaser {
        _id
        referralCode
      }
    }
  }
`;

const purchaseInfoQuery = gql`
  query purchaseInfo($purchaseId: String!, $customerIdForAdmin: String) {
    purchaseInfo(
      purchaseId: $purchaseId
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      shippingInsurance {
        cost
      }
      productsAndDetails {
        product {
          _id
          name
        }
      }
      subscription {
        recurringAddOns {
          amountToCharge
        }
        plan {
          _id
          provider
          months
          title
          costWithCoupon
          sku
          actualCost
          originalCost
          type
        }
        shippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        billingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        recipientData {
          name {
            first
            last
          }
          email
          dateToEmail
        }
        paymentMethod {
          brand
          last4
          expMonth
          expYear
        }
        firstBoxShippingMethod
        isGift
        isBrownBoxSelected
      }
      order {
        _id
        shippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        billingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        recipientData {
          name {
            first
            last
          }
          email
          dateToEmail
        }
        paymentMethod {
          brand
          last4
          expMonth
          expYear
        }
        firstBoxShippingMethod
        isGift
        actualCost
        isBrownBoxSelected
        provider
        months
        title
      }
      costs {
        subTotal
        pointsDiscount
        discount
        shipping
        taxesAndFees
        overallTotal
        shippingInsuranceCost
      }
      confirmationCode
      purchaser {
        _id
        isTemporary
        referralCode
        accountType
      }
      paidStatus
    }
  }
`;

const getCurrentShopTag = gql`
  query getCurrentShopTag($description: String!) {
    getCurrentShopTag(description: $description) {
      description
      bitsyParent {
        description
      }
      bumpParent {
        description
      }
      bitsyChildren {
        description
      }
      bumpChildren {
        description
      }
    }
  }
`;

const getPromoProductsForCart = gql`
  query getPromoProductsForCart($tempCart: String) {
    getPromoProductsForCart(tempCart: $tempCart) {
      _id
      name
      description
      descriptionComponents
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      freeReturns
      brand
      isOutOfStock
      media {
        color
        url
        variation
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
      }
      error {
        key
        value
      }
    }
  }
`;

const getPromoProductsForPostPurchase = gql`
  query getPromoProductsForPostPurchase(
    $purchaseId: String
    $customerIdForAdmin: String
  ) {
    getPromoProductsForPostPurchase(
      purchaseId: $purchaseId
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      name
      description
      descriptionComponents
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      freeReturns
      discounted
      brand
      isOutOfStock
      media {
        color
        url
        variation
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
      }
      error {
        key
        value
      }
    }
  }
`;

const coregOffers = gql`
  query coregOffers(
    $orderConfirmationCode: String
    $customerIdForAdmin: String
  ) {
    coregOffers(
      orderConfirmationCode: $orderConfirmationCode
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      name
      imageUrl
      description1
      description2
      agency
      isSelected
      ctaButtonText
      ctaButtonSelectedText
      trustedform_cert_url
      policyTextAndUrls {
        text
        link
        styling
      }
      error {
        key
        value
      }
      surveyQuestions {
        questionId
        questionText
        type
        responses {
          displayText
          responseCode
        }
      }
      recurringAddOnProduct {
        _id
      }
      surveyImageUrl
      directUrl
    }
  }
`;

// may need to edit this -- not sure what info we need yet
const getBreastPumpProducts = gql`
  query getBreastPumpProducts {
    getBreastPumpProducts {
      _id
      name
      brand
      description
      descriptionComponents
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      freeReturns
      media {
        color
        url
        variation
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
      }
      error {
        key
        value
      }
    }
  }
`;

const getCompanyByCode = gql`
  query getCompanyByCode($companyCode: String!) {
    getCompanyByCode(companyCode: $companyCode) {
      _id
      companyCode
      name
      isActive
      paymentType
      corporatePortalHeroCopyLine1
      corporatePortalHeroCopyLine2
      welcomeTitle
      welcomeText
      shippingOptions {
        _id
        title
        cost
        isInternational
        timeToShipInBusinessDays
      }
      purchases {
        provider
        confirmationCode
        createdAt
        numberOfPaymentAttempts
        stripeDeclineCode
        paidStatus
        file
        paymentType
      }
      billingAddress {
        name {
          first
          last
        }
        country
        state
        zipCode
        city
        streetAddress
        aptDetails
      }
      error {
        key
        value
      }
    }
  }
`;

const getCorporateProducts = gql`
  query getCorporateProducts($companyCode: String!) {
    getCorporateProducts(companyCode: $companyCode) {
      _id
      name
      description
      ingredients
      originalCost
      actualCost
      urlName
      averageRating
      quantityDictionaryAsString
      totalQuantity
      variationName
      isDropShip
      isComposite
      isOutOfStock
      media {
        color
        url
        variation
        altTag
      }
      tags {
        _id
        description
        isInternal
        bitsyParent {
          _id
          description
        }
        bumpParent {
          _id
          description
        }
      }
    }
  }
`;

const validateCompanySignUpUrl = gql`
  query validateCompanySignUpUrl($signUpCode: String!) {
    validateCompanySignUpUrl(signUpCode: $signUpCode) {
      _id
      name
      companyCode
      error {
        key
        value
      }
    }
  }
`;

const addEmailToMasterList = gql`
  query addEmailToMasterList($email: String!, $provider: String!) {
    addEmailToMasterList(email: $email, provider: $provider) {
      _id
    }
  }
`;

const sendDueDateCalculatorKlaviyoEvents = gql`
  query sendDueDateCalculatorKlaviyoEvents(
    $email: String!
    $provider: String!
    $dueDate: String!
    $lastPeriodDate: String!
    $conceptionDate: String!
    $testDate: String!
    $firstHeartBeat: String!
    $weeksPregnant: String!
    $babysFirstMove: String!
    $endTrimester1: String!
    $endTrimester2: String!
    $isSubscribedToNewsletter: Boolean!
  ) {
    sendDueDateCalculatorKlaviyoEvents(
      email: $email
      provider: $provider
      dueDate: $dueDate
      lastPeriodDate: $lastPeriodDate
      conceptionDate: $conceptionDate
      testDate: $testDate
      firstHeartBeat: $firstHeartBeat
      weeksPregnant: $weeksPregnant
      babysFirstMove: $babysFirstMove
      endTrimester1: $endTrimester1
      endTrimester2: $endTrimester2
      isSubscribedToNewsletter: $isSubscribedToNewsletter
    ) {
      _id
    }
  }
`;

const addEmailToBreastPumpMasterList = gql`
  query addEmailToBreastPumpMasterList(
    $email: String!
    $provider: String!
    $dueDate: String
    $phoneNumber: String
  ) {
    addEmailToBreastPumpMasterList(
      email: $email
      provider: $provider
      dueDate: $dueDate
      phoneNumber: $phoneNumber
    ) {
      _id
    }
  }
`;

const addEmailToGiveawayList = gql`
  query addEmailToGiveawayList(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $provider: String!
    $streetAddress: String
    $aptDetails: String
    $city: String
    $state: String
    $zipCode: String
    $country: String
    $dueDate: String
    $isOptedInSMS: Boolean
    $isSubscribedToNewsletter: Boolean
    $giveawayName: String
    $shoppingFor: String
  ) {
    addEmailToGiveawayList(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      provider: $provider
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
      dueDate: $dueDate
      isOptedInSMS: $isOptedInSMS
      isSubscribedToNewsletter: $isSubscribedToNewsletter
      giveawayName: $giveawayName
      shoppingFor: $shoppingFor
    ) {
      _id
    }
  }
`;

const getSubscriptionAddOnProducts = gql`
  query getSubscriptionAddOnProducts(
    $customerIdForAdmin: String
    $subscriptionId: String!
  ) {
    getSubscriptionAddOnProducts(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
    ) {
      _id
      name
      description
      descriptionComponents
      sku
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      tagsToDisplay
      tagToRenderUnder
      freeReturns
      brand
      shouldShowAddToNextBoxForProduct
      isOutOfStock
      media {
        color
        url
        variation
        altTag
      }
      tags {
        description
        isInternal
        bitsyParent {
          _id
          description
        }
        bumpChildren {
          _id
          description
        }
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      createdAt
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
      }
      subscriptionPlan {
        _id
        provider
        months
        title
        orderToShow
        costWithCoupon
        actualCost
        originalCost
        tagType
        isHidden
        minimumMonths
        actualCostAsShipping
        couponToAutoPopulate {
          _id
          code
          discountPercentage
          discountAmount
          freeShipping
          addedProducts {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
          addedProductsOptions {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
        }
        bulletPoints {
          point1
          point2
          point3
          point4
        }
      }
      discounted
      error {
        key
        value
      }
    }
  }
`;

const getSubscriptionAddOnProductsForLandingPage = gql`
  query getSubscriptionAddOnProductsForLandingPage {
    getSubscriptionAddOnProductsForLandingPage {
      _id
      name
      description
      descriptionComponents
      sku
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      tagsToDisplay
      tagToRenderUnder
      freeReturns
      brand
      shouldShowAddToNextBoxForProduct
      isOutOfStock
      media {
        color
        url
        variation
        altTag
      }
      tags {
        _id
        description
        isInternal
        bitsyParent {
          _id
          description
        }
        bumpChildren {
          _id
          description
        }
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      createdAt
      reviews {
        _id
        stars
        description
        isActive
        updatedAt
        createdAt
        reviewer {
          name {
            first
            last
          }
        }
      }
      subscriptionPlan {
        _id
        provider
        months
        title
        orderToShow
        costWithCoupon
        actualCost
        originalCost
        tagType
        isHidden
        minimumMonths
        actualCostAsShipping
        couponToAutoPopulate {
          _id
          code
          discountPercentage
          discountAmount
          freeShipping
          addedProducts {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
          addedProductsOptions {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
        }
        bulletPoints {
          point1
          point2
          point3
          point4
        }
      }
      discounted
      error {
        key
        value
      }
    }
  }
`;

const getRegistryInfo = gql`
  query getRegistryInfo($registryId: String, $shareCode: String) {
    getRegistryInfo(registryId: $registryId, shareCode: $shareCode) {
      _id
      name {
        first
        last
      }
      subscription {
        babyGender
        babyBirthday
      }
      sponsors {
        name {
          first
          last
        }
        email
        numOfBoxesSponsored
        giftMessage
        thankYouCreatedAt
        isAnonymous
        createdAt
      }
      displayGender
      displayDueDate
      numOfBoxesSponsored
      daysToGo
      registryProgress
      shareCode
    }
  }
`;

const getRegistryInfoForOwner = gql`
  query getRegistryInfoForOwner($customerIdForAdmin: String) {
    getRegistryInfoForOwner(customerIdForAdmin: $customerIdForAdmin) {
      _id
      name {
        first
        last
      }
      subscription {
        _id
        babyGender
        babyBirthday
        shippingAddress {
          name {
            first
            last
          }
          streetAddress
          aptDetails
          city
          state
          zipCode
          country
        }
      }
      sponsors {
        _id
        name {
          first
          last
        }
        email
        numOfBoxesSponsored
        giftMessage
        thankYouCreatedAt
        isAnonymous
        createdAt
      }
      displayGender
      displayDueDate
      numOfBoxesSponsored
      daysToGo
      registryProgress
      shareCode
    }
  }
`;

const searchForRegistries = gql`
  query searchForRegistries($firstName: String, $lastName: String) {
    searchForRegistries(firstName: $firstName, lastName: $lastName) {
      _id
      name {
        first
        last
      }
      subscription {
        babyGender
        babyBirthday
        shippingAddress {
          state
        }
      }
      displayGender
      displayDueDate
      shareCode
    }
  }
`;

const getRegistryProduct = gql`
  query getRegistryProduct {
    getRegistryProduct {
      _id
      name
      description
      actualCost
      originalCost
      media {
        color
        url
        variation
      }
      error {
        key
        value
      }
    }
  }
`;

const getActiveSaleEvents = gql`
  query getActiveSaleEvents {
    getActiveSaleEvents {
      _id
      siteLabelText
      includedProducts {
        _id
      }
    }
  }
`;

const validateRegistryAddress = gql`
  query validateRegistryAddress(
    $streetAddress: String!
    $aptDetails: String
    $city: String!
    $state: String!
    $zipCode: String!
    $country: String!
  ) {
    validateRegistryAddress(
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
    ) {
      suggestedAddresses {
        streetAddress
        city
        state
        zipCode
      }
    }
  }
`;

const getSubscriptionCouponsByCode = gql`
  query getSubscriptionCouponsByCode($code: String) {
    getSubscriptionCouponsByCode(code: $code) {
      _id
      planInfoOverride {
        costWithCoupon
        selectSubMessage
      }
      addedProducts {
        _id
        sku
        name
      }
      addedProductsOptions {
        _id
        sku
        name
      }
      exclusivePlans {
        _id
      }
    }
  }
`;
const allBabyNames = gql`
  query allBabyNames($gender: String, $offset: Int, $limit: Int) {
    allBabyNames(gender: $gender, offset: $offset, limit: $limit) {
      _id
      name
      gender
      ranking
      possibleBrothers
      possibleSisters
      createdOn
      updatedOn
      origin
      meaning
      pronunciation
    }
  }
`;

// MUTATIONS
const addItemToCart = gql`
  mutation addItemToCart(
    $customerIdForAdmin: String
    $planId: String
    $product: String
    $variation: String
    $color: String
    $quantity: Int
    $promoItem: Boolean
    $fetchCartWithPendingPurchase: Boolean
    $subscriptionCouponFromURLAsId: String
    $isBreastPump: Boolean
    $isDmeAddOn: Boolean
  ) {
    addItemToCart(
      customerIdForAdmin: $customerIdForAdmin
      planId: $planId
      product: $product
      variation: $variation
      color: $color
      quantity: $quantity
      promoItem: $promoItem
      fetchCartWithPendingPurchase: $fetchCartWithPendingPurchase
      subscriptionCouponFromURLAsId: $subscriptionCouponFromURLAsId
      isBreastPump: $isBreastPump
      isDmeAddOn: $isDmeAddOn
    ) {
      _id
      numberOfItems
      hasSubscription
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          sku
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
        }
        variation
        color
        quantity
        amountToCharge
        promoItem
        isBreastPump
      }
      subscription {
        isGift
        plan {
          _id
          title
          costWithCoupon
          sku
          actualCost
          originalCost
          actualCostAsShipping
          months
          productUrlPrefix
          linkUrl
          imageUrl
        }
        babyBirthday
      }
      coupon {
        _id
        code
        discountPercentage
        discountAmount
        freeShipping
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const swapGWPItem = gql`
  mutation swapGWPItem($customerIdForAdmin: String, $productIds: [String]) {
    swapGWPItem(
      customerIdForAdmin: $customerIdForAdmin
      productIds: $productIds
    ) {
      _id
      numberOfItems
      hasSubscription
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          urlName
          actualCost
          sku
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
            bundledGWPCost
          }
        }
        variation
        color
        quantity
        amountToCharge
        promoItem
        isBreastPump
        isAddedCouponProduct
        isBundledGWPProduct
      }
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          sku
          actualCost
          originalCost
          actualCostAsShipping
          linkUrl
          imageUrl
        }
        babyBirthday
      }
      coupon {
        _id
        code
        discountPercentage
        discountAmount
        freeShipping
        addedProductsOptions {
          _id
          sku
          name
        }
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const signInUser = gql`
  mutation signInUser(
    $provider: String!
    $tempCart: String
    $email: String!
    $password: String!
    $isSigningInAsAdmin: Boolean
    $cartId: String
    $googleToken: String
  ) {
    signInUser(
      provider: $provider
      tempCart: $tempCart
      email: $email
      password: $password
      isSigningInAsAdmin: $isSigningInAsAdmin
      cartId: $cartId
      googleToken: $googleToken
    ) {
      _id
      isSubscribedToNewsletter
      isOptedInSMS
      email
      name {
        first
        last
      }
      phoneNumber
      accountType
      lastPasswordChange
      isVerified
      token
      referralCode
      points {
        pointsDifference
      }
      error {
        key
        value
      }
    }
  }
`;

const signOutUser = gql`
  mutation signOutUser {
    signOutUser {
      _id
    }
  }
`;

const signUpUser = gql`
  mutation signUpUser(
    $provider: String!
    $tempCart: String
    $firstName: String
    $lastName: String
    $email: String!
    $phoneNumber: String!
    $password: String!
    $isSubscribedToNewsletter: Boolean!
    $isAgreedToTermsAndConditions: Boolean!
    $cartId: String
    $doNotSendPasswordEmail: Boolean
    $companyCode: String
    $dueDate: String
    $isOptedInSMS: Boolean
    $dmeFormStep: String
    $signUpFormName: String
  ) {
    signUpUser(
      provider: $provider
      tempCart: $tempCart
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      isSubscribedToNewsletter: $isSubscribedToNewsletter
      isAgreedToTermsAndConditions: $isAgreedToTermsAndConditions
      cartId: $cartId
      doNotSendPasswordEmail: $doNotSendPasswordEmail
      companyCode: $companyCode
      dueDate: $dueDate
      isOptedInSMS: $isOptedInSMS
      dmeFormStep: $dmeFormStep
      signUpFormName: $signUpFormName
    ) {
      _id
      isSubscribedToNewsletter
      isOptedInSMS
      email
      name {
        first
        last
      }
      isVerified
      token
      referralCode
      error {
        key
        value
      }
    }
  }
`;

const refreshUserToken = gql`
  query refreshUserToken($provider: String!) {
    refreshUserToken(provider: $provider) {
      _id
      token
      name {
        first
        last
      }
    }
  }
`;

const confirmUserPassword = gql`
  mutation confirmUserPassword(
    $provider: String!
    $email: String!
    $password: String!
  ) {
    confirmUserPassword(
      provider: $provider
      email: $email
      password: $password
    ) {
      _id
      email
      error {
        key
        value
      }
    }
  }
`;

const getEmptyUser = gql`
  mutation getEmptyUser($provider: String!, $tempCart: String) {
    getEmptyUser(provider: $provider, tempCart: $tempCart) {
      _id
      token
      error {
        key
        value
      }
    }
  }
`;

const processPendingPayments = gql`
  mutation processPendingPayments($purchaseId: String) {
    processPendingPayments(purchaseId: $purchaseId) {
      _id
      error {
        key
        value
      }
    }
  }
`;

const addAllShippingData = gql`
  mutation addAllShippingData(
    $customerIdForAdmin: String
    $shippingFirstName: NonEmptyString!
    $shippingLastName: NonEmptyString!
    $shippingStreetAddress: NonEmptyString!
    $shippingAptDetails: String
    $shippingCity: NonEmptyString!
    $shippingState: NonEmptyString!
    $shippingZipCode: NonEmptyString!
    $shippingCountry: NonEmptyString!
    $isBrownBoxSelected: Boolean!
    $firstBoxShipping: String!
    $isVerified: Boolean
    $skipAddressValidation: Boolean!
    $guestCheckoutEmail: String
    $phoneNumber: String
    $email: NonEmptyString
    $password: NonEmptyString
  ) {
    addAllShippingData(
      customerIdForAdmin: $customerIdForAdmin
      shippingFirstName: $shippingFirstName
      shippingLastName: $shippingLastName
      shippingStreetAddress: $shippingStreetAddress
      shippingAptDetails: $shippingAptDetails
      shippingCity: $shippingCity
      shippingState: $shippingState
      shippingZipCode: $shippingZipCode
      shippingCountry: $shippingCountry
      isBrownBoxSelected: $isBrownBoxSelected
      firstBoxShipping: $firstBoxShipping
      isVerified: $isVerified
      skipAddressValidation: $skipAddressValidation
      guestCheckoutEmail: $guestCheckoutEmail
      phoneNumber: $phoneNumber
      email: $email
      password: $password
    ) {
      _id
      shippingAddress {
        name {
          first
          last
        }
        phoneNumber
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
        shippingRegion
      }
      isBrownBoxSelected
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
        shippingInsuranceCost
      }
      suggestedAddresses {
        streetAddress
        city
        state
        zipCode
      }
      error {
        key
        value
      }
    }
  }
`;

const addSubscriptionDetails = gql`
  mutation addSubscriptionDetails(
    $customerIdForAdmin: String
    $giftMessage: String
    $babyGender: String
    $day: String
    $month: String
    $year: String
    $pregnancyStatus: String
    $dueDateUnknown: Boolean
  ) {
    addSubscriptionDetails(
      customerIdForAdmin: $customerIdForAdmin
      giftMessage: $giftMessage
      babyGender: $babyGender
      day: $day
      month: $month
      year: $year
      pregnancyStatus: $pregnancyStatus
      dueDateUnknown: $dueDateUnknown
    ) {
      _id
      babyBirthday
      babyGender
      giftMessage
    }
  }
`;

const reactivateSubscription = gql`
  mutation reactivateSubscription($subscriptionId: String!) {
    reactivateSubscription(subscriptionId: $subscriptionId) {
      _id
      status
      isSkipCurrentMonth
      specialRequirementsOnLabel
      currentOrderMonth
      confirmationCode
      plan {
        _id
        provider
        months
        title
        actualCost
        originalCost
      }
      isGift
      nextPaymentDate
      lastPaymentDate
      createdAt
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      orderHistory {
        _id
        createdAt
        shippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        shippingType {
          title
          cost
        }
        isBrownBoxSelected
        confirmationCode
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        cardId
        customerId
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const extendSubscription = gql`
  mutation extendSubscription($subscriptionId: String!) {
    extendSubscription(subscriptionId: $subscriptionId) {
      _id
      status
      isSkipCurrentMonth
      specialRequirementsOnLabel
      currentOrderMonth
      confirmationCode
      plan {
        _id
        provider
        months
        title
        actualCost
        originalCost
      }
      isGift
      nextPaymentDate
      lastPaymentDate
      createdAt
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      orderHistory {
        _id
        createdAt
        shippingAddress {
          name {
            first
            last
          }
          country
          streetAddress
          aptDetails
          city
          state
          zipCode
        }
        shippingType {
          title
          cost
        }
        isBrownBoxSelected
        confirmationCode
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        cardId
        customerId
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const addGuestCheckoutData = gql`
  mutation addGuestCheckoutData(
    $customerIdForAdmin: String
    $guestCheckoutEmail: String!
    $guestCheckoutPhoneNumber: String!
    $firstName: String
    $lastName: String
    $zipCode: String
    $isAgreedToTermsAndConditions: Boolean!
    $isSubscribedToNewsletter: Boolean!
    $trackingSource: String
  ) {
    addGuestCheckoutData(
      customerIdForAdmin: $customerIdForAdmin
      guestCheckoutEmail: $guestCheckoutEmail
      guestCheckoutPhoneNumber: $guestCheckoutPhoneNumber
      firstName: $firstName
      lastName: $lastName
      zipCode: $zipCode
      isAgreedToTermsAndConditions: $isAgreedToTermsAndConditions
      isSubscribedToNewsletter: $isSubscribedToNewsletter
      trackingSource: $trackingSource
    ) {
      _id
      guestCheckoutEmail
      email
      phoneNumber
      name {
        first
        last
      }
      isSubscribedToNewsletter
      isAgreedToTermsAndConditions
    }
  }
`;

const addCoregCheckoutData = gql`
  mutation addCoregCheckoutData(
    $customerIdForAdmin: String
    $guestCheckoutEmail: String!
    $guestCheckoutPhoneNumber: String!
    $firstName: String
    $lastName: String
    $streetAddress: String
    $aptDetails: String
    $city: String
    $state: String
    $zipCode: String
    $country: String
    $isAgreedToTermsAndConditions: Boolean!
    $isSubscribedToNewsletter: Boolean!
  ) {
    addCoregCheckoutData(
      customerIdForAdmin: $customerIdForAdmin
      guestCheckoutEmail: $guestCheckoutEmail
      guestCheckoutPhoneNumber: $guestCheckoutPhoneNumber
      firstName: $firstName
      lastName: $lastName
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
      isAgreedToTermsAndConditions: $isAgreedToTermsAndConditions
      isSubscribedToNewsletter: $isSubscribedToNewsletter
    ) {
      _id
      guestCheckoutEmail
      email
      phoneNumber
      name {
        first
        last
      }
      isSubscribedToNewsletter
      isAgreedToTermsAndConditions
    }
  }
`;

const purchaseCart = gql`
  mutation purchaseCart(
    $customerIdForAdmin: String
    $paymentToken: String!
    $billingFirstName: NonEmptyString!
    $billingLastName: NonEmptyString!
    $billingStreetAddress: String
    $billingAptDetails: String
    $billingCity: String
    $billingState: String
    $billingZipCode: String
    $billingCountry: String
    $shippingFirstName: NonEmptyString!
    $shippingLastName: NonEmptyString!
    $shippingStreetAddress: NonEmptyString!
    $shippingAptDetails: String
    $shippingCity: NonEmptyString!
    $shippingState: NonEmptyString!
    $shippingZipCode: NonEmptyString!
    $shippingCountry: NonEmptyString!
    $giftMessage: String
    $shippingOption: String
    $birthDay: String
    $birthMonth: String
    $birthYear: String
    $gender: String
    $couponCode: String
    $giftCards: String
    $paymentType: String
    $isPendingPayment: Boolean
    $companyCode: String
    $companyPaymentType: String
    $isAnonymous: Boolean
    $registryId: String
    $maxBountyClickId: String
    $referralCode: String
    $insuranceCoveredPumpPurchaseId: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $checkoutVersion: String
    $isSubscribedToNewsletter: Boolean
  ) {
    purchaseCart(
      customerIdForAdmin: $customerIdForAdmin
      paymentToken: $paymentToken
      billingFirstName: $billingFirstName
      billingLastName: $billingLastName
      billingStreetAddress: $billingStreetAddress
      billingAptDetails: $billingAptDetails
      billingCity: $billingCity
      billingState: $billingState
      billingZipCode: $billingZipCode
      billingCountry: $billingCountry
      shippingFirstName: $shippingFirstName
      shippingLastName: $shippingLastName
      shippingStreetAddress: $shippingStreetAddress
      shippingAptDetails: $shippingAptDetails
      shippingCity: $shippingCity
      shippingState: $shippingState
      shippingZipCode: $shippingZipCode
      shippingCountry: $shippingCountry
      giftMessage: $giftMessage
      shippingOption: $shippingOption
      birthDay: $birthDay
      birthMonth: $birthMonth
      birthYear: $birthYear
      gender: $gender
      couponCode: $couponCode
      giftCards: $giftCards
      paymentType: $paymentType
      isPendingPayment: $isPendingPayment
      companyCode: $companyCode
      companyPaymentType: $companyPaymentType
      isAnonymous: $isAnonymous
      registryId: $registryId
      maxBountyClickId: $maxBountyClickId
      referralCode: $referralCode
      insuranceCoveredPumpPurchaseId: $insuranceCoveredPumpPurchaseId
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      checkoutVersion: $checkoutVersion
      isSubscribedToNewsletter: $isSubscribedToNewsletter
    ) {
      _id
      isBrownBoxSelected
      userPoints
      coupon {
        _id
        code
        discountPercentage
        discountAmount
      }
      giftCards {
        code
        originalAmount
        amountRemaining
      }
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      shippingAddress {
        name {
          first
          last
        }
        phoneNumber
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
      }
      billingAddress {
        name {
          first
          last
        }
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
      subCosts {
        subTotal
        pointsDiscount
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        shippingInsuranceCost
      }
      justPurchasedPurchaseId
      firstBoxShippingAddress {
        name {
          first
          last
        }
        streetAddress
        aptDetails
        city
        state
        zipCode
        country
      }
      firstBoxShippingMethod
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          sku
          urlName
          media {
            url
            color
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
        }
        variation
        color
        quantity
      }
      subscription {
        _id
        status
        subCosts {
          subTotal
          pointsDiscount
          giftCardDiscount
          couponDiscount
          shipping
          taxesAndFees
          overallTotal
        }
        plan {
          _id
          provider
          months
          title
          costWithCoupon
          sku
          actualCost
          originalCost
          linkUrl
          imageUrl
        }
        recipientData {
          name {
            first
            last
          }
          email
          dateToEmail
        }
        giftMessage
      }
      purchaser {
        email
        guestCheckoutEmail
      }
      error {
        key
        value
      }
    }
  }
`;

const addDiscountToCart = gql`
  mutation addDiscountToCart(
    $customerIdForAdmin: String
    $code: String!
    $isCoupon: Boolean
    $isGift: Boolean
  ) {
    addDiscountToCart(
      customerIdForAdmin: $customerIdForAdmin
      code: $code
      isCoupon: $isCoupon
      isGift: $isGift
    ) {
      _id
      numberOfItems
      hasSubscription
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
            bundledGWPCost
          }
        }
        variation
        color
        quantity
        amountToCharge
        isAddedCouponProduct
        isBundledGWPProduct
      }
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          actualCost
          originalCost
          actualCostAsShipping
        }
        babyBirthday
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      coupon {
        _id
        code
        discountPercentage
        discountAmount
        addedProductsOptions {
          _id
          sku
          name
        }
      }
      giftCards {
        code
        originalAmount
      }
      error {
        key
        value
      }
    }
  }
`;

const removeDiscountFromCart = gql`
  mutation removeDiscountFromCart(
    $customerIdForAdmin: String
    $isCoupon: Boolean
    $giftCardIndex: Int
  ) {
    removeDiscountFromCart(
      customerIdForAdmin: $customerIdForAdmin
      isCoupon: $isCoupon
      giftCardIndex: $giftCardIndex
    ) {
      _id
      numberOfItems
      hasSubscription
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
        }
        variation
        color
        quantity
        amountToCharge
      }
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          actualCost
          originalCost
          actualCostAsShipping
        }
        babyBirthday
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      coupon {
        _id
        code
        discountPercentage
        discountAmount
      }
      giftCards {
        code
        originalAmount
      }
      error {
        key
        value
      }
    }
  }
`;

const editAccountDetails = gql`
  mutation editAccountDetails(
    $provider: String!
    $customerIdForAdmin: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String
    $password: String
    $previousPassword: String
    $dueDate: String
    $generatePassword: Boolean
    $orderConfirmationCode: String
    $dmeFormStep: String
  ) {
    editAccountDetails(
      provider: $provider
      customerIdForAdmin: $customerIdForAdmin
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      previousPassword: $previousPassword
      dueDate: $dueDate
      generatePassword: $generatePassword
      orderConfirmationCode: $orderConfirmationCode
      dmeFormStep: $dmeFormStep
    ) {
      _id
      isSubscribedToNewsletter
      email
      phoneNumber
      name {
        first
        last
      }
    }
  }
`;

const editShippingAddress = gql`
  mutation editShippingAddress(
    $subscriptionId: String!
    $firstName: String!
    $lastName: String!
    $streetAddress: String!
    $aptDetails: String
    $city: String!
    $state: String!
    $zipCode: String!
    $country: String!
  ) {
    editShippingAddress(
      subscriptionId: $subscriptionId
      firstName: $firstName
      lastName: $lastName
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
    ) {
      _id
      status
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
      }
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const editPaymentMethod = gql`
  mutation editPaymentMethod(
    $customerIdForAdmin: String
    $subscriptionId: String!
    $paymentToken: String!
    $provider: String
    $firstName: String!
    $lastName: String!
    $streetAddress: String
    $aptDetails: String
    $city: String
    $state: String
    $zipCode: String
    $country: String
  ) {
    editPaymentMethod(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
      paymentToken: $paymentToken
      provider: $provider
      firstName: $firstName
      lastName: $lastName
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
    ) {
      _id
      status
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
      }
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const editBabyDetails = gql`
  mutation editBabyDetails(
    $subscriptionId: String!
    $babyGender: String
    $month: String
    $day: String
    $year: String
    $pregnancyStatus: String
  ) {
    editBabyDetails(
      subscriptionId: $subscriptionId
      babyGender: $babyGender
      month: $month
      day: $day
      year: $year
      pregnancyStatus: $pregnancyStatus
    ) {
      _id
      status
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
        type
      }
      babyGender
      babyBirthday
      pregnancyStatus
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const sendForgotPasswordLink = gql`
  mutation sendForgotPasswordLink($email: String!, $provider: String!) {
    sendForgotPasswordLink(email: $email, provider: $provider) {
      email
      error {
        key
        value
      }
    }
  }
`;

const resetPassword = gql`
  mutation resetPassword(
    $resetPasswordCode: String!
    $password: String!
    $provider: String
  ) {
    resetPassword(
      resetPasswordCode: $resetPasswordCode
      password: $password
      provider: $provider
    ) {
      email
      error {
        key
        value
      }
    }
  }
`;

const skipNextMonth = gql`
  mutation skipNextMonth(
    $customerIdForAdmin: String
    $subscriptionId: String!
  ) {
    skipNextMonth(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
    ) {
      _id
      status
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
      }
      monthsToSkip
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const unskipNextMonth = gql`
  mutation unskipNextMonth(
    $customerIdForAdmin: String
    $subscriptionId: String!
  ) {
    unskipNextMonth(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
    ) {
      _id
      status
      plan {
        _id
        provider
        months
        title
        costWithCoupon
        actualCost
        originalCost
      }
      monthsToSkip
      babyGender
      babyBirthday
      recipientData {
        name {
          first
          last
        }
        email
        dateToEmail
      }
      firstBoxShippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      firstBoxShippingMethod
      shippingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      billingAddress {
        name {
          first
          last
        }
        country
        streetAddress
        aptDetails
        city
        state
        zipCode
      }
      paymentMethod {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const sendCorporateGiftingContactEmail = gql`
  mutation sendCorporateGiftingContactEmail(
    $aName: String!
    $companyName: String!
    $companyEmail: String!
    $companyPhoneNumber: String!
    $provider: String
    $utmSource: String
  ) {
    sendCorporateGiftingContactEmail(
      aName: $aName
      companyName: $companyName
      companyEmail: $companyEmail
      companyPhoneNumber: $companyPhoneNumber
      provider: $provider
      utmSource: $utmSource
    ) {
      error {
        key
        value
      }
    }
  }
`;

const sendEmailToHelloBitsyBoxesWithMessage = gql`
  mutation sendEmailToHelloBitsyBoxesWithMessage(
    $name: String!
    $email: String!
    $message: String!
    $subject: String
    $provider: String
  ) {
    sendEmailToHelloBitsyBoxesWithMessage(
      name: $name
      email: $email
      message: $message
      subject: $subject
      provider: $provider
    ) {
      error {
        key
        value
      }
    }
  }
`;

const addReview = gql`
  mutation addReview(
    $customerIdForAdmin: String
    $productId: String!
    $stars: Int!
    $description: String!
  ) {
    addReview(
      customerIdForAdmin: $customerIdForAdmin
      productId: $productId
      stars: $stars
      description: $description
    ) {
      _id
      error {
        key
        value
      }
    }
  }
`;

const editReview = gql`
  mutation editReview(
    $customerIdForAdmin: String
    $reviewId: String!
    $stars: Int
    $description: String
  ) {
    editReview(
      customerIdForAdmin: $customerIdForAdmin
      reviewId: $reviewId
      stars: $stars
      description: $description
    ) {
      _id
      stars
      description
      error {
        key
        value
      }
    }
  }
`;

const deleteReview = gql`
  mutation deleteReview($customerIdForAdmin: String, $reviewId: String!) {
    deleteReview(customerIdForAdmin: $customerIdForAdmin, reviewId: $reviewId) {
      _id
      error {
        key
        value
      }
    }
  }
`;

const removeItemFromCart = gql`
  mutation removeItemFromCart(
    $customerIdForAdmin: String
    $productDetailsIdToRemove: String!
  ) {
    removeItemFromCart(
      customerIdForAdmin: $customerIdForAdmin
      productDetailsIdToRemove: $productDetailsIdToRemove
    ) {
      _id
      numberOfItems
      hasSubscription
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
          sku
        }
        variation
        color
        quantity
        amountToCharge
        promoItem
      }
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          sku
          actualCost
          originalCost
          actualCostAsShipping
          productUrlPrefix
          linkUrl
          imageUrl
        }
        babyBirthday
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const changeQuantityOfItemInCart = gql`
  mutation changeQuantityOfItemInCart(
    $customerIdForAdmin: String
    $productDetailsIdToChange: String!
    $newQuantity: Int!
  ) {
    changeQuantityOfItemInCart(
      customerIdForAdmin: $customerIdForAdmin
      productDetailsIdToChange: $productDetailsIdToChange
      newQuantity: $newQuantity
    ) {
      _id
      numberOfItems
      hasSubscription
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
        }
        variation
        color
        quantity
        amountToCharge
      }
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          sku
          actualCost
          originalCost
          actualCostAsShipping
          productUrlPrefix
          linkUrl
          imageUrl
        }
        babyBirthday
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const removeSubscriptionFromCart = gql`
  mutation removeSubscriptionFromCart($customerIdForAdmin: String) {
    removeSubscriptionFromCart(customerIdForAdmin: $customerIdForAdmin) {
      _id
      numberOfItems
      hasSubscription
      subscription {
        isGift
        plan {
          _id
          title
          months
          costWithCoupon
          sku
          actualCost
          originalCost
          productUrlPrefix
          linkUrl
          imageUrl
        }
        babyBirthday
      }
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
          sku
        }
        variation
        color
        quantity
        amountToCharge
      }
      firstBoxShipping {
        _id
        title
        cost
        isInternational
        timeToShipInBusinessDays
      }
      coupon {
        _id
        code
        discountPercentage
        discountAmount
        freeShipping
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const updatePointsAppliedStatus = gql`
  mutation updatePointsAppliedStatus(
    $customerIdForAdmin: String
    $hasPointsApplied: Boolean!
  ) {
    updatePointsAppliedStatus(
      customerIdForAdmin: $customerIdForAdmin
      hasPointsApplied: $hasPointsApplied
    ) {
      _id
      hasPointsApplied
      firstBoxShipping {
        _id
        cost
        isInternational
        timeToShipInBusinessDays
        title
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
    }
  }
`;

const cancelSubscription = gql`
  mutation cancelSubscription(
    $subscriptionId: String!
    $cancellationReason: String!
    $customerIdForAdmin: String
  ) {
    cancelSubscription(
      subscriptionId: $subscriptionId
      cancellationReason: $cancellationReason
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      status
      error {
        key
        value
      }
    }
  }
`;

const updateRenewalCouponAsUser = gql`
  mutation updateRenewalCouponAsUser(
    $subscriptionId: String!
    $renewalCouponCode: String!
  ) {
    updateRenewalCouponAsUser(
      subscriptionId: $subscriptionId
      renewalCouponCode: $renewalCouponCode
    ) {
      _id
      error {
        key
        value
      }
    }
  }
`;

const validateCouponsInCart = gql`
  mutation validateCouponsInCart($customerIdForAdmin: String) {
    validateCouponsInCart(customerIdForAdmin: $customerIdForAdmin) {
      _id
      coupon {
        _id
        code
        discountPercentage
        discountAmount
        freeShipping
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const validateProductsInCart = gql`
  mutation validateProductsInCart($customerIdForAdmin: String) {
    validateProductsInCart(customerIdForAdmin: $customerIdForAdmin) {
      _id
      numberOfItems
      productsAndDetails {
        _id
        product {
          _id
          name
          brand
          actualCost
          urlName
          media {
            color
            url
          }
          quantityDetails {
            variation
            color
            quantity
          }
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
          sku
        }
        variation
        color
        quantity
        amountToCharge
      }
      subCosts {
        subTotal
        giftCardDiscount
        couponDiscount
        shipping
        taxesAndFees
        overallTotal
        potentialGiftCardDiscount
        potentialTaxesAndFees
        potentialOverallTotal
        applicablePointsWithDiscount
        pointsDiscount
      }
      error {
        key
        value
      }
    }
  }
`;

const sendAndStoreOffersSelected = gql`
  mutation sendAndStoreOffersSelected(
    $offerId: String!
    $questionResponses: [questionResponsesObject]
    $birthDay: String
    $birthMonth: String
    $birthYear: String
    $customerIdForAdmin: String
    $trustedFormUrl: String
    $orderConfirmationCode: String
    $dueDate: String
  ) {
    sendAndStoreOffersSelected(
      offerId: $offerId
      questionResponses: $questionResponses
      birthDay: $birthDay
      birthMonth: $birthMonth
      birthYear: $birthYear
      customerIdForAdmin: $customerIdForAdmin
      trustedFormUrl: $trustedFormUrl
      orderConfirmationCode: $orderConfirmationCode
      dueDate: $dueDate
    ) {
      _id
    }
  }
`;

const addAddressToUser = gql`
  mutation addAddressToUser(
    $customerIdForAdmin: String
    $firstName: String!
    $lastName: String!
    $streetAddress: String!
    $aptDetails: String
    $city: String!
    $state: String!
    $zipCode: String!
    $country: String!
    $addressType: String
    $orderConfirmationCode: String
    $dmeFormStep: String
  ) {
    addAddressToUser(
      customerIdForAdmin: $customerIdForAdmin
      firstName: $firstName
      lastName: $lastName
      streetAddress: $streetAddress
      aptDetails: $aptDetails
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
      addressType: $addressType
      orderConfirmationCode: $orderConfirmationCode
      dmeFormStep: $dmeFormStep
    ) {
      _id
      isSubscribedToNewsletter
      email
      name {
        first
        last
      }
      providers
      isVerified
      token
      error {
        key
        value
      }
    }
  }
`;

const addInsuranceDataAndCreatePurchase = gql`
  mutation addInsuranceDataAndCreatePurchase(
    $insuranceType: String!
    $memberId: String!
    $dateOfBirth: String!
    $obgynFirstName: String!
    $obgynLastName: String!
    $obgynPhoneNumber: String!
    $customerIdForAdmin: String
    $orderConfirmationCode: String
    $utmCampaign: String
    $utmMedium: String
    $utmSource: String
  ) {
    addInsuranceDataAndCreatePurchase(
      insuranceType: $insuranceType
      memberId: $memberId
      dateOfBirth: $dateOfBirth
      obgynFirstName: $obgynFirstName
      obgynLastName: $obgynLastName
      obgynPhoneNumber: $obgynPhoneNumber
      customerIdForAdmin: $customerIdForAdmin
      orderConfirmationCode: $orderConfirmationCode
      utmCampaign: $utmCampaign
      utmMedium: $utmMedium
      utmSource: $utmSource
    ) {
      _id
      isSubscribedToNewsletter
      email
      name {
        first
        last
      }
      isVerified
      token
      insuranceCoveredPumpPurchaseId
      error {
        key
        value
      }
    }
  }
`;

const addPartnerClickToUser = gql`
  mutation addPartnerClickToUser(
    $customerIdForAdmin: String
    $partner: String!
  ) {
    addPartnerClickToUser(
      customerIdForAdmin: $customerIdForAdmin
      partner: $partner
    ) {
      _id
      isSubscribedToNewsletter
      email
      name {
        first
        last
      }
      isVerified
      token
      error {
        key
        value
      }
    }
  }
`;

const clearCart = gql`
  mutation clearCart {
    clearCart {
      _id
      numberOfItems
    }
  }
`;

const verifyCorporateAccount = gql`
  mutation verifyCorporateAccount($corporateVerificationCode: String!) {
    verifyCorporateAccount(
      corporateVerificationCode: $corporateVerificationCode
    ) {
      _id
      accountType
      activeCompanies
      corpRedirect
      token
      error {
        key
        value
      }
    }
  }
`;

const verifyInsuranceProviderAndAddDataToUser = gql`
  mutation verifyInsuranceProviderAndAddDataToUser(
    $customerIdForAdmin: String
    $provider: String!
    $insuranceType: String!
    $memberId: String!
    $dateOfBirth: String!
    $obgynFirstName: String!
    $obgynLastName: String!
    $obgynPhoneNumber: String!
    $obgynFaxNumber: String
    $obgynNpi: String
    $matchedPartner: String
    $orderConfirmationCode: String
    $isAgreedToMomEssentials: Boolean!
    $isAgreedToTermsAndConditions: Boolean!
  ) {
    verifyInsuranceProviderAndAddDataToUser(
      customerIdForAdmin: $customerIdForAdmin
      provider: $provider
      insuranceType: $insuranceType
      memberId: $memberId
      dateOfBirth: $dateOfBirth
      obgynFirstName: $obgynFirstName
      obgynLastName: $obgynLastName
      obgynPhoneNumber: $obgynPhoneNumber
      obgynFaxNumber: $obgynFaxNumber
      obgynNpi: $obgynNpi
      matchedPartner: $matchedPartner
      orderConfirmationCode: $orderConfirmationCode
      isAgreedToMomEssentials: $isAgreedToMomEssentials
      isAgreedToTermsAndConditions: $isAgreedToTermsAndConditions
    ) {
      name
      urlName
      isOnBumpBoxes
      isPartnerApiEnabled
    }
  }
`;

const getSignedUrlForInsurancePhotos = gql`
  query getSignedUrlForInsurancePhotos($customerIdForAdmin: String) {
    getSignedUrlForInsurancePhotos(customerIdForAdmin: $customerIdForAdmin) {
      postDataForInsuranceCardFront
      postDataForInsuranceCardBack
    }
  }
`;

const addOrRemoveProductToAddOns = gql`
  mutation addOrRemoveProductToAddOns(
    $customerIdForAdmin: String
    $subscriptionId: String!
    $productId: String!
    $productAlreadyAdded: Boolean!
    $isRecurringAddOn: Boolean
  ) {
    addOrRemoveProductToAddOns(
      customerIdForAdmin: $customerIdForAdmin
      subscriptionId: $subscriptionId
      productId: $productId
      productAlreadyAdded: $productAlreadyAdded
      isRecurringAddOn: $isRecurringAddOn
    ) {
      _id
      status
      renewOn
      recurringAddOns {
        amountToCharge
        product {
          _id
          name
          originalCost
          actualCost
          urlName
          ownPromoData {
            isSubPromo
            isProductPromo
            isEverythingPromo
            isPostPurchasePromo
            isPreCheckoutPromo
            isVitaminPromo
            expiresOn
            validOn
            isAddOn
            isRecurringAddOn
            cost
            header
            subHeader
            buttonText
            promoDescription
          }
        }
      }
      addOnsWithDetails {
        _id
        quantity
        replacementItem
        color
        variation
        amountToCharge
        product {
          _id
          name
          actualCost
          originalCost
        }
      }
    }
  }
`;

const createRegistry = gql`
  mutation createRegistry(
    $shippingAddress: address!
    $dueDate: String!
    $gender: String!
    $displayGender: Boolean!
    $displayDueDate: Boolean!
    $subscriptionId: String!
    $customerIdForAdmin: String
  ) {
    createRegistry(
      shippingAddress: $shippingAddress
      dueDate: $dueDate
      gender: $gender
      displayGender: $displayGender
      displayDueDate: $displayDueDate
      subscriptionId: $subscriptionId
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      shareCode
      userEmail
    }
  }
`;

const editRegistry = gql`
  mutation editRegistry(
    $registryId: String!
    $registryFirstName: String!
    $registryLastName: String!
    $shippingAddress: address!
    $dueDate: String!
    $gender: String!
    $displayGender: Boolean!
    $displayDueDate: Boolean!
    $customerIdForAdmin: String
  ) {
    editRegistry(
      registryId: $registryId
      shippingAddress: $shippingAddress
      dueDate: $dueDate
      gender: $gender
      displayGender: $displayGender
      displayDueDate: $displayDueDate
      registryFirstName: $registryFirstName
      registryLastName: $registryLastName
      customerIdForAdmin: $customerIdForAdmin
    ) {
      _id
      name {
        first
        last
      }
      subscription {
        _id
        babyGender
        babyBirthday
        shippingAddress {
          name {
            first
            last
          }
          streetAddress
          aptDetails
          city
          state
          zipCode
          country
        }
      }
      sponsors {
        name {
          first
          last
        }
        email
        numOfBoxesSponsored
        giftMessage
        thankYouCreatedAt
        isAnonymous
        createdAt
      }
      displayGender
      displayDueDate
      numOfBoxesSponsored
      daysToGo
      registryProgress
      shareCode
    }
  }
`;

const addOrRemoveShippingInsuranceForCart = gql`
  mutation addOrRemoveShippingInsuranceForCart(
    $cartId: String!
    $isAddingInsurance: Boolean!
  ) {
    addOrRemoveShippingInsuranceForCart(
      cartId: $cartId
      isAddingInsurance: $isAddingInsurance
    ) {
      _id
      shippingInsurance {
        cost
      }
    }
  }
`;

const addOrRemoveShippingInsuranceForSubscription = gql`
  mutation addOrRemoveShippingInsuranceForSubscription(
    $subscriptionId: String!
    $isAddingInsurance: Boolean!
  ) {
    addOrRemoveShippingInsuranceForSubscription(
      subscriptionId: $subscriptionId
      isAddingInsurance: $isAddingInsurance
    ) {
      _id
      shippingInsurance {
        cost
      }
    }
  }
`;

const addAddressToRegistryCart = gql`
  mutation addAddressToRegistryCart(
    $customerIdForAdmin: String
    $registryId: String!
  ) {
    addAddressToRegistryCart(
      customerIdForAdmin: $customerIdForAdmin
      registryId: $registryId
    ) {
      _id
    }
  }
`;

const storeAndSendSMSOptIn = gql`
  mutation storeAndSendSMSOptIn(
    $customerIdForAdmin: String
    $isOptedInSMS: Boolean!
    $email: String
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $isSubscribedToNewsletter: Boolean
    $provider: String
    $source: String!
  ) {
    storeAndSendSMSOptIn(
      customerIdForAdmin: $customerIdForAdmin
      isOptedInSMS: $isOptedInSMS
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      isSubscribedToNewsletter: $isSubscribedToNewsletter
      provider: $provider
      source: $source
    ) {
      _id
    }
  }
`;

const getPreCheckoutPromoProduct = gql`
  query getPreCheckoutPromoProduct(
    $isRecurringAddOnPromo: Boolean
    $provider: String
    $productAddedToCart: String
  ) {
    getPreCheckoutPromoProduct(
      isRecurringAddOnPromo: $isRecurringAddOnPromo
      provider: $provider
      productAddedToCart: $productAddedToCart
    ) {
      _id
      name
      isActive
      description
      ingredients
      originalCost
      actualCost
      urlName
      averageRating
      quantityDictionaryAsString
      totalQuantity
      variationName
      showColorsAsMultipleItems
      showVariationsAsMultipleItems
      isDropShip
      sku
      isComposite
      brand
      isOutOfStock
      media {
        color
        url
        variation
        altTag
      }
      tags {
        _id
        description
        isInternal
        bitsyParent {
          _id
          description
        }
        bumpParent {
          _id
          description
        }
      }
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        title
        header
        subHeader
        buttonText
        promoDescription
      }
      subscriptionPlan {
        _id
        provider
        months
        title
        orderToShow
        costWithCoupon
        actualCost
        originalCost
        tagType
        isHidden
        minimumMonths
        actualCostAsShipping
        couponToAutoPopulate {
          _id
          code
          discountPercentage
          discountAmount
          freeShipping
          addedProducts {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
          addedProductsOptions {
            _id
            name
            sku
            description
            descriptionComponents
            ingredients
            colors
            variations
            originalCost
            actualCost
            urlName
            brand
            media {
              url
            }
          }
        }
        bulletPoints {
          point1
          point2
          point3
          point4
        }
      }
      discounted
      totalNumberOfProducts
      __typename @skip(if: true)
    }
  }
`;

const checkIfSubscriptionExists = gql`
  query checkIfSubscriptionExists($confirmationCode: String!) {
    checkIfSubscriptionExists(confirmationCode: $confirmationCode) {
      _id
    }
  }
`;

const getReferralCodeForNewOrReturningUser = gql`
  mutation getReferralCodeForNewOrReturningUser(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    getReferralCodeForNewOrReturningUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      _id
      referralCode
      email
      name {
        first
        last
      }
    }
  }
`;

const getReferralCodeForLoggedInUser = gql`
  mutation getReferralCodeForLoggedInUser($customerIdForAdmin: String) {
    getReferralCodeForLoggedInUser(customerIdForAdmin: $customerIdForAdmin) {
      _id
      referralCode
      email
      name {
        first
        last
      }
    }
  }
`;

const getInsuranceCoveredPumps = gql`
  query getInsuranceCoveredPumps(
    $customerIdForAdmin: String
    $provider: String!
    $insuranceType: String!
    $memberId: String!
    $state: String!
  ) {
    getInsuranceCoveredPumps(
      customerIdForAdmin: $customerIdForAdmin
      provider: $provider
      insuranceType: $insuranceType
      memberId: $memberId
      state: $state
    ) {
      _id
      name
      brand
      description
      descriptionComponents
      ingredients
      actualCost
      originalCost
      urlName
      totalQuantity
      variationName
      averageRating
      isDropShip
      isComposite
      freeReturns
      media {
        color
        url
        variation
      }
      colorsDictionary {
        permanentName
        displayName
        hex
        media
      }
      quantityDictionaryAsString
      ownPromoData {
        isSubPromo
        isProductPromo
        isEverythingPromo
        isPostPurchasePromo
        isPreCheckoutPromo
        isVitaminPromo
        expiresOn
        validOn
        isAddOn
        isRecurringAddOn
        cost
        header
        subHeader
        buttonText
        promoDescription
      }
      error {
        key
        value
      }
    }
  }
`;

const purchaseCoregCart = gql`
  mutation purchaseCoregCart {
    purchaseCoregCart {
      _id
      confirmationCode
    }
  }
`;

const completeOrMarkAsReadyPurchaseWithPostCheckoutSteps = gql`
  mutation completeOrMarkAsReadyPurchaseWithPostCheckoutSteps(
    $purchaseId: String!
  ) {
    completeOrMarkAsReadyPurchaseWithPostCheckoutSteps(
      purchaseId: $purchaseId
    ) {
      _id
    }
  }
`;

const getCartOrSubscriptionDataFromPurchase = gql`
  query getCartOrSubscriptionDataFromPurchase($purchaseId: String!) {
    getCartOrSubscriptionDataFromPurchase(purchaseId: $purchaseId) {
      ... on Cart {
        _id
        babyBirthday
        babyGender
        isBrownBoxSelected
        giftMessage
        coupon {
          _id
          addedProductsOptions {
            _id
            name
            sku
            media {
              color
              url
              variation
              altTag
            }
            totalQuantity
            isOutOfStock
            originalCost
          }
        }
        productsAndDetails {
          _id
          product {
            _id
            name
          }
          variation
          color
          quantity
          amountToCharge
          promoItem
          isAddedCouponProduct
          isBundledGWPProduct
        }
        subCosts {
          subTotal
          giftCardDiscount
          couponDiscount
          shipping
          taxesAndFees
          overallTotal
          potentialGiftCardDiscount
          potentialTaxesAndFees
          potentialOverallTotal
          applicablePointsWithDiscount
          pointsDiscount
        }
      }
      ... on Subscription {
        _id
        babyBirthday
        babyGender
        isBrownBoxSelected
        giftMessage
        coupon {
          _id
          addedProductsOptions {
            _id
            name
            sku
            media {
              color
              url
              variation
              altTag
            }
            totalQuantity
            isOutOfStock
            originalCost
          }
        }
        addOnsWithDetails {
          _id
          quantity
          replacementItem
          color
          variation
          amountToCharge
          product {
            _id
            name
          }
        }
        recurringAddOns {
          amountToCharge
          product {
            _id
            name
          }
        }
      }
    }
  }
`;

const editBabyDetailsFromWelcomeMat = gql`
  mutation editBabyDetailsFromWelcomeMat(
    $purchaseId: String!
    $babyGender: String!
    $month: String
    $day: String
    $year: String
    $dueDateUnknown: Boolean
  ) {
    editBabyDetailsFromWelcomeMat(
      purchaseId: $purchaseId
      babyGender: $babyGender
      month: $month
      day: $day
      year: $year
      dueDateUnknown: $dueDateUnknown
    ) {
      _id
      babyGender
      babyBirthday
    }
  }
`;

const addOrRemoveWelcomeMatProducts = gql`
  mutation addOrRemoveWelcomeMatProducts(
    $purchaseId: String!
    $productsAndDetails: [productAndDetails]!
    $gwpProductIds: [String]
  ) {
    addOrRemoveWelcomeMatProducts(
      purchaseId: $purchaseId
      productsAndDetails: $productsAndDetails
      gwpProductIds: $gwpProductIds
    ) {
      ... on Cart {
        _id
        productsAndDetails {
          _id
          product {
            _id
            name
          }
          variation
          color
          quantity
          amountToCharge
          promoItem
          isAddedCouponProduct
          isBundledGWPProduct
        }
        subCosts {
          subTotal
          giftCardDiscount
          couponDiscount
          shipping
          taxesAndFees
          overallTotal
          potentialGiftCardDiscount
          potentialTaxesAndFees
          potentialOverallTotal
          applicablePointsWithDiscount
          pointsDiscount
        }
      }
      ... on Subscription {
        _id
        addOnsWithDetails {
          _id
          quantity
          replacementItem
          color
          variation
          amountToCharge
          product {
            _id
            name
          }
        }
        recurringAddOns {
          amountToCharge
          product {
            _id
            name
          }
        }
      }
    }
  }
`;

const editBrownBoxShippingAndGiftMessageFromWelcomeMat = gql`
  mutation editBrownBoxShippingAndGiftMessageFromWelcomeMat(
    $purchaseId: String!
    $isBrownBoxSelected: Boolean
    $giftMessage: String
  ) {
    editBrownBoxShippingAndGiftMessageFromWelcomeMat(
      purchaseId: $purchaseId
      isBrownBoxSelected: $isBrownBoxSelected
      giftMessage: $giftMessage
    ) {
      _id
      isBrownBoxSelected
      giftMessage
    }
  }
`;

const insecureCorporatePurchaseAndOrder = gql`
  mutation insecureCorporatePurchaseAndOrder(
    $email: NonEmptyString!
    $companyCode: NonEmptyString!
    $EEID: NonEmptyString
    $shippingFirstName: NonEmptyString!
    $shippingLastName: NonEmptyString!
    $shippingAddressLineOne: NonEmptyString!
    $shippingAddressLineTwo: NonEmptyString
    $shippingCity: NonEmptyString!
    $shippingState: NonEmptyString!
    $shippingPostalCode: NonEmptyString!
    $shippingCountry: NonEmptyString!
    $productId: NonEmptyString!
  ) {
    insecureCorporatePurchaseAndOrder(
      email: $email
      companyCode: $companyCode
      EEID: $EEID
      shippingFirstName: $shippingFirstName
      shippingLastName: $shippingLastName
      shippingAddressLineOne: $shippingAddressLineOne
      shippingAddressLineTwo: $shippingAddressLineTwo
      shippingCity: $shippingCity
      shippingState: $shippingState
      shippingPostalCode: $shippingPostalCode
      shippingCountry: $shippingCountry
      productId: $productId
    ) {
      _id
      confirmationCode
    }
  }
`;

export default {
  queries: {
    getSubscriptionQuery,
    productQuery,
    userInfoQuery,
    allPlansQuery,
    plansForProviderQuery,
    allShippingOptionsQuery,
    orderInfoQuery,
    purchaseInfoQuery,
    subscriptionInProgressQuery,
    getProductsQuery,
    cartItemCountQuery,
    availablePointsQuery,
    getCurrentShopTag,
    allPressReleasesQuery,
    getPromoProductsForCart,
    getPromoProductsForPostPurchase,
    coregOffers,
    getBreastPumpProducts,
    getCompanyByCode,
    getCorporateProducts,
    validateCompanySignUpUrl,
    addEmailToMasterList,
    sendDueDateCalculatorKlaviyoEvents,
    addEmailToBreastPumpMasterList,
    addEmailToGiveawayList,
    getSubscriptionAddOnProducts,
    getRegistryInfo,
    getRegistryInfoForOwner,
    searchForRegistries,
    getRegistryProduct,
    validateRegistryAddress,
    getPreCheckoutPromoProduct,
    getSubscriptionAddOnProductsForLandingPage,
    checkIfSubscriptionExists,
    refreshUserToken,
    getSubscriptionCouponsByCode,
    getInsuranceCoveredPumps,
    allBabyNames,
    getSignedUrlForInsurancePhotos,
    getActiveSaleEvents,
    getCartOrSubscriptionDataFromPurchase,
  },
  mutations: {
    signInUser,
    signUpUser,
    signOutUser,
    confirmUserPassword,
    addDiscountToCart,
    removeDiscountFromCart,
    editAccountDetails,
    editShippingAddress,
    editPaymentMethod,
    editBabyDetails,
    sendForgotPasswordLink,
    resetPassword,
    skipNextMonth,
    sendCorporateGiftingContactEmail,
    sendEmailToHelloBitsyBoxesWithMessage,
    addReview,
    editReview,
    deleteReview,
    addAllShippingData,
    addSubscriptionDetails,
    reactivateSubscription,
    extendSubscription,
    addItemToCart,
    swapGWPItem,
    purchaseCart,
    removeItemFromCart,
    changeQuantityOfItemInCart,
    removeSubscriptionFromCart,
    getEmptyUser,
    addGuestCheckoutData,
    updatePointsAppliedStatus,
    unskipNextMonth,
    cancelSubscription,
    updateRenewalCouponAsUser,
    validateCouponsInCart,
    validateProductsInCart,
    sendAndStoreOffersSelected,
    addAddressToUser,
    verifyInsuranceProviderAndAddDataToUser,
    addInsuranceDataAndCreatePurchase,
    addPartnerClickToUser,
    clearCart,
    verifyCorporateAccount,
    addOrRemoveProductToAddOns,
    createRegistry,
    editRegistry,
    addOrRemoveShippingInsuranceForCart,
    addOrRemoveShippingInsuranceForSubscription,
    addAddressToRegistryCart,
    storeAndSendSMSOptIn,
    getReferralCodeForNewOrReturningUser,
    getReferralCodeForLoggedInUser,
    cartInfo,
    addCoregCheckoutData,
    purchaseCoregCart,
    completeOrMarkAsReadyPurchaseWithPostCheckoutSteps,
    editBabyDetailsFromWelcomeMat,
    addOrRemoveWelcomeMatProducts,
    editBrownBoxShippingAndGiftMessageFromWelcomeMat,
    processPendingPayments,
    insecureCorporatePurchaseAndOrder,
  },
};
