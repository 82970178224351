import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

export default function SuggestedAddresses(props) {
  if (!props.showSuggestedShippingAddresses) {
    return null;
  }

  return (
    <Row className='center-align'>
      <Col>
        <h3>
          We found an updated
          <br />
          address for you.
        </h3>
        <p>Should we update your shipping address?</p>
        <Form.Group>
          {props.suggestedShippingAddresses.map((address, index) => (
            <Form.Check
              key={address.zipCode}
              type='radio'
              onChange={props.updateShippingAddress}
              label={`${address.streetAddress}, ${
                props.shippingValues.aptDetails
                  ? `${props.shippingValues.aptDetails}, `
                  : ''
              }${address.city}, ${address.state} ${address.zipCode}`}
              value={index}
              name='suggestedAddress'
              id={`suggestedAddress${index}`}
            />
          ))}
          <Form.Check
            type='radio'
            onChange={props.updateShippingAddress}
            value='noChange'
            label='No, Keep my current address'
            checked={props.selectedShippingOption === 'noChange'}
            name='suggestedAddress'
            id='currentAddress'
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
