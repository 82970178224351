import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import Environment from './Environment';

const beforeSendHelper = (message, event) => {
  if (message) {
    if (
      message.includes('InvalidToken') ||
      message.includes('Load failed at refreshUserToken') ||
      message.startsWith('TypeError: cancelled')
    ) {
      return null;
    }
    if (
      message.includes('Oops!') ||
      message.includes('EMAIL_EXISTS') ||
      message.includes('STRIPE_ERROR')
    ) {
      const sentryEvent = event;
      sentryEvent.level = 'info';
      return sentryEvent;
    }
  }
  return event;
};

const initTime = () => {
  if (Environment.getEnv !== 'DEVELOPMENT') {
    Sentry.init({
      dsn:
        'https://6e212757b0df491d92eb19888ad53575@o130078.ingest.sentry.io/287221',
      environment: Environment.getEnv,
      ignoreErrors: ['Non-Error promise rejection captured'],

      beforeSend(event, hint) {
        const { message } = hint.originalException;
        return beforeSendHelper(message, event);
      },

      // Turning off sentry replays until we can make better use of them.
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,

      integrations: [
        new Replay({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: true,
        }),
      ],
    });
  }
};

export default {
  initTime,
  beforeSendHelper,
};
