import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import './style.css';

import Environment from '../../services/Environment';

const getNumberOptions = function getNumberOptions(
  min,
  max,
  hasUnknownOption,
  type,
  sort,
) {
  const arrayLength = max - min;
  let nums;
  if (sort === 'descending') {
    nums = Array.from({ length: arrayLength }, (_, i) => max - i);
  } else {
    nums = Array.from({ length: arrayLength }, (_, i) => i + min);
  }
  const numberOptions = nums.map((num) => num.toString());
  const optionsToReturn = ['Unknown'];
  if (hasUnknownOption) {
    return optionsToReturn.concat(numberOptions).map((numberOption) => (
      <option key={`${type}-${numberOption}`} value={numberOption}>
        {numberOption}
      </option>
    ));
  }
  return numberOptions.map((numberOption) => (
    <option key={`${type}-${numberOption}`} value={numberOption}>
      {numberOption}
    </option>
  ));
};

const getMonthOptions = function getMonthOptions() {
  const monthOptions = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  return monthOptions.map((monthOption) => (
    <option key={`month-${monthOption.label}`} value={monthOption.value}>
      {monthOption.label}
    </option>
  ));
};

const getCurrentYear = function getCurrentYear() {
  return new Date().getFullYear();
};

const renderHelpBlock = (isInvalid, text) => {
  if (isInvalid) {
    return <div className='help-block'>{text}</div>;
  }
  return null;
};

const renderYearRange = function renderYearRange(props) {
  if (props.breastPump) {
    return getNumberOptions(
      getCurrentYear() - 55,
      getCurrentYear() - 10,
      false,
      'year',
      'ascending',
    );
  }
  if (props.breastPumpDueDate) {
    return getNumberOptions(
      getCurrentYear() - 1,
      getCurrentYear() + 2,
      false,
      'year',
      'ascending',
    );
  }
  if (props.manyYears) {
    return getNumberOptions(
      getCurrentYear() - 100,
      getCurrentYear(),
      false,
      'year',
      'descending',
    );
  }
  return getNumberOptions(
    Environment.store.includes('bump')
      ? getCurrentYear()
      : getCurrentYear() - 3,
    Environment.store.includes('bump')
      ? getCurrentYear() + 2
      : getCurrentYear() + 1,
    false,
    'year',
    'ascending',
  );
};

const renderDayRange = function renderDayRange(props) {
  if (props.noUnknownDay) {
    return getNumberOptions(1, 32, false, 'day');
  }
  return getNumberOptions(1, 32, !props.breastPump, 'day');
};

const DateForm = (props) => (
  <Col className='date-form' xs={12} sm={12} md={12} lg={12}>
    <Row>
      <Col
        className='dateInput dateInputMonth center-align'
        xs={4}
        sm={4}
        md={4}
        lg={4}
      >
        <Form.Group
          controlId='month-date-input'
          validated={
            !props.dateValues.month && props.hasBeenSubmitted
              ? undefined
              : props.dateValues.month
          }
        >
          <Form.Control
            name='month'
            value={props.dateValues.month}
            onChange={props.handleChange}
            as='select'
            aria-label={
              props.ariaPrefix
                ? `${props.ariaPrefix} Select Month`
                : 'Select Month'
            }
          >
            <option value=''>Month</option>
            {getMonthOptions()}
          </Form.Control>
          {renderHelpBlock(
            !props.dateValues.month &&
              props.hasBeenSubmitted &&
              !props.optional,
            'Month required.',
          )}
        </Form.Group>
      </Col>
      <Col
        className='dateInput dateInputDay center-align'
        xs={4}
        sm={4}
        md={4}
        lg={4}
      >
        <Form.Group
          controlId='day-date-input'
          validated={
            !props.dateValues.day && props.hasBeenSubmitted
              ? undefined
              : props.dateValues.day
          }
        >
          <Form.Control
            name='day'
            value={props.dateValues.day}
            onChange={props.handleChange}
            as='select'
            aria-label={
              props.ariaPrefix ? `${props.ariaPrefix} Select Day` : 'Select Day'
            }
          >
            <option value=''>Day</option>
            {renderDayRange(props)}
          </Form.Control>
          {renderHelpBlock(
            !props.dateValues.day && props.hasBeenSubmitted && !props.optional,
            'Day required.',
          )}
        </Form.Group>
      </Col>
      <Col
        className='dateInput dateInputYear center-align'
        xs={4}
        sm={4}
        md={4}
        lg={4}
      >
        <Form.Group
          controlId='year-date-input'
          validated={
            !props.dateValues.year && props.hasBeenSubmitted
              ? undefined
              : props.dateValues.year
          }
        >
          <Form.Control
            name='year'
            value={props.dateValues.year}
            onChange={props.handleChange}
            as='select'
            aria-label={
              props.ariaPrefix
                ? `${props.ariaPrefix} Select Year`
                : 'Select Year'
            }
          >
            <option value=''>Year</option>
            {renderYearRange(props)}
          </Form.Control>
          {renderHelpBlock(
            !props.dateValues.year && props.hasBeenSubmitted && !props.optional,
            'Year required.',
          )}
        </Form.Group>
      </Col>
    </Row>
  </Col>
);

export default DateForm;
