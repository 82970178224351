import BitsyApollo from './BitsyApollo';
import Auth from './Auth';
import BBAnalytics from './BBAnalytics';
import Content from '../constants/Content';
import Logger from './Logger';

// helper function for tempCart subCosts
function getSubCosts(cart) {
  const totalCost = BitsyApollo.getCosts(cart);
  const subscriptionCost = cart.subscription
    ? cart.subscription.plan.actualCost
    : 0;
  const couponDiscount = cart.subscription
    ? cart.subscription.plan.originalCost -
      cart.subscription.plan.costWithCoupon
    : 0;
  const subTotal = totalCost - couponDiscount + subscriptionCost;
  return {
    totalCost,
    pointsDiscount: 0,
    overallTotal: totalCost,
    subTotal,
    couponDiscount,
    giftCardDiscount: 0,
    taxesAndFees: 0,
  };
}

async function getCart(queryVariablesObject) {
  if (Auth.hasToken()) {
    const { data } = await BitsyApollo.cartInfo(queryVariablesObject);
    if (data) return data.cartInfo;
  }

  const tempCart = await BitsyApollo.getTempCart();

  const subCosts = getSubCosts(tempCart);

  const firstBoxShipping = {
    __typename: 'Shipping',
    _id: '5a8c946af6da9dcb85f90bfa',
    cost: 0,
    isInternational: false,
    timeToShipInBusinessDays: '7 - 10 Business Days',
    title: 'Ground Shipping (FREE)',
  };

  return {
    ...tempCart,
    subCosts,
    applicablePointsWithDiscount: 0,
    firstBoxShipping,
  };
}

function changeQuantityOfProduct(product, change, index) {
  let data = null;
  const hasToken = Auth.hasToken();
  if (change === 'minus') {
    if (product.quantity === 1) return {};
    if (hasToken) {
      data = {
        productDetailsIdToChange: product._id,
        newQuantity: product.quantity - 1,
      };
    } else {
      data = {
        color: product.color,
        variation: product.variation,
        product: product.product,
        quantity: parseInt(product.quantity, 10) - 1,
      };
    }
  } else if (change === 'plus') {
    const { color, variation, quantityDetails } = product;

    let stock = hasToken ? null : quantityDetails[variation][color];

    if (hasToken) {
      product.product.quantityDetails.forEach((detail) => {
        if (detail.variation === variation && detail.color === color) {
          stock = detail.quantity;
        }
      });
    }
    if (
      product.quantity + 1 > stock &&
      !product.product.isDropShip &&
      !product.product.isComposite
    ) {
      return {
        modalType: 'ABOVE STOCK',
        stock,
        productToChangeName: product.product.name,
      };
    }

    if (hasToken) {
      data = {
        productDetailsIdToChange: product._id,
        newQuantity: product.quantity + 1,
      };
    } else {
      data = {
        color: product.color,
        variation: product.variation,
        product: product.product,
        quantity: parseInt(product.quantity, 10) + 1,
      };
    }
  }

  if (hasToken) {
    return BitsyApollo.changeQuantityOfItemInCart(data).then((res) => {
      if (res.error) {
        Logger.error(res.error);
        const errorMessage = res.error.message.replace(
          /GraphQL error: Error: /g,
          '',
        );
        return { unknownError: errorMessage };
      }
      BBAnalytics.updatedCartEvent(res);
      return res;
    });
  }
  const tempCart = BitsyApollo.getTempCart();
  if (
    tempCart &&
    tempCart.productsAndDetails.length &&
    index >= 0 &&
    tempCart.productsAndDetails.length - 1 >= index
  ) {
    tempCart.productsAndDetails.splice(index, 1, data);
  }
  const tempCartString = JSON.stringify(tempCart);
  localStorage.setItem('tempCart', tempCartString);
  return { modalType: 'NONE' };
}

function deleteProductFromCart(
  cartInfo,
  productToDeleteId,
  promoId,
  productToDeleteIndex,
) {
  const isLoggedIn = Auth.isLoggedIn();
  let specificProdIdToDelete;
  let showPromo = false;

  if (Auth.hasToken()) {
    const item = { productDetailsIdToRemove: productToDeleteId };

    cartInfo.productsAndDetails.map((product) => {
      if (productToDeleteId === product._id) {
        specificProdIdToDelete = product.product._id;
      }
      return {};
    });

    return BitsyApollo.removeItemFromCart(item).then((res) => {
      if (res.error) {
        Logger.error(res.error);
        const errorMessage = res.error.message.replace(
          /GraphQL error: Error: /g,
          '',
        );
        return { unknownError: errorMessage };
      }
      if (specificProdIdToDelete === promoId) {
        showPromo = true;
      }
      return {
        productToDeleteId: '',
        productToDeleteName: '',
        modalType: 'NONE',
        specificProdIdToDelete,
        showPromo,
        isLoggedIn,
      };
    });
  }

  // if mot logged in
  cartInfo.productsAndDetails.map((product) => {
    if (productToDeleteId === product._id) {
      specificProdIdToDelete = product.product._id;
    }
    return {};
  });

  const tempCart = BitsyApollo.getTempCart();

  if (
    tempCart &&
    tempCart.productsAndDetails &&
    productToDeleteIndex >= 0 &&
    tempCart.productsAndDetails.length - 1 >= productToDeleteIndex
  )
    tempCart.productsAndDetails.splice(productToDeleteIndex, 1);

  if (tempCart.productsAndDetails && tempCart.productsAndDetails.length) {
    const tempCartString = JSON.stringify(tempCart);
    localStorage.setItem('tempCart', tempCartString);
  } else if (
    !tempCart.productsAndDetails.length &&
    tempCart.subscription === null
  ) {
    localStorage.removeItem('tempCart');
  } else {
    tempCart.productsAndDetails = [];
    const tempCartString = JSON.stringify(tempCart);
    localStorage.setItem('tempCart', tempCartString);
  }

  if (specificProdIdToDelete === promoId) {
    showPromo = true;
  }
  const overallTotal = BitsyApollo.getCosts(tempCart);
  BBAnalytics.updatedCartEvent({
    productsAndDetails: tempCart.productsAndDetails,
    subscription: tempCart.subscription,
    subCosts: { overallTotal },
  });
  return {
    modalType: 'NONE',
    productToDeleteName: '',
    productToDeleteIndex: -1,
    specificProdIdToDelete,
    showPromo,
    isLoggedIn,
  };
}

function deletePromoWithSub(cartInfo, promoId, isPromoAdded) {
  const tempCart = BitsyApollo.getTempCart();
  if (isPromoAdded && promoId !== '') {
    if (tempCart && tempCart.productsAndDetails.length) {
      tempCart.productsAndDetails.map((product, index) => {
        if (product.product._id === promoId) {
          const productToDeleteIndex = index;
          const productToDeleteId = product ? product._id : null;
          this.deleteProductFromCart(
            cartInfo,
            productToDeleteId,
            promoId,
            productToDeleteIndex,
          );
        }
        return '';
      });
    } else {
      return { modalType: 'NONE' };
    }
  }
  return '';
}

// helper function for deleting subs while not logged in
function deleteSubFromCart(cartInfo, isPromoAdded, promoId) {
  let productToDeleteId;
  if (Auth.hasToken()) {
    const customerIdForAdmin = localStorage.getItem('customerId');
    return BitsyApollo.removeSubscriptionFromCart({ customerIdForAdmin }).then(
      (res) => {
        if (res.error) {
          Logger.error(res.error);
          const errorMessage = res.error.message.replace(
            /GraphQL error: Error: /g,
            '',
          );
          return { unknownError: errorMessage };
        }
        if (isPromoAdded && promoId !== '') {
          if (cartInfo && cartInfo.productsAndDetails.length) {
            cartInfo.productsAndDetails.map((product) => {
              if (product.product._id === promoId) {
                productToDeleteId = product._id;
                this.deleteProductFromCart(
                  cartInfo,
                  productToDeleteId,
                  promoId,
                );
              }
              return {};
            });
          } else {
            return { modalType: 'NONE' };
          }
        }
        return { modalType: 'NONE' };
      },
    );
  }

  // if not logged in
  const tempCart = BitsyApollo.getTempCart();

  if (!tempCart.productsAndDetails.length) {
    localStorage.removeItem('tempCart');
  } else {
    tempCart.subscription = null;
    const tempCartString = JSON.stringify(tempCart);
    localStorage.setItem('tempCart', tempCartString);
  }

  if (localStorage.getItem('token')) {
    BitsyApollo.removeSubscriptionFromCart({}).then((res) => {
      if (res.error) {
        Logger.error(res.error);
        const errorMessage = res.error.message.replace(
          /GraphQL error: Error: /g,
          '',
        );
        return { unknownError: errorMessage };
      }
      return null;
    });
  }
  const overallTotal = BitsyApollo.getCosts(tempCart);
  BBAnalytics.updatedCartEvent({
    productsAndDetails: tempCart.productsAndDetails,
    subscription: tempCart.subscription,
    subCosts: { overallTotal },
  });

  deletePromoWithSub(cartInfo, promoId, isPromoAdded);
  return {
    modalType: 'NONE',
    productToDeleteName: '',
    productToDeleteIndex: -1,
  };
}

function addDiscountToCart(code, isCoupon, isGift) {
  return BitsyApollo.addDiscountToCart({
    code,
    isCoupon,
    isGift,
  });
}

function removeDiscountFromCart(isCoupon, giftCardIndex) {
  return BitsyApollo.removeDiscountFromCart({ isCoupon, giftCardIndex }).then(
    (res) => {
      if (res.error && isCoupon) {
        return {
          couponCodeError: 'Something went wrong.',
          autoAppliedCouponError: null,
        };
      }
      if (res.error) {
        return {
          giftCardCodeError: 'Something went wrong.',
          autoAppliedCouponError: null,
        };
      }
      if (isCoupon) {
        return {
          coupon: null,
          autoAppliedCouponError: null,
          cartInfo: res,
        };
      }
      return {
        giftCards: res.giftCards,
        autoAppliedCouponError: null,
      };
    },
  );
}

function removeTokenIfGuestAndPurchasedCart() {
  const isGuestCheckout = localStorage.getItem('isGuestCheckout');
  const hasPurchasedCart = localStorage.getItem('purchasedCart');

  if (isGuestCheckout && hasPurchasedCart) {
    if (JSON.parse(isGuestCheckout) && JSON.parse(hasPurchasedCart)) {
      localStorage.removeItem('token');
    }
  }
}

function addSubscriptionToCart(
  plan,
  provider,
  list,
  subscriptionCouponFromURL,
) {
  removeTokenIfGuestAndPurchasedCart();

  if (Auth.hasToken()) {
    return BitsyApollo.addItemToCart({
      planId: plan._id,
      subscriptionCouponFromURLAsId: subscriptionCouponFromURL?._id,
      sku: plan.sku,
    }).then(async (resFromAddItemToCart) => {
      if (resFromAddItemToCart.error) {
        Logger.error(resFromAddItemToCart.error);
        const errorMessage = resFromAddItemToCart.error.message.replace(
          /GraphQL error: Error: /g,
          '',
        );
        return { unknownError: errorMessage };
      }

      BBAnalytics.viewedSubscriptionEvent(
        resFromAddItemToCart.subscription.plan,
      );

      const { data } = await BitsyApollo.cartInfo();
      return data.cartInfo;
    });
  }
  const cartInfo = BitsyApollo.getTempCart();

  // removes previous coupon products before adding a new one
  if (cartInfo && cartInfo.productsAndDetails.length) {
    const prodsAndDets = cartInfo.productsAndDetails;
    const filteredProducts = prodsAndDets.filter(
      (item) => !item.isAddedCouponProduct,
    );
    cartInfo.productsAndDetails = filteredProducts;
  }

  const tempCartString = JSON.stringify(cartInfo);
  localStorage.setItem('tempCart', tempCartString);

  BitsyApollo.addItemToTempCart(
    {
      _id: 'tempsub',
      provider,
      plan,
      subscriptionCouponFromURL,
      sku: plan.sku,
    },
    'Subscription',
    list,
  );

  return cartInfo;
}

function determineAddToCartModal(
  shouldShowPreCheckoutPromo,
  postPurchasePromo,
) {
  let modalType;
  if (shouldShowPreCheckoutPromo) {
    modalType = 'PRE-CHECKOUT PROMO';
  } else if (postPurchasePromo) {
    modalType = 'SHOW-PROMOS';
  } else {
    modalType = 'CART SUCCESS';
  }

  return modalType;
}

function addProductToCart(
  productInfo,
  variation,
  newState,
  provider,
  promoItem,
  itemTrackingObj,
  postPurchasePromo,
  fetchCartWithPendingPurchase,
  shouldShowPreCheckoutPromo,
  list,
  isBreastPump,
  isDmeAddOn,
) {
  const stateToReturn = newState;

  removeTokenIfGuestAndPurchasedCart();

  let colorToUse = newState.color || 'NONE';
  let variationToUse = variation || 'NONE';
  let quantityToUse = newState.quantity || 1;

  if (promoItem === true) {
    colorToUse = 'NONE';
    variationToUse = 'NONE';
    quantityToUse = 1;
  }

  let itemToAdd;

  if (Auth.hasToken()) {
    if (!productInfo.subscriptionPlan) {
      itemToAdd = {
        product: productInfo._id,
        color: colorToUse,
        variation: variationToUse,
        quantity: quantityToUse,
        promoItem,
        fetchCartWithPendingPurchase,
        isBreastPump,
        isDmeAddOn,
        sku: productInfo.sku,
        name: productInfo.name,
      };
    } else {
      stateToReturn.isRecurringAddOnPromo = true;
      itemToAdd = {
        planId: productInfo.subscriptionPlan._id,
        sku: productInfo.subscriptionPlan.sku,
      };
    }
    return BitsyApollo.addItemToCart(itemToAdd, itemTrackingObj, list).then(
      async (res) => {
        if (res.error) {
          const errorMessage = res.error.message.replace(
            /GraphQL error: Error: /g,
            '',
          );
          return { unknownError: errorMessage };
        }
        const modalType = determineAddToCartModal(
          shouldShowPreCheckoutPromo,
          postPurchasePromo,
        );
        stateToReturn.modalType = modalType;
        stateToReturn.productAddedToCart = productInfo._id;
        return stateToReturn;
      },
    );
  }

  if (!productInfo.subscriptionPlan) {
    itemToAdd = {
      product: productInfo,
      color: colorToUse,
      variation: variationToUse,
      quantity: 1,
      promoItem,
      isDmeAddOn,
      name: productInfo.name,
      sku: productInfo.sku,
    };
    BitsyApollo.addItemToTempCart(itemToAdd);
    const modalType = determineAddToCartModal(
      shouldShowPreCheckoutPromo,
      postPurchasePromo,
    );

    stateToReturn.modalType = modalType;
    stateToReturn.productAddedToCart = productInfo._id;

    return stateToReturn;
  }
  itemToAdd = {
    _id: 'tempsub',
    provider,
    plan: productInfo.subscriptionPlan,
    sku: productInfo.subscriptionPlan.sku,
  };
  BitsyApollo.addItemToTempCart(itemToAdd, 'Subscription');
  const modal = determineAddToCartModal(
    shouldShowPreCheckoutPromo,
    postPurchasePromo,
  );
  stateToReturn.modalType = modal;
  stateToReturn.productAddedToCart = productInfo._id;
  stateToReturn.isRecurringAddOnPromo = true;
  return stateToReturn;
}

function validateCouponsInCart() {
  if (Auth.hasToken()) {
    return BitsyApollo.validateCouponsInCart();
  }
  return null;
}

function validateProductsInCart() {
  if (Auth.hasToken()) {
    return BitsyApollo.validateProductsInCart();
  }
  return null;
}

async function cartItemCountQuery(customerId) {
  const itemCountQuery = await BitsyApollo.cartItemCountQuery(customerId);
  const itemCount =
    itemCountQuery && itemCountQuery.data
      ? itemCountQuery.data.cartItemCount.numberOfItems
      : 0;
  return itemCount;
}

function determineErrorMessage(key, err, isCoupon) {
  if (isCoupon) {
    const removeMessage =
      key !== 'EMPTY_GIFTCARD'
        ? 'We have removed the coupon from your cart.'
        : 'We have removed the giftcard from your cart.';
    if (key === 'AUTO_APPLIED_APPROPRIATE_COUPON') return err;
    return `${err} ${removeMessage}`;
  }

  try {
    const productErr = JSON.parse(err);
    if (productErr.length > 1) {
      const names = productErr.map((e) => e.name);
      const productNames = names.join(', ').replace(/, ([^,]*)$/, ' and $1');
      return `${productNames} have been removed because they are either no longer carried or no longer in stock.`;
    }

    const { name, problem } = productErr[0];
    if (problem === 'no_longer_carried') {
      return `${name} has been removed because it is no longer available for purchase.`;
    }
    if (problem === 'no_stock') {
      return `${name} has been removed because it is no longer in stock. Please check back later.`;
    }
    if (problem === 'registry_box') {
      return `${name} has been removed because it must be purchased through the registry checkout.`;
    }
    if (problem === 'not_enough_in_stock_for_purchase') {
      return `${name} does not have enough in stock for this purchase. We have adjusted the quantity.`;
    }

    if (problem === 'no_sub_for_promo') {
      return `${name} has been removed because it must be purchased with a subscription.`;
    }

    if (problem === 'no_other_product_for_promo') {
      return `${name} has been removed because it must be purchased with another product.`;
    }
  } catch (error) {
    Logger.error(error, {
      key,
      err,
      isCoupon,
    });
  }

  return null;
}

function determineTaxjarErrorMessage(responseError, isAddShippingDataError) {
  let error = responseError.value;

  // For errors without value/key fields, like TaxJar errors
  if (!isAddShippingDataError) {
    const editedError = responseError
      .toString()
      .replace(/Error: /g, '')
      .replace(/GraphQL error: /g, '');

    if (editedError.includes("isn't a valid postal code")) {
      error = editedError
        .replace(/Bad Request - To zip /g, '')
        .replace(/\)/g, '')
        .replace(/\(/g, '');
    }

    if (editedError.includes('to_zip') && editedError.includes('to_state')) {
      error = editedError
        .replace(/Bad Request - /g, '')
        .replace(/to_zip/g, 'Zip code')
        .replace(/to_state/g, 'the state of');
    }
  }

  return error;
}

/**
 *
 * @param {Object} paymentData - Purchase and cart data. This object will
 *                               be modified directly
 * @param {*} specialErrorMessage
 * @param {*} isLoadingFlag - this is sort of a hack around if you have a container that has a isLoading state that could get clobbered by the sideeffect of another component also doing things with isLoading. This could probably be refactored out with some better component hierarchy
 */

async function purchaseCart(
  paymentData,
  specialErrorMessage,
  isLoadingFlag = 'isLoading',
) {
  const res = await BitsyApollo.purchaseCart(paymentData);
  // if we have a custom loading flag use it. otherwise other users of this util method
  // may already be handling state independently of the return so we don't want to
  // blindly include 'isLoading: false' in the return state.
  const isCustomLoadingFlag = isLoadingFlag !== 'isLoading';
  if (res.error) {
    const isNameInputError = (resError) => {
      resError.networkError?.result?.errors?.some(
        (err) =>
          err.message?.includes('shippingFirstName') ||
          err.message?.includes('shippingLastName'),
      );
    };
    const isAddressInputError = (resError) =>
      resError.networkError?.result?.errors?.some(
        (err) =>
          err.message?.includes('shippingStreetAddress') ||
          err.message?.includes('shippingCity') ||
          err.message?.includes('shippingState') ||
          err.message?.includes('shippingZipCode'),
      );
    if (isNameInputError(res.error)) {
      const returnState = {
        unknownError:
          'Oops! It seems there was an issue with your name. Please check your input and try again.',
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    }
    if (isAddressInputError(res.error)) {
      const returnState = {
        unknownError:
          'Oops! It seems there was an issue with your shipping address. Please check your input and try again.',
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    }

    const error = res.error
      .toString()
      .replace(/Error: /g, '')
      .replace(/GraphQL error: /g, '');
    const errorComponents = error.split('--');
    if (
      errorComponents &&
      errorComponents.length >= 2 &&
      errorComponents[0] === 'STRIPE_ERROR'
    ) {
      if (specialErrorMessage === 'logged') {
        const returnState = {
          unknownError: `Oops! We've logged you in but ${errorComponents[1].toLowerCase()}`,
          hasPaymentBeenSubmitted: false,
        };
        if (isCustomLoadingFlag) {
          returnState[isLoadingFlag] = false;
        }
        return returnState;
      }
      if (specialErrorMessage === 'guest') {
        const returnState = {
          unknownError: `Oops! We've stored your email and phone number but ${errorComponents[1].toLowerCase()}`,
          hasPaymentBeenSubmitted: false,
        };
        if (isCustomLoadingFlag) {
          returnState[isLoadingFlag] = false;
        }
        return returnState;
      }
      if (specialErrorMessage === 'none') {
        const returnState = {
          unknownError: `Oops! ${errorComponents[1]}`,
          hasPaymentBeenSubmitted: false,
        };
        if (isCustomLoadingFlag) {
          returnState[isLoadingFlag] = false;
        }
        return returnState;
      }
    } else if (error === 'PAYMENT_FAILED') {
      const returnState = {
        paymentError: true,
        [isLoadingFlag]: false,
        isError: true,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'INVALID_COUPON') {
      const returnState = {
        unknownError:
          'Oops, the coupon you used is invalid for this box type! The payment did not go through. We removed the coupon from this purchase, please review and try again.',
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'INVALID_SHIPPING_OPTION') {
      const returnState = {
        unknownError:
          'Oops! You do not qualify for free shipping. Please adjust your shipping selection and try again!',
        currentStatus: 'SHIPPING',
        currentStatusOverride: true,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'MUST_SELECT_INTERNATIONAL_SHIPPING') {
      const returnState = {
        unknownError:
          'Oops! Your shipping address is outside of the United States and you do not have the international shipping option selected. Please adjust your shipping selection and try again!',
        currentStatus: 'SHIPPING',
        currentStatusOverride: true,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'MUST_SELECT_OCONUS_SHIPPING') {
      const returnState = {
        unknownError:
          'Oops! You are shipping to Alaska or Hawaii and have not selected the Alaska and Hawaii or another paid shipping option. Please adjust your shipping selection and try again',
        currentStatus: 'SHIPPING',
        currentStatusOverride: true,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'PURCHASE_MADE_ERROR_FOLLOWED') {
      const returnState = {
        modalType: 'worstCaseScenarioCheckout',
        hasPaymentBeenSubmitted: false,
        isError: true,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'ALREADY_PURCHASED') {
      const returnState = {
        unknownError:
          'No additionals items can be added to your purchase at this time.',
        hasPaymentBeenSubmitted: false,
        modalType: 'ERROR',
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'CORPORATE_PRODUCTS_ONLY') {
      const returnState = {
        unknownError:
          'Your products are not available for purchase through the corporate portal.',
        hasPaymentBeenSubmitted: false,
        modalType: 'ERROR',
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'MISSING_COMPANY_CODE') {
      const returnState = {
        unknownError:
          'There was a problem verifying your account. Please log out and then log in before trying to purchase your cart.',
        hasPaymentBeenSubmitted: false,
        modalType: 'ERROR',
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'No email found for purchaser.') {
      const returnState = {
        unknownError: `No email was found. Please check your email was entered. If you continue to encounter this error, please contact our customer experience team at 888-913-7879 or ${Content.names.helpEmail}!`,
        hasPaymentBeenSubmitted: false,
        modalType: 'ERROR',
        pageDisplayed: 'shipping',
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error.includes('Oops!')) {
      const returnState = {
        unknownError: error,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (
      error.includes("isn't a valid postal code") ||
      (error.includes('to_zip') && error.includes('to_state'))
    ) {
      const isAddShippingData = false;
      const taxjarError = determineTaxjarErrorMessage(error, isAddShippingData);

      const returnState = {
        unknownError: taxjarError,
        hasPaymentBeenSubmitted: false,
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }
      return returnState;
    } else if (error === 'NO CART FOUND DURING PURCHASE') {
      return res;
    } else {
      const returnState = {
        unknownError: `Oops, something went wrong! If you continue to encounter this error, please contact our customer experience team at 888-913-7879 or ${Content.names.helpEmail}!`,
        hasPaymentBeenSubmitted: false,
        modalType: 'ERROR',
      };
      if (isCustomLoadingFlag) {
        returnState[isLoadingFlag] = false;
      }

      return returnState;
    }
  }

  // Remove MaxBounty ID after a successful purchase
  if (
    paymentData.registryId ||
    paymentData.companyCode ||
    paymentData.isPendingPayment === false
  ) {
    localStorage.removeItem('maxBountyClickId');
  }
  return res;
}

async function clearCart() {
  if (Auth.hasToken()) {
    localStorage.removeItem('tempCart');
    return BitsyApollo.clearCart();
  }
  return null;
}

async function addAddressToRegistryCart(registryId) {
  await BitsyApollo.addAddressToRegistryCart({ registryId });
}

export default {
  getCart,
  changeQuantityOfProduct,
  deleteProductFromCart,
  deleteSubFromCart,
  addDiscountToCart,
  removeDiscountFromCart,
  addSubscriptionToCart,
  addProductToCart,
  validateCouponsInCart,
  validateProductsInCart,
  cartItemCountQuery,
  determineErrorMessage,
  purchaseCart,
  clearCart,
  determineTaxjarErrorMessage,
  addAddressToRegistryCart,
};
