import React from 'react';
import { Col, Button } from 'react-bootstrap';
import './style.css';

const CrossPromoBlock = () => {
  const goToPromoURL = () => {
    const promoURL = 'https://bumpboxes.com';
    window.open(promoURL, '_blank');
  };

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className='mt-4 p-4 crossPromoBlockContainer'
    >
      <h3>The #1 Pregnancy Subscription Box</h3>
      <p>
        Each month you&apos;ll receive a Bump Box with full-sized products
        tailored to your due date and safe for your baby!
      </p>
      <Button variant='info' onClick={goToPromoURL}>
        Get 50% Off + Free Gift
      </Button>
      <p>Includes FREE Shipping</p>
    </Col>
  );
};

export default CrossPromoBlock;
