import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeaheadToggleButton from '../typeahead-toggle-button';
import './style.css';

const FilterDropdown = (props) => {
  const handleChange = (selectedValues) => {
    props.onChange({
      target: {
        name: props.name,
        value: selectedValues[0],
      },
    });
  };

  return (
    <Typeahead
      className='comboAutoComplete'
      id={props.id}
      placeholder='Type here to search'
      options={props.options}
      onChange={handleChange}
      parent={props.parent}
      name={props.name}
      value={props.value}
      defaultInputValue={props.defaultInputValue}
    >
      {({ isMenuShown, toggleMenu }) => (
        // toggleMenu comes from the typeahead component library
        <TypeaheadToggleButton
          isOpen={isMenuShown}
          onClick={() => toggleMenu()}
        />
      )}
    </Typeahead>
  );
};

export default FilterDropdown;
