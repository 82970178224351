import React, { useState } from 'react';
import { Row, Col, Button, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import BitsyModal from '../bitsy-modal';

import './style.scss';

export default function SMSOptIn(props) {
  const [modalType, setModalType] = useState('NONE');

  const exitModal = () => setModalType('NONE');

  const renderModal = () => {
    if (modalType === 'smsOptInDetails') {
      return (
        <BitsyModal
          isShowingModal
          content='smsOptInDetails'
          onExit={exitModal}
          onClickMainButton={exitModal}
          type='bodyAndExitModal'
          h2ClassName='smsOptInTitle'
          modalHeaderClass='smsOptInModalHeader'
          data={{}}
        />
      );
    }
    return null;
  };

  const handleCheckboxToggle = async () => {
    props.handleSMSOptInToggle();
  };

  return (
    <Row id={props.containerId}>
      {renderModal()}
      <Col className='sms-optIn-container'>
        <FormCheck aria-label='Edit description for SMS Account Sign Up Opt In'>
          <FormCheck.Label>
            <FormCheck.Input
              checked={props.isSmsOptInSelected}
              onChange={handleCheckboxToggle}
              name='isSmsOptInSelected'
              isStatic={false}
              id='smsOptInInput'
            />
            {props.giveawaySMSMessage
              ? props.giveawaySMSMessage
              : 'Yes, I would like to receive text updates from Bump Boxes regarding Latest News and Promotions.'}
            <Button
              className='infoPopupButton'
              variant='incognito'
              onClick={() => setModalType('smsOptInDetails')}
            >
              <FontAwesomeIcon icon={faCircleInfo} size='lg' />
            </Button>
          </FormCheck.Label>
        </FormCheck>
      </Col>
    </Row>
  );
}
