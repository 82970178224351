import React from 'react';
import './style.css';

// if you want a progress bar, pass in percentage and progressStrokeWidth
// if you want just text with a circle, pass in text

const ProgressCircle = (props) => {
  // Size of the enclosing square
  const { sqSize } = props;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize / 2) - (props.sqSize * 0.05);
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - dashArray * props.percentage / 100;
  return (
    <svg
      width={props.sqSize}
      height={props.sqSize}
      viewBox={viewBox}
    >
      <circle
        className='circle-background'
        cx={props.sqSize / 2}
        cy={props.sqSize / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`} />
      {props.progressStrokeWidth ? (
        <circle
          className='circle-progress'
          cx={props.sqSize / 2}
          cy={props.sqSize / 2}
          r={radius}
          strokeWidth={`${props.progressStrokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      ) : null}
      <text
        className='circle-text'
        x='50%'
        y='50%'
        dy='.3em'
        textAnchor='middle'>
        {props.percentage ? `${props.percentage}%` : props.text}
      </text>
    </svg>
  );
};

export default ProgressCircle;

// used and modified from https://codepen.io/bbrady/pen/ozrjKE
