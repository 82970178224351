import React from 'react';
import './style.css';

const ProductReviewRating = (props) => (
  <div
    className='productReviewRating yotpo bottomLine'
    data-appkey='MtI6WMUc1SMDm8DW3ZmRDSTA4TUsLomtzi81ZY7C'
    data-domain='https://bumpboxes.com/'
    data-product-id={props.product?.sku}
    data-name={props.product?.name}
    data-url={`https://bumpboxes.com/products/${props.product?.urlName?.toLowerCase()}`}
    data-image-url={props.product?.media[0] ? props.product?.media[0].url : ''}
  />
);

export default React.memo(ProductReviewRating);
