/* eslint-disable camelcase */
import gql from 'graphql-tag';
import serviceUtils from './serviceUtils';
import logger from './Logger';

/**
 * Retrieves user information from the apollo cache based on the token stored in the local storage.
 * @returns {Object} The user information object containing _id, email, and phoneNumber.
 */
const getUserInfoFromCache = () => {
  const userInfo = {
    _id: '',
    email: '',
    phoneNumber: '',
  };
  const token = localStorage.getItem('token');
  if (token) {
    // Decode the JWT token to get the user id
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayloadString = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          const code = `00${c.charCodeAt(0).toString(16)}`;
          return `%${code.slice(-2)}`;
        })
        .join(''),
    );

    const tokenPayload = JSON.parse(jsonPayloadString);
    userInfo._id = tokenPayload._id;

    try {
      const userInfoFromCache = window.apolloClient.readFragment({
        id: `User:${tokenPayload._id}`,
        fragment: gql`
          fragment userWithEmail on User {
            _id
            email
            phoneNumber
          }
        `,
      });
      if (userInfoFromCache) {
        userInfo._id = userInfoFromCache._id;
        userInfo.email = userInfoFromCache.email;
        userInfo.phoneNumber = userInfoFromCache.phoneNumber;
      }
    } catch {
      // assume that the user lookup failed because they have a guestCheckoutEmail instead of an email, try a new lookup
      try {
        const userInfoFromCache = window.apolloClient.readFragment({
          id: `User:${tokenPayload._id}`,
          fragment: gql`
            fragment userWithGuestCheckoutEmail on User {
              _id
              guestCheckoutEmail
              phoneNumber
            }
          `,
        });
        if (userInfoFromCache) {
          userInfo._id = userInfoFromCache._id;
          userInfo.email = userInfoFromCache.email;
          userInfo.phoneNumber = userInfoFromCache.phoneNumber;
        }
      } catch {
        return userInfo;
      }
    }
  }
  return userInfo;
};

/**
 * Sends an event to Tiktok tracking.
 *
 * @param {string} eventName - The name of the event to track.
 * @param {object} data - The data associated with the event.
 * @param {object} [userInfo] - The user information.
 */
const sendEvent = (eventName, data, userInfo) => {
  try {
    if (window.ttq) {
      const userInfoFromCache = getUserInfoFromCache();
      // Use passed in user info if present, this is to handle guest checkout where the info will not be available in the cache
      if (userInfo?.email) {
        userInfoFromCache.email = userInfo.email;
      }
      if (userInfo?.phoneNumber) {
        userInfoFromCache.phoneNumber = userInfo.phoneNumber;
      }

      const phoneNumber =
        serviceUtils.getE164FormattedPhoneNumber(
          userInfoFromCache.phoneNumber,
        ) || '';
      window.ttq.identify({
        email:
          userInfoFromCache.email || userInfoFromCache.guestCheckoutEmail || '',
        phone_number: phoneNumber,
        external_id: userInfoFromCache._id || '',
      });

      window.ttq.track(eventName, data);
    }
  } catch (error) {
    logger.error(error);
  }
};

/**
 * Tracks the event when payment information is added.
 *
 * @param {Object} [userInfo] - The user information.
 */
const trackAddPaymentInfo = (userInfo) => {
  // During guest checkout user info will not be present in the apollo cache, so we pass it from the checkout component to here
  sendEvent('AddPaymentInfo', undefined, userInfo);
};

/**
 * Retrieves the product properties for the given cart.
 *
 * @param {Array} cart - The cart containing the products.
 * @returns {Array} - An array of product properties for TikTok tracking.
 */
const getProductsForCart = (cart) => {
  const contents = [];
  const cartProperties = serviceUtils.getProductPropertiesFromCart(cart);

  cartProperties.forEach((product) => {
    contents.push(
      serviceUtils.getProductPropertiesForTikTok(
        product,
        product.price,
        product.quantity,
      ),
    );
  });

  return contents;
};

/**
 * Retrieves the cart properties for tracking.
 *
 * @param {Object} cart - The cart object.
 * @returns {Object} - The properties object for tracking.
 */
const getCartProperties = (cart) => {
  const value = cart?.subCosts?.overallTotal || 0;
  const properties = {
    content_type: 'product',
    currency: 'USD',
    value,
    contents: getProductsForCart(cart),
  };

  return properties;
};

/**
 * Tracks the initiation of the checkout process.
 *
 * @param {Object} cart - The cart object containing the items being checked out.
 */
const trackCheckoutStarted = (cart) => {
  const properties = getCartProperties(cart);

  sendEvent('InitiateCheckout', properties);
};

/**
 * Tracks the view content event.
 *
 * @param {Object} item - The item to be tracked.
 * @param {boolean} isProduct - Indicates whether the item is a product or a subscription plan.
 */
const trackViewContent = (item, isProduct) => {
  const pseudoCart = {};
  if (isProduct) {
    pseudoCart.productsAndDetails = [
      {
        product: item,
      },
    ];
  } else {
    pseudoCart.subscription = {
      plan: item,
    };
  }

  const properties = getCartProperties(pseudoCart);
  let total = 0;
  properties.contents.forEach((product) => {
    total += product.price;
  });
  properties.value = total;

  sendEvent('ViewContent', properties);
};

const trackSubmitForm = (formDescription) => {
  const properties = {
    description: formDescription,
  };
  sendEvent('SubmitForm', properties);
};

export default {
  trackAddPaymentInfo,
  trackCheckoutStarted,
  trackViewContent,
  trackSubmitForm,
};
