/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import BBAnalytics from '../../services/BBAnalytics';
import Features from '../../services/Features';
import Auth from '../../services/Auth';
import BitsyApollo from '../../services/BitsyApollo';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    BBAnalytics.pageViewEvent(page);
  };

  const HOC = class extends Component {
    getUser = async (data) => {
      if (Auth.isLoggedIn()) {
        const user = await BitsyApollo.userInfoQuery(data);
        if (user?.data?.userInfo) {
          const userData = user.data.userInfo;
          return userData;
        }

        return null;
      }

      return null;
    };

    async componentDidMount() {
      let page = this.props.location.pathname;
      if (page.includes('/purchase/thank-you/')) {
        page = '/purchase/thank-you';
      }

      trackPage(page);

      const userInfo = await this.getUser();

      if (userInfo) {
        await Features.updateUserAsync({
          userID: userInfo._id,
          email: userInfo.email || userInfo.guestCheckoutEmail,
        });
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return (
        // eslint-disable-next-line
        <WrappedComponent {...this.props} {...options} features={Features} />
      );
    }
  };

  return HOC;
}
