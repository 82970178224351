import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import 'react-app-polyfill/stable';

import App from './containers/app';
import BitsyApollo from './services/BitsyApollo';

const BitsyCustomerFrontendApp = (
  <ApolloProvider client={BitsyApollo.client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

render(BitsyCustomerFrontendApp, document.getElementById('root'));
