import TagManager from 'react-gtm-module';
import Environment from './Environment';
import Logger from './Logger';

// The actual trackers
import FBTrackingActual from './FBTracking';
import KlaviyoActual from './Klaviyo';
import GoogleAnalyticsActual from './GoogleAnalytics';
import AdWordsActual from './AdWords';
import OathTagActual from './OathTag';
import BingTrackingActual from './BingTracking';
import TiktokTrackingActual from './TiktokTracking';

// Proxied services
let FBTracking;
let Klaviyo;
let GoogleAnalytics;
let AdWords;
let OathTag;
let BingTracking;
let TiktokTracking;

function isProduction() {
  return Environment.getEnv === 'PRODUCTION';
}

// Helper variables
let trackersInitialized = false;
let trackerState = {};

// used for testing purposes
function setTrackersInitialized(initValue) {
  trackersInitialized = initValue;
}

const pageViewQueue = [];

function handleException(e) {
  try {
    Logger.error(e);
  } catch (ravenError) {
    console.log(`Analytics error handler could not record entry: ${e}`); // eslint-disable-line no-console
    console.log(`Analytics error handler also received: ${ravenError}`); // eslint-disable-line no-console
  }
}

function createAnalyticsProxy(obj, isEnabled) {
  return new Proxy(obj, {
    get(target, prop) {
      if (typeof target[prop] === 'function') {
        return new Proxy(target[prop], {
          apply: (targetApply, thisArg, argumentsList) => {
            if (isEnabled) {
              try {
                return Reflect.apply(targetApply, thisArg, argumentsList);
              } catch (e) {
                handleException(e);
              }
            }
            return false;
          },
        });
      }

      return Reflect.get(target, prop);
    },
  });
}

const setupProxies = () => {
  FBTracking = createAnalyticsProxy(
    FBTrackingActual,
    trackerState.isFacebookEnabled,
  );
  Klaviyo = createAnalyticsProxy(KlaviyoActual, trackerState.isKlaviyoEnabled);
  GoogleAnalytics = createAnalyticsProxy(
    GoogleAnalyticsActual,
    trackerState.isGoogleAnalyticsEnabled,
  );
  AdWords = createAnalyticsProxy(
    AdWordsActual,
    trackerState.isGoogleAdwordsEnabled,
  );
  OathTag = createAnalyticsProxy(OathTagActual, trackerState.isOathEnabled);
  BingTracking = createAnalyticsProxy(
    BingTrackingActual,
    trackerState.isBingEnabled,
  );
  TiktokTracking = createAnalyticsProxy(
    TiktokTrackingActual,
    trackerState.isTiktokEnabled,
  );
};

function setTrackersState(newTrackerState = {}) {
  trackerState = {
    isGoogleAnalyticsEnabled: isProduction(),
    isGoogleAdwordsEnabled: isProduction(),
    isGoogleTagManagerEnabled: isProduction(),
    isFacebookEnabled: isProduction(),
    isKlaviyoEnabled: true,
    isOathEnabled: isProduction(),
    isBingEnabled: isProduction(),
    isTiktokEnabled: true,

    // override above values, if present
    ...newTrackerState,
  };

  setupProxies();
}

setTrackersState();

/**
 * Wraps analytics calls as a promise that logs and rejects after logging the result
 * @param {string} type - The type used when logging
 * @param {function} payloadFunction - Function to wrap
 */
const safelyCallAnalytics = (type, payloadFunction) =>
  new Promise((resolve) => {
    try {
      payloadFunction();
      resolve();
    } catch (e) {
      handleException(e);

      // GOTCHA: This function will eat exceptions.
      resolve(`Could not track ${type}. ${e}`);
    }
  });

/**
 * Does a quick check of the cart for digital only 0 value items
 * @param {*} cartInfo graphql query {GraphQLTags.queries.cartInfoQuery}
 * @returns {boolean} if the cart has a actual cost on any of the items we
 * consider it to have value
 */
function isNoValuePurchase(cartInfo) {
  try {
    if (
      cartInfo.isCartDigitalOnly &&
      cartInfo.productsAndDetails.reduce((acc, val) => {
        return acc + val.product.actualCost;
      }, 0) === 0
    ) {
      return true;
    }
  } catch (e) {
    handleException(e);
  }
  return false;
}

function getAdvancedMatchingData(data) {
  if (!data) {
    return null;
  }

  const advancedMatchingData = {};
  const email = data.email || data.guestCheckoutEmail;
  if (email) {
    advancedMatchingData.em = email.toLowerCase();
  }
  if (data.name && data.name.first && data.name.last) {
    advancedMatchingData.fn = data.name.first.toLowerCase();
    advancedMatchingData.ln = data.name.last.toLowerCase();
  }
  if (data.phoneNumber) {
    advancedMatchingData.ph = data.phoneNumber;
  }
  if (data.billingAddress && data.billingAddress.zipCode) {
    advancedMatchingData.zp = data.billingAddress.zipCode;
    advancedMatchingData.ct = data.billingAddress.city.toLowerCase();
    advancedMatchingData.st = data.billingAddress.state.toLowerCase();
  } else if (data.purchases && data.purchases.length) {
    const paidPurchases = data.purchases.filter(
      (purchase) => purchase.paidStatus === 'PAID',
    );
    const mostRecentPurchase =
      paidPurchases && paidPurchases.length ? paidPurchases.pop() : null;
    if (
      mostRecentPurchase &&
      mostRecentPurchase.order &&
      mostRecentPurchase.order.billingAddress
    ) {
      advancedMatchingData.zp = mostRecentPurchase.order.billingAddress.zipCode;
    }
  }
  if (data.userId) {
    // eslint-disable-next-line camelcase
    advancedMatchingData.external_id = data.userId;
  }

  return advancedMatchingData;
}

/**
 * Handles events for when a user changes pages even if the
 * page load is handled client side.
 */
const pageViewEvent = async (page, options) =>
  safelyCallAnalytics('pageViewEvent', () => {
    if (
      !trackersInitialized &&
      (trackerState.isGoogleAnalyticsEnabled ||
        trackerState.isFacebookEnabled ||
        trackerState.isBingEnabled)
    ) {
      pageViewQueue.push({ page, options });
    } else {
      try {
        AdWords.configurePagePath(page);
      } catch (e) {
        handleException(e);
      }

      BingTracking.pageView(page);
    }
  });

const runTheInits = async () => {
  /** GOTCHA
   * This function is called from the componentDidMount method of the app/index.js component.
   * There is a chance that external scripts will not be loaded by the time this function is called.
   */
  return new Promise((resolve, reject) => {
    if (!trackersInitialized) {
      setupProxies();

      try {
        if (trackerState.isGoogleTagManagerEnabled) {
          TagManager.initialize({
            gtmId: Environment.getGTMId,
          });
        }

        if (
          trackerState.isGoogleAnalyticsEnabled ||
          trackerState.isGoogleAdwordsEnabled
        ) {
          GoogleAnalytics.init();
        }

        // Must happen before reprocessing the pageViewQueue
        trackersInitialized = true;
        pageViewQueue.forEach((pageEventObject) => {
          pageViewEvent(pageEventObject.page, pageEventObject.options);
        });
      } catch (e) {
        handleException(e);
        reject(`runTheInits broke. ${e}`); // eslint-disable-line prefer-promise-reject-errors
      }
    } else {
      trackersInitialized = true;

      // drain any existing entries in the queue
      pageViewQueue.splice(0, pageViewQueue.length);
    }

    resolve();
  });
};

const addAdvancedMatchingDataToMetaPixel = (userData) => {
  const advancedMatchingData = getAdvancedMatchingData(userData);
  FBTracking.init(advancedMatchingData);
};

/**
 * Handles events for brand new users
 */
const listSignupEvent = async (userData) =>
  safelyCallAnalytics('listSignupEvent', () => {
    Klaviyo.identifyUser({
      email: userData.email,
      source: userData.source,
    });
  });

/**
 * Handles events for brand new users
 */
const newUserEvent = async (userData, source) =>
  safelyCallAnalytics('newUserEvent', () => {
    Klaviyo.addUserProfile(userData, source);
    GoogleAnalytics.setUserId(userData);
  });

/**
 * Handles events for when an existing user has returned
 */
const returningUserEvent = async (userData, page) =>
  safelyCallAnalytics('returningUserEvent', () => {
    Klaviyo.addUserProfile(userData);
    GoogleAnalytics.setUserId(userData);
    GoogleAnalytics.accountDetailsUpdated(page);
  });

const setUserDataForGoogleEnchancedConversions = (user) => {
  try {
    if (trackerState.isGoogleTagManagerEnabled) {
      const emailForGtag = user.email || user.guestCheckoutEmail;
      if (emailForGtag) {
        GoogleAnalytics.setUserData(emailForGtag);
      }
    }
  } catch (e) {
    handleException(e);
  }
};

/**
 * Handles events for when a purchase/conversion happens
 */
const purchaseEvent = async (purchaseEventData, cartInfo) =>
  safelyCallAnalytics('purchaseEvent', () => {
    if (purchaseEventData.res?.purchaser) {
      setUserDataForGoogleEnchancedConversions(purchaseEventData.res.purchaser);
    }

    /**
     * we are using this flag to change how analytics are
     * handled for FB and GA. If we only have digital free items
     * (currently only digital prenancy and gender announcements)
     * then it isn't considered a full purchase. FB is treated
     * as a lead and GA is not completed like a normal purchase
     */
    const isNoValuePurchaseFlag = isNoValuePurchase(cartInfo);
    if (!isNoValuePurchaseFlag) {
      GoogleAnalytics.transactionComplete(purchaseEventData, cartInfo);
      FBTracking.FBTransactionComplete(purchaseEventData, cartInfo);

      if (
        cartInfo &&
        cartInfo.shippingInsurance &&
        cartInfo.shippingInsurance.cost
      ) {
        GoogleAnalytics.addShippingInsurance();
      }
    } else {
      FBTracking.addLead();
    }

    Klaviyo.updateUserPoints(
      purchaseEventData?.res?.purchaser,
      purchaseEventData?.res?.userPoints,
    );

    AdWords.transactionComplete(purchaseEventData, cartInfo);
    OathTag.purchaseComplete(purchaseEventData);
    BingTracking.purchaseComplete(purchaseEventData, cartInfo);
  });

/**
 * Handles events for when a user views a single product
 */
const viewedProductEvent = async (productData, isPromo, position) =>
  safelyCallAnalytics('viewedProductEvent', () => {
    const isProduct = true;
    Klaviyo.viewedProduct(productData);
    FBTracking.viewedProduct(productData);
    GoogleAnalytics.viewProduct(productData, isProduct, isPromo, position);
    OathTag.viewedProduct(productData, isProduct);
    TiktokTracking.trackViewContent(productData, isProduct);
  });

/**
 * Handles events for when a user views a subscription
 */
const viewedSubscriptionEvent = async (subscriptionData) =>
  safelyCallAnalytics('viewedSubscriptionEvent', () => {
    const isProduct = false;
    Klaviyo.viewedSubscription(subscriptionData);
    FBTracking.viewedSubscription(subscriptionData);
    GoogleAnalytics.viewProduct(subscriptionData, isProduct);
    OathTag.viewedProduct(subscriptionData, isProduct);
    TiktokTracking.trackViewContent(subscriptionData);
  });

const getItemToAddWithData = (cartData, itemToAdd) => {
  try {
    let itemType = 'subscription';
    let item;
    if (itemToAdd?.plan) {
      item = itemToAdd;
    } else if ((cartData.subscription && !itemToAdd) || itemToAdd?.planId) {
      item = cartData.subscription;
    } else {
      itemType = 'product';
      item = itemToAdd;
      if (typeof itemToAdd?.product === 'string') {
        item = cartData.productsAndDetails.find(
          (productsAndDetailsItem) =>
            productsAndDetailsItem.product._id === itemToAdd.product,
        );
      }
    }

    if (itemType === 'subscription') {
      return {
        eventValue: 'subscription',
        amountToCharge: item.plan.costWithCoupon || item.plan.actualCost,
        amountToChargeForIndividualItem:
          item.plan.costWithCoupon || item.plan.actualCost,
        productId: item.plan._id,
        quantity: 1,
      };
    }

    if (item) {
      let amountToCharge;
      let amountToChargeForIndividualItem;
      if (!item.quantity) {
        item.quantity = 1;
      }
      if (item.promoItem && item.product.ownPromoData) {
        amountToCharge = item.product.ownPromoData.cost;
        amountToChargeForIndividualItem = amountToCharge;
      } else if (item.amountToCharge) {
        amountToCharge = item.amountToCharge;
        amountToChargeForIndividualItem = parseFloat(
          (item.amountToCharge / item.quantity).toFixed(2),
          10,
        );
      } else {
        amountToCharge = item.product.actualCost || 0;
        amountToChargeForIndividualItem = amountToCharge;
      }

      return {
        eventValue: 'product',
        amountToCharge,
        amountToChargeForIndividualItem,
        productId: item.product._id,
        quantity: item.quantity,
      };
    }
    return null;
  } catch (e) {
    handleException(e);
    return undefined;
  }
};

/**
 * Handles events for when a user's cart is updated
 */
const updatedCartEvent = async (cartData, itemTrackingObj, itemToAdd, list) =>
  safelyCallAnalytics('updatedCartEvent', () => {
    Klaviyo.updatedCart(cartData);
    GoogleAnalytics.addToCart(cartData, list);

    if (itemTrackingObj) {
      GoogleAnalytics.addToCartItemTracking(itemTrackingObj);
    }

    if (itemToAdd) {
      FBTracking.addToCart(cartData, itemToAdd);
    }

    const itemToAddWithData = getItemToAddWithData(cartData, itemToAdd);
    if (itemToAddWithData) {
      OathTag.addToCart(itemToAddWithData);
      BingTracking.addToCart(itemToAddWithData);
    }
  });

/**
 * Handles events for when a user change their due date
 */
const setDueDateEvent = async (date, email, optInSource) =>
  safelyCallAnalytics('setDueDateEvent', () => {
    if (date) {
      Klaviyo.setDueDateForUser(date, email, optInSource);
    }
  });

const itemsLoaded = async (eventName) =>
  safelyCallAnalytics('itemsLoaded', () => {
    if (trackerState.isGoogleTagManagerEnabled) {
      GoogleAnalytics.promoProductsLoaded(eventName);
    }
  });

/**
 * Handles events for starting checkout
 */
const checkoutStarted = async (cart) =>
  safelyCallAnalytics('checkoutStarted', () => {
    Klaviyo.checkoutStarted(cart);
    FBTracking.checkoutStarted(cart);
    GoogleAnalytics.beginCheckout(cart);
    TiktokTracking.trackCheckoutStarted(cart);
  });

const checkoutProcess = async (cartInfo, stepNum) =>
  safelyCallAnalytics('checkoutProcess', () => {
    if (stepNum === 2) {
      GoogleAnalytics.checkoutShippingInfoSubmitted(cartInfo);
    }
    if (stepNum === 3) {
      GoogleAnalytics.checkoutCustomizationInfoSubmitted(cartInfo);
    }
    if (stepNum === 4) {
      GoogleAnalytics.checkoutPaymentInfoSubmitted(cartInfo);
    }
  });

const paymentInfoSubmitted = async (userInfo) =>
  safelyCallAnalytics('paymentInfoSubmitted', () => {
    TiktokTracking.trackAddPaymentInfo(userInfo);
  });

const breastPumpProcess = async (stepNum, email, bpData) =>
  safelyCallAnalytics('breastPumpProcess', () => {
    if (email) {
      setUserDataForGoogleEnchancedConversions({ email });
    }
    GoogleAnalytics.breastPumpProcess(stepNum);
    Klaviyo.breastPumpProcess(stepNum, email, bpData);
    if (stepNum === '3A') {
      AdWords.breastPumpProcessAdWords();
      FBTracking.addLead();
      TiktokTracking.trackSubmitForm('DME Form Completed');
    }
  });

const viewPromo = async (promoData) =>
  safelyCallAnalytics('viewPromo', () => {
    GoogleAnalytics.viewPromo(promoData);
  });

const updatedCreditCard = async (hasError) =>
  safelyCallAnalytics('updatedCreditCard', () => {
    GoogleAnalytics.clickUpdateCreditCard(hasError);
  });

const usePreviousCard = async () =>
  safelyCallAnalytics('usePreviousCard', () => {
    GoogleAnalytics.usePreviousCard();
  });

const myAccountDropDown = async (dropdownItem) =>
  safelyCallAnalytics('myAccountDropDown', () => {
    GoogleAnalytics.clickMyAccountDropdown(dropdownItem);
  });

const subscriptionAddOnsChanged = async (action, label) =>
  safelyCallAnalytics('subscriptionAddOnsChanged', () => {
    GoogleAnalytics.subscriptionAddOnsChanged(action, label);
  });

const viewPartner = async (email, partner) =>
  safelyCallAnalytics('viewPartner', () => {
    Klaviyo.viewPartner(email, partner);
    GoogleAnalytics.clickInsurancePartnerLink(partner);
  });

const createRegistry = async (email, dueDate) =>
  safelyCallAnalytics('createRegistry', () => {
    Klaviyo.addRegistryToUser(email, dueDate);
  });

const breastPumpPartnerApiInfoSent = async (email, partner) =>
  safelyCallAnalytics('breastPumpPartnerApiInfoSent', () => {
    Klaviyo.breastPumpPartnerApiInfoSent(email, partner);
    GoogleAnalytics.breastPumpPartnerApiInfoSent(partner);
  });

const registryProcess = async (stepNum, email, registryCode) =>
  safelyCallAnalytics('registryProcess', () => {
    GoogleAnalytics.registryProcess(stepNum);
    Klaviyo.registryProcess(stepNum, email, registryCode);
  });

const addActivationEvent = async (eventName) =>
  safelyCallAnalytics('addActivationEvent', () => {
    GoogleAnalytics.subscriptionPlansVisible(eventName);
  });

const clickedTrackingNumber = async () =>
  safelyCallAnalytics('clickedTrackingNumber', () => {
    GoogleAnalytics.clickedTrackingNumber();
  });

const corpGiftingSubmit = async () =>
  safelyCallAnalytics('corpGiftingSubmit', () => {
    GoogleAnalytics.corpGiftingSubmit();
  });

const dueDateCalcSubmitted = async (isOptedIn) =>
  safelyCallAnalytics('dueDateCalcSubmitted', () => {
    GoogleAnalytics.dueDateCalcSubmitted();
    if (isOptedIn) {
      GoogleAnalytics.dueDateCalcOptedIntoEmail();
    }
  });

const digitalPregnancyAnnouncementDownloaded = async () =>
  safelyCallAnalytics('digitalPregnancyAnnouncementDownloaded', () => {
    GoogleAnalytics.digitalPregnancyAnnouncementDownloaded(
      'Digital Pregnancy Announcement - Downloaded',
    );
    AdWords.digitalPregnancyAnnouncementAdWords();
    FBTracking.addLead();
  });

const coregIncentiveAchieved = async () =>
  safelyCallAnalytics('coregIncentiveAchieved', () => {
    GoogleAnalytics.coregIncentiveAchieved();
    AdWords.addCoregLead();
    FBTracking.addLead();
  });

const babyNamesListViewed = async () =>
  safelyCallAnalytics('babyNamesListViewed', () => {
    GoogleAnalytics.digitalServicesDownloaded(
      'Baby Name Matcher - List Viewed',
    );
    AdWords.addBabyNameMatcherLead();
    FBTracking.addLead();
  });

const scheduledCancellationOrCompletion = async () =>
  safelyCallAnalytics('scheduledCancellationOrCompletion', () => {
    GoogleAnalytics.scheduledCancel();
  });

const cancelButtonClicked = async () =>
  safelyCallAnalytics('cancelButtonClicked', () => {
    GoogleAnalytics.cancelButtonClicked();
  });

const skipAMonthClicked = async () =>
  safelyCallAnalytics('skipAMonthClicked', () => {
    GoogleAnalytics.skipAMonthClicked();
  });

const cancelSurveyOptionSelected = async (choice) =>
  safelyCallAnalytics('cancelSurveyOptionSelected', () => {
    GoogleAnalytics.cancelSurveyOptionSelected(choice);
  });

const bitsyOfferClicked = async () =>
  safelyCallAnalytics('bitsyOfferClicked', () => {
    GoogleAnalytics.bitsyOfferClicked();
  });

const earlyFeeClicked = async () =>
  safelyCallAnalytics('earlyFeeClicked', () => {
    GoogleAnalytics.earlyFeeClicked();
  });

const extendedSubscription = async () =>
  safelyCallAnalytics('extendedSubscription', () => {
    GoogleAnalytics.extendedSubscription();
  });

const editPaymentUsed = async () =>
  safelyCallAnalytics('editPaymentUsed', () => {
    GoogleAnalytics.editPaymentUsed();
  });

const giveawayEntry = async () =>
  safelyCallAnalytics('giveawayEntry', () => {
    GoogleAnalytics.giveawayEntry();
    FBTracking.addLead();
  });

const referralButtonClicked = async (buttonClicked) =>
  safelyCallAnalytics('referralButtonClicked', () => {
    GoogleAnalytics.clickedReferralButton(buttonClicked);
  });

const estimatedSavingsIconClicked = async () =>
  safelyCallAnalytics('estimatedSavingsIconClicked', () => {
    GoogleAnalytics.clickedEstimatedSavingsIcon();
  });

const estimatedSavingsLinkClicked = async (linkClicked) =>
  safelyCallAnalytics('estimatedSavingsLinkClicked', () => {
    GoogleAnalytics.clickedEstimatedSavingsLink(linkClicked);
  });

const cartIsEmpty = async () =>
  safelyCallAnalytics('cartIsEmpty', () => {
    GoogleAnalytics.cartIsEmpty();
  });

const sendSponsorInfo = async (sponsorName) =>
  safelyCallAnalytics('sendSponsorInfo', () => {
    GoogleAnalytics.sendSponsorInfo(sponsorName);
  });

const optedIntoSMS = async (sponsorName) =>
  safelyCallAnalytics('optedIntoSMS', () => {
    GoogleAnalytics.optedIntoSMS(sponsorName);
  });

export default {
  setTrackersInitialized, // exported for testing purposes
  setTrackersState, // exported for testing purposes
  pageViewEvent,
  listSignupEvent,
  newUserEvent,
  returningUserEvent,
  purchaseEvent,
  viewedProductEvent,
  viewedSubscriptionEvent,
  updatedCartEvent,
  setDueDateEvent,
  itemsLoaded,
  checkoutStarted,
  checkoutProcess,
  breastPumpProcess,
  runTheInits,
  viewPromo,
  updatedCreditCard,
  subscriptionAddOnsChanged,
  myAccountDropDown,
  viewPartner,
  createRegistry,
  breastPumpPartnerApiInfoSent,
  registryProcess,
  addActivationEvent,
  clickedTrackingNumber,
  corpGiftingSubmit,
  dueDateCalcSubmitted,
  scheduledCancellationOrCompletion,
  cancelButtonClicked,
  skipAMonthClicked,
  cancelSurveyOptionSelected,
  bitsyOfferClicked,
  earlyFeeClicked,
  extendedSubscription,
  editPaymentUsed,
  giveawayEntry,
  referralButtonClicked,
  estimatedSavingsIconClicked,
  estimatedSavingsLinkClicked,
  cartIsEmpty,
  usePreviousCard,
  digitalPregnancyAnnouncementDownloaded,
  coregIncentiveAchieved,
  babyNamesListViewed,
  sendSponsorInfo,
  optedIntoSMS,
  paymentInfoSubmitted,
  addAdvancedMatchingDataToMetaPixel,
};
