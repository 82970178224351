import Environment from './Environment';
import Logger from './Logger';
import GtagService from './GtagService';

const purchaseLabel = 'purchase';
const bpFormLabel = 'bpForm';
const digitalPregnancyAnnouncementLabel = 'digitalPregnancyAnnouncement';
const coregLeadsLabel = 'coregLeads';
const babyNameMatcherLeadsLabel = 'babyNamesMatcherLeads';

const adWordsIdAndLabel = (labelName) => {
  const id = Environment.getGoogleAdWordsId;
  let label;
  if (labelName === purchaseLabel) {
    if (Environment.store === 'bitsy') {
      label = 'fX3xCJXM8IIBENHrpf8C';
    } else {
      label = 'el02CKW3pnMQ8IqbzgM';
    }
  } else if (labelName === bpFormLabel) {
    label = 'iNGsCP30rLEDEPCKm84D';
  } else if (labelName === digitalPregnancyAnnouncementLabel) {
    label = 'MfOpCJ_pz4AYEPCKm84D';
  } else if (labelName === coregLeadsLabel) {
    label = '7OQLCNq_6oEYEPCKm84D';
  } else if (labelName === babyNameMatcherLeadsLabel) {
    label = '80wyCPigo4IYEPCKm84D';
  }

  return `${id}/${label}`;
};

const transactionComplete = (trackingData, cartInfo) => {
  if (Environment.getEnv === 'PRODUCTION') {
    try {
      let couponUsed = 'No coupon used';
      if (cartInfo.coupon && cartInfo.coupon.code) {
        couponUsed = cartInfo.coupon.code;
      }

      const lineItems = [];
      if (cartInfo.subscription && cartInfo.subscription.plan) {
        const subscriptionToAddData = {
          name: `${cartInfo.subscription.plan.title} Subscription`,
          price:
            cartInfo.subscription.plan.costWithCoupon ||
            cartInfo.subscription.plan.actualCost,
          quantity: 1,
          id: cartInfo.subscription.plan._id || '1234',
        };
        lineItems.push(subscriptionToAddData);
      }
      if (cartInfo.productsAndDetails && cartInfo.productsAndDetails.length) {
        cartInfo.productsAndDetails.forEach((productAndDetails) => {
          let productName =
            productAndDetails.product?.name || 'Missing product name';
          if (
            productAndDetails.color !== 'NONE' &&
            productAndDetails.variation !== 'NONE'
          ) {
            productName = `${productName} - ${productAndDetails.color}, ${productAndDetails.variation}`;
          } else if (productAndDetails.color !== 'NONE') {
            productName = `${productName} - productAndDetails.color`;
          } else if (productAndDetails.variation !== 'NONE') {
            productName = `${productName} - productAndDetails.variation`;
          }
          const productToAddData = {
            name: productName,
            price:
              productAndDetails.promoItem &&
              productAndDetails.product.ownPromoData
                ? productAndDetails.product.ownPromoData.cost
                : productAndDetails.product.actualCost || 0,
            quantity: productAndDetails.quantity || 1,
            id: productAndDetails.product.sku || 'Missing product SKU',
          };
          lineItems.push(productToAddData);
        });
      }

      GtagService.sendToDatalayer('event', 'purchase', {
        // eslint-disable-next-line camelcase
        send_to: adWordsIdAndLabel(purchaseLabel),
        transaction_id: trackingData.res.justPurchasedPurchaseId, // eslint-disable-line camelcase
        currency: 'USD',
        value: parseFloat(trackingData.total, 10),
        tax: parseFloat(trackingData.taxesAndFees, 10),
        shipping: parseFloat(trackingData.shipping, 10),
        items: lineItems,
        coupon: couponUsed,
      });
      GtagService.sendToDatalayer('event', 'conversion', {
        send_to: adWordsIdAndLabel(purchaseLabel), // eslint-disable-line camelcase
        value: parseFloat(trackingData.total, 10),
        currency: 'USD',
        transaction_id: trackingData.res.justPurchasedPurchaseId, // eslint-disable-line camelcase
      });
    } catch (e) {
      Logger.error(e);
    }
  }
};

const breastPumpProcessAdWords = () => {
  GtagService.sendToDatalayer('event', 'conversion', {
    send_to: adWordsIdAndLabel(bpFormLabel), // eslint-disable-line camelcase
    value: parseFloat(0, 10),
    currency: 'USD',
  });
};

const digitalPregnancyAnnouncementAdWords = () => {
  GtagService.sendToDatalayer('event', 'conversion', {
    send_to: adWordsIdAndLabel(digitalPregnancyAnnouncementLabel), // eslint-disable-line camelcase
    value: parseFloat(0, 10),
    currency: 'USD',
  });
};

const addCoregLead = () => {
  GtagService.sendToDatalayer('event', 'conversion', {
    send_to: adWordsIdAndLabel(coregLeadsLabel), // eslint-disable-line camelcase
    value: parseFloat(0, 10),
    currency: 'USD',
  });
};

const addBabyNameMatcherLead = () => {
  GtagService.sendToDatalayer('event', 'conversion', {
    send_to: adWordsIdAndLabel(babyNameMatcherLeadsLabel), // eslint-disable-line camelcase
    value: parseFloat(0, 10),
    currency: 'USD',
  });
};

const configurePagePath = (page) => {
  GtagService.sendToDatalayer('config', Environment.getGoogleAdWordsId, {
    page_path: page, // eslint-disable-line camelcase
  });
};

export default {
  transactionComplete,
  breastPumpProcessAdWords,
  adWordsIdAndLabel,
  digitalPregnancyAnnouncementAdWords,
  addCoregLead,
  addBabyNameMatcherLead,
  configurePagePath,
};
