const formatAsMoney = (input) => {
  if (!input) return '0.00';
  if (input === 0) return '0.00';
  return parseFloat(input).toFixed(2);
};

const calculateTaxesAndFees = (subCosts) => {
  if (!subCosts.taxesAndFees) return formatAsMoney(0);
  return formatAsMoney(subCosts.taxesAndFees);
};

const calculateGiftCardDiscount = (subCosts) => {
  if (!subCosts.giftCardDiscount) return formatAsMoney(0);
  return formatAsMoney(subCosts.giftCardDiscount);
};

const calculateTotal = (subCosts) => {
  const taxesAndFees = parseFloat(calculateTaxesAndFees(subCosts));
  const subTotal = parseFloat(subCosts.subTotal);
  const shipping = parseFloat(subCosts.shipping);
  const shippingInsurance = parseFloat(subCosts.shippingInsuranceCost);
  const discount =
    parseFloat(calculateGiftCardDiscount(subCosts)) +
    parseFloat(subCosts.couponDiscount) +
    parseFloat(subCosts.pointsDiscount);
  return formatAsMoney(
    taxesAndFees + subTotal + shipping + shippingInsurance - discount,
  );
};

const determineShippingInsuranceCost = (item) => {
  let { cost } = item.shippingInsurance;
  if (
    item.subscription &&
    item.subscription.plan &&
    item.subscription.plan.months !== 'INFINITY'
  ) {
    cost = parseFloat(
      item.subscription.plan.months * item.shippingInsurance.cost,
    );
  }
  return cost;
};

module.exports = {
  formatAsMoney,
  calculateTaxesAndFees,
  calculateGiftCardDiscount,
  calculateTotal,
  determineShippingInsuranceCost,
};
