/* eslint-disable camelcase */
import GtagService from './GtagService';
import Content from '../constants/Content';
import Logger from './Logger';
import Environment from './Environment';

function init() {
  GtagService.loadGtag();
}

const getSubscriptionItem = (plan, listName) => {
  let price = plan.costWithCoupon || plan.actualCost;
  price = Number(price);
  const item = {
    item_id: plan.months === 'INFINITY' ? 'non-bundled' : 'bundled',
    item_name: `${plan.title} Subscription`,
    price,
    quantity: 1,
    item_category: 'Subscription',
    affiliation: Content.names.fancyNamePlural,
  };

  if (listName) {
    item.item_list_name = listName;
  }

  return item;
};

const getProductPrice = (productsAndDetailsItem, product) => {
  if (productsAndDetailsItem.promoItem && product.ownPromoData) {
    return productsAndDetailsItem.product.ownPromoData.cost;
  }
  return productsAndDetailsItem.amountToCharge || product.actualCost || 0;
};

const getProductItem = (productsAndDetailsItem, isVIPCart, listName) => {
  let product;
  if (productsAndDetailsItem.product) {
    product = productsAndDetailsItem.product;
  } else {
    product = productsAndDetailsItem;
  }

  let itemVariant;
  if (productsAndDetailsItem.color !== 'NONE') {
    itemVariant = productsAndDetailsItem.color;
  }
  if (productsAndDetailsItem.varation !== 'NONE') {
    itemVariant += `-${productsAndDetailsItem.varation}`;
  }

  const item = {
    item_id: product.sku || product._id,
    item_name: product.name || 'unknown',
    price: Number(getProductPrice(productsAndDetailsItem, product)),
    quantity: Number(productsAndDetailsItem.quantity) || 1,
    coupon: isVIPCart ? 'VIP' : 'NON-VIP',
    item_category: 'Shop',
    item_brand: product.brand || 'unknown',
    item_variant: itemVariant,
    affiliation: Content.names.fancyNamePlural,
  };

  if (listName) {
    item.item_list_name = listName;
  }

  return item;
};

const getServiceItem = (service, type, isVIPCart) => {
  return {
    item_id: type,
    item_name: type,
    item_category: 'Shop',
    item_variant: 'Unknown',
    price: Number(service.cost),
    quantity: 1,
    coupon: isVIPCart ? 'VIP' : 'NON-VIP',
  };
};

const viewPromo = (promoData) => {
  const items = [];
  promoData.products?.forEach((product) => {
    items.push({
      item_id: product._id || 'unknown',
      item_name: product.name || 'unknown',
      item_brand: product.brand || 'unknown',
      price: Number(product.ownPromoData?.cost) || 0,
      quantity: 1,
    });
  });
  const promoDataToAdd = {
    items,
    promotion_id: promoData.id,
    promotion_name: promoData.name || 'Unknown',
    creative_slot: promoData.position,
  };
  GtagService.sendToDatalayer('event', 'view_promotion', promoDataToAdd);
  GtagService.sendToDatalayer('event', 'view', {
    event_category: 'Internal Promotions',
    event_label: promoData.position,
  });
};

const getAllItemsForCart = (cartInfo, listName) => {
  const isVIPCart =
    cartInfo.subscriberDiscountAmount && cartInfo.subscriberDiscountAmount > 0;
  const items = [];

  if (cartInfo.subscription) {
    items.push(getSubscriptionItem(cartInfo.subscription.plan, listName));
  }
  cartInfo.productsAndDetails.forEach((productsAndDetailsItem) => {
    items.push(getProductItem(productsAndDetailsItem, isVIPCart, listName));
  });

  if (cartInfo.shippingInsurance) {
    const type = 'Shipping Insurance';
    let totalShippingInsuranceCost = cartInfo.shippingInsurance.cost;
    if (
      cartInfo.subscription?.plan &&
      cartInfo.subscription.plan.months !== 'INFINITY'
    ) {
      totalShippingInsuranceCost =
        cartInfo.shippingInsurance.cost * cartInfo.subscription.plan.months;
    }
    const shippingInsurance = {
      cost: totalShippingInsuranceCost,
    };
    items.push(getServiceItem(shippingInsurance, type, isVIPCart));
  }

  if (cartInfo.isBrownBoxSelected) {
    const type = 'Discreet Shipping';
    const service = {
      cost: 4.99,
    };
    items.push(getServiceItem(service, type, isVIPCart));
  }

  if (cartInfo.firstBoxShipping && cartInfo.firstBoxShipping.cost > 0) {
    const type = cartInfo.firstBoxShipping.title;
    items.push(getServiceItem(cartInfo.firstBoxShipping, type, isVIPCart));
  }

  return items;
};

const transactionComplete = (gtmData, cartInfo) => {
  try {
    let purchaseId = gtmData.res.justPurchasedPurchaseId;
    if (gtmData.isPostPurchasePurchase) {
      purchaseId = `${purchaseId}-postPurchaseUpsell`;
    }
    const purchaseData = {
      items: getAllItemsForCart(cartInfo),
      transaction_id: purchaseId,
      value: Number(gtmData.total), // Total transaction value (incl. tax and shipping)
      tax: Number(gtmData.taxesAndFees),
      shipping: Number(gtmData.shipping),
      coupon: cartInfo?.coupon?.code ? cartInfo.coupon.code : '',
      currency: 'USD',
    };
    GtagService.sendToDatalayer('event', 'purchase', purchaseData);
    GtagService.sendToDatalayer('event', 'Checkout Complete', {
      event_category: 'Checkout',
      event_label: purchaseId,
    });

    // Fire GTM event w/ purchase data
    const skulist = [];
    const pricelist = [];
    const quantitylist = [];
    if (cartInfo.subscription) {
      skulist.push(`${cartInfo.subscription.plan.title} Subscription`);
      pricelist.push(
        cartInfo.subscription.plan.costWithCoupon ||
          cartInfo.subscription.plan.actualCost,
      );
      quantitylist.push(1);
    }
    if (cartInfo.productsAndDetails?.length) {
      cartInfo.productsAndDetails.forEach((productsAndDetailsItem) => {
        skulist.push(productsAndDetailsItem.product.sku || 'unknown');
        pricelist.push(
          productsAndDetailsItem.amountToCharge ||
            productsAndDetailsItem.product.actualCost ||
            0,
        );
        quantitylist.push(productsAndDetailsItem.product.quantity || 1);
      });
    }
    GtagService.sendDatalayerVariables({
      event: 'checkout_complete',
      purchase_id: purchaseId, // eslint-disable-line camelcase
      // adding a check incase the subCosts value is falsy. This is currently only in
      // pump upsell scenarios
      purchase_amount: cartInfo.subCosts // eslint-disable-line camelcase
        ? cartInfo.subCosts.overallTotal
        : gtmData.total,
      skulist: skulist.join(),
      pricelist: pricelist.join(),
      quantitylist: quantitylist.join(),
    });
  } catch (e) {
    Logger.error(e);
  }
};

const usePreviousCard = () => {
  try {
    GtagService.sendToDatalayer('event', 'usePreviousCard', {
      event_category: 'feature-paymentMethods',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const beginCheckout = (cartInfo) => {
  try {
    const cartData = {
      items: getAllItemsForCart(cartInfo),
      value: Number(cartInfo.subCosts?.overallTotal) || 0,
      currency: 'USD',
      coupon: cartInfo?.coupon?.code ? cartInfo.coupon.code : '',
    };
    GtagService.sendToDatalayer('event', 'begin_checkout', cartData);
  } catch (e) {
    Logger.error(e);
  }
};

const checkoutShippingInfoSubmitted = (cartInfo) => {
  try {
    const cartData = {
      items: getAllItemsForCart(cartInfo),
      value: Number(cartInfo.subCosts?.overallTotal) || 0,
      currency: 'USD',
      shipping_tier: cartInfo.firstBoxShipping?.title || 'unknown',
      coupon: cartInfo?.coupon?.code ? cartInfo.coupon.code : '',
    };
    GtagService.sendToDatalayer('event', 'add_shipping_info', cartData);
  } catch (e) {
    Logger.error(e);
  }
};

const checkoutCustomizationInfoSubmitted = (cartInfo) => {
  try {
    const cartData = {
      items: getAllItemsForCart(cartInfo),
      value: Number(cartInfo.subCosts?.overallTotal) || 0,
      currency: 'USD',
      coupon: cartInfo?.coupon?.code ? cartInfo.coupon.code : '',
    };
    GtagService.sendToDatalayer('event', 'add_customization_info', cartData);
  } catch (e) {
    Logger.error(e);
  }
};

const checkoutPaymentInfoSubmitted = (cartInfo) => {
  try {
    const cartData = {
      items: getAllItemsForCart(cartInfo),
      value: Number(cartInfo.subCosts?.overallTotal) || 0,
      currency: 'USD',
      coupon: cartInfo?.coupon?.code ? cartInfo.coupon.code : '',
      payment_type: '',
    };
    GtagService.sendToDatalayer('event', 'add_payment_info', cartData);
  } catch (e) {
    Logger.error(e);
  }
};

const viewProduct = (data, isProduct, isPromo, position) => {
  try {
    if (!isProduct) {
      const subItem = getSubscriptionItem(data);
      const gaData = {
        items: [subItem],
        currency: 'USD',
        value: Number(subItem.price),
      };
      GtagService.sendToDatalayer('event', 'view_item', gaData);

      GtagService.sendToDatalayer('event', 'view', {
        event_category: 'Subscription',
      });
    }

    if (isProduct) {
      if (isPromo) {
        viewPromo({
          products: [data],
          position,
        });
      }

      const productItem = getProductItem(data);
      const gaData = {
        items: [productItem],
        currency: 'USD',
        value: Number(productItem.price),
      };
      GtagService.sendToDatalayer('event', 'view_item', gaData);

      GtagService.sendToDatalayer('event', 'view', {
        event_category: 'Shop',
      });
    }
  } catch (e) {
    Logger.error(e);
  }
};

const addToCart = (cartInfo, listName) => {
  try {
    const items = getAllItemsForCart(cartInfo, listName);
    const value = items.reduce((total, item) => total + item.price, 0);
    const gaData = {
      items,
      value: Number(value),
      currency: 'USD',
    };
    GtagService.sendToDatalayer('event', 'add_to_cart', gaData);

    GtagService.sendToDatalayer('event', 'add to cart', {
      event_category: 'Cart',
      event_label: listName,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const addToCartItemTracking = (itemTrackingObj) => {
  const { event_category, action, item } = itemTrackingObj;
  GtagService.sendToDatalayer('event', action, {
    event_category,
    event_label: item,
  });
};

const breastPumpProcess = (stepNum) => {
  try {
    GtagService.sendToDatalayer('event', 'Breast Pumps', {
      event_category: 'Breast Pumps',
      event_label: `Step ${stepNum} Submitted`,
    });
    if (stepNum === 1) {
      GtagService.sendToDatalayer('event', 'begin_checkout', {
        event_category: 'BP Checkout',
        checkout_step: 1,
        event_label: 'BP Customer Info',
      });
    }
    if (stepNum === 2) {
      GtagService.sendToDatalayer('event', 'add_shipping_info', {
        event_category: 'BP Checkout',
        checkout_step: 2,
        event_label: 'BP Customer Address',
        shipping_tier: 'Standard',
      });
    }
    if (stepNum === '3A') {
      GtagService.sendToDatalayer('event', 'BP_insurance_submitted', {
        event_category: 'BP Checkout',
        checkout_step: 3,
        event_label: 'BP Customer Insurance',
      });
    }
  } catch (e) {
    Logger.error(e);
  }
};

const clickInsurancePartnerLink = (partnerName) => {
  try {
    GtagService.sendToDatalayer('event', 'Partner Clicked', {
      event_category: 'Breast Pumps',
      event_label: partnerName,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickUpdateCreditCard = (hasError) => {
  try {
    GtagService.sendToDatalayer('event', 'updateCreditCard', {
      event_category: 'MyAccount',
      event_label: `updateCreditCard`,
      hasError: !!hasError,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickMyAccountDropdown = (dropdownItem, hasError) => {
  try {
    GtagService.sendToDatalayer('event', 'click interaction', {
      event_category: 'MyAccount',
      event_label: `${dropdownItem}`,
      hasError: !!hasError,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const setUserId = (userData) => {
  try {
    if (userData?._id) {
      GtagService.sendToDatalayer(
        'config',
        Environment.getGoogleAnalyticsTrackingId,
        {
          user_id: userData._id,
        },
      );
    }
  } catch (e) {
    Logger.error(e);
  }
};

const accountDetailsUpdated = (page) => {
  try {
    GtagService.sendToDatalayer('event', page, {
      event_category: 'MyAccount',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const breastPumpPartnerApiInfoSent = (partnerName) => {
  try {
    GtagService.sendToDatalayer('event', 'BP Api Lead Sent', {
      event_category: 'Breast Pumps',
      event_label: partnerName,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const registryProcess = (stepNum) => {
  try {
    GtagService.sendToDatalayer('event', 'Registry Steps', {
      event_category: 'Registry Steps',
      event_label: `Step ${stepNum} Submitted`,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const sendSponsorInfo = (sponsor) => {
  try {
    GtagService.sendToDatalayer('event', 'Offer Selected', {
      event_category: 'Data Partner',
      event_label: sponsor,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const addShippingInsurance = () => {
  try {
    GtagService.sendToDatalayer('event', 'Purchased', {
      event_category: 'Shipping Insurance',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const optedIntoSMS = () => {
  try {
    GtagService.sendToDatalayer('event', 'Opt-in Selected', {
      event_category: 'Opted-In SMS',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickedTrackingNumber = () => {
  try {
    GtagService.sendToDatalayer('event', 'click interaction', {
      event_category: 'Tracking Number',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const corpGiftingSubmit = () => {
  try {
    GtagService.sendToDatalayer('event', 'LeadSubmitted', {
      event_category: 'CorporateGifting',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const dueDateCalcSubmitted = () => {
  try {
    GtagService.sendToDatalayer('event', 'pregnancy_calculator', {
      event_category: 'Due Date Calc',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const digitalPregnancyAnnouncementDownloaded = (name) => {
  try {
    GtagService.sendToDatalayer('event', 'announcement_download', {
      event_category: name,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const digitalServicesDownloaded = (name) => {
  try {
    GtagService.sendToDatalayer('event', 'Digital Service Download', {
      event_category: name,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const coregIncentiveAchieved = () => {
  try {
    GtagService.sendToDatalayer('event', 'Coreg Incentive Achieved', {
      event_category: 'CoReg',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const dueDateCalcOptedIntoEmail = () => {
  try {
    GtagService.sendToDatalayer('event', 'Opted in to email', {
      event_category: 'Due Date Calc',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const scheduledCancel = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Scheduled Cancel/Complete',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const cancelButtonClicked = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Cancel Flow - Cancel button clicked',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const skipAMonthClicked = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Cancel Flow - Skip A Month clicked',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const cancelSurveyOptionSelected = (choice) => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Cancel Flow - Survey Option selected',
      event_label: choice,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const bitsyOfferClicked = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Cancel Flow - Bitsy Offer clicked',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const earlyFeeClicked = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Cancel Flow - Early Fee clicked after alert',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const extendedSubscription = () => {
  try {
    GtagService.sendToDatalayer('event', 'MyAccount', {
      event_category: 'Extended Subscription',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const editPaymentUsed = () => {
  try {
    GtagService.sendToDatalayer('event', 'Updated Credit Card', {
      event_category: 'Edit Payment',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const cartIsEmpty = () => {
  try {
    GtagService.sendToDatalayer('event', 'Cart is Empty', {
      event_category: 'Cart',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const giveawayEntry = () => {
  try {
    GtagService.sendToDatalayer('event', 'New Giveaway Entry', {
      event_category: 'Giveaway',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickedReferralButton = (buttonClicked) => {
  try {
    GtagService.sendToDatalayer('event', 'click interaction', {
      event_category: 'Refer-A-Friend',
      event_label: buttonClicked,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickedEstimatedSavingsIcon = () => {
  try {
    GtagService.sendToDatalayer('event', 'click interaction', {
      event_category: 'Estimated Savings - Icon',
    });
  } catch (e) {
    Logger.error(e);
  }
};

const clickedEstimatedSavingsLink = (linkClicked) => {
  try {
    GtagService.sendToDatalayer('event', 'click interaction', {
      event_category: 'Estimated Savings - Link',
      event_label: linkClicked,
    });
  } catch (e) {
    Logger.error(e);
  }
};

const setUserData = (email) => {
  GtagService.sendToDatalayer('set', 'user_data', { email });
};

const promoProductsLoaded = (eventName) => {
  GtagService.sendToDatalayer('event', eventName);
};

const subscriptionPlansVisible = (eventName) => {
  GtagService.sendToDatalayer('event', eventName);
};

const subscriptionAddOnsChanged = (action, event_label) => {
  GtagService.sendToDatalayer('event', action, {
    event_category: 'My Account',
    event_label,
  });
};

export default {
  init,
  transactionComplete,
  viewProduct,
  addToCart,
  addToCartItemTracking,
  viewPromo,
  breastPumpProcess,
  clickInsurancePartnerLink,
  clickUpdateCreditCard,
  clickMyAccountDropdown,
  accountDetailsUpdated,
  breastPumpPartnerApiInfoSent,
  registryProcess,
  sendSponsorInfo,
  addShippingInsurance,
  optedIntoSMS,
  clickedTrackingNumber,
  corpGiftingSubmit,
  dueDateCalcSubmitted,
  dueDateCalcOptedIntoEmail,
  scheduledCancel,
  cancelButtonClicked,
  skipAMonthClicked,
  cancelSurveyOptionSelected,
  bitsyOfferClicked,
  earlyFeeClicked,
  extendedSubscription,
  editPaymentUsed,
  giveawayEntry,
  clickedReferralButton,
  clickedEstimatedSavingsIcon,
  clickedEstimatedSavingsLink,
  usePreviousCard,
  cartIsEmpty,
  digitalServicesDownloaded,
  coregIncentiveAchieved,
  beginCheckout,
  checkoutShippingInfoSubmitted,
  checkoutCustomizationInfoSubmitted,
  checkoutPaymentInfoSubmitted,
  setUserData,
  promoProductsLoaded,
  subscriptionPlansVisible,
  subscriptionAddOnsChanged,
  setUserId,
  digitalPregnancyAnnouncementDownloaded,
};
