import React from 'react';
import { Row, Col } from 'react-bootstrap';

const PrivacyText = ({ doNotSellDataRef }) => (
  <Row>
    <Col className='privacy-text'>
      <p>Last updated: January 23, 2024</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information under
        this Privacy Policy.
      </p>
      <h2>Interpretation and Definitions</h2>
      <br />
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under these conditions. These definitions shall have the same
        meaning whether or not they appear in singular or in plural.
      </p>
      <h3>Definitions</h3>
      <h4>For this Privacy Policy:</h4>
      <p>
        Account means a unique account created for You to access our Service or
        parts of our Service.
      </p>
      <p>
        Business, for the CCPA (California Consumer Privacy Act), calls the
        Company the legal entity that collects Consumers` personal information
        and determines the purposes and means of the processing of Consumers`
        personal information, or on behalf of which such information is
        collected and that alone, or jointly with others, determines the
        purposes and means of the processing of consumers` personal information,
        that does business in the State of California.
      </p>
      <p>
        Company “the Company“, “We“, “Us“ or “Our“ in this Agreement) refers to
        Bump Boxes, Inc., 7719 North Pioneer Lane, Peoria, Illinois 61615.
      </p>
      <p>For the GDPR, the Company is the Data Controller.</p>
      <p>
        Consumer, for the CCPA (California Consumer Privacy Act), means a
        natural person who is a California resident. A resident, as defined in
        the law, includes (1) every individual in the USA for other than a
        temporary or transitory purpose, and (2) every individual who is
        domiciled in the USA who is outside the USA for a temporary or
        transitory purpose.
      </p>
      <p>
        Cookies are small files placed on Your computer, mobile device or any
        other device by a website, containing the details of Your browsing
        history on that website among its many uses.
      </p>
      <p>
        Data Controller, for the GDPR (General Data Protection Regulation),
        calls the Company the legal person which alone or jointly with others
        determines the purposes and means of the processing of Personal Data.
      </p>
      <p>
        Device means any device that can access the Service such as a computer,
        a cellphone or a digital tablet.
      </p>
      <p>
        Do Not Track (DNT) is a concept promoted by US regulatory authorities,
        in particular the U.S. Federal Trade Commission (FTC), for the Internet
        industry to develop and implement a mechanism for allowing internet
        users to control the tracking of their online activities across
        websites.
      </p>
      <p>
        Facebook Fan Page is a public profile named Bump Boxes` Facebook Page
        specifically created by the Company on the Facebook social network,
        accessible from https://www.facebook.com/BumpBoxes/
      </p>
      <p>
        Personal Data is any information that relates to an identified or
        identifiable individual.
      </p>
      <p>
        For the purposes for GDPR, Personal Data means any information relating
        to You such as a name, an identification number, location data, online
        identifier or to one or more factors specific to the physical,
        physiological, genetic, mental, economic, cultural or social identity.
      </p>
      <p>
        For the CCPA, Personal Data means any information that identifies,
        relates to, describes or can be associated with, or could reasonably be
        linked, directly or indirectly, with You.
      </p>
      <p>
        Sale, for the CCPA (California Consumer Privacy Act), means selling,
        renting, releasing, disclosing, disseminating, making available,
        transferring, or otherwise communicating orally, in writing, or by
        electronic or other means, a Consumer`s personal information to another
        business or a third party for monetary or other valuable consideration.
      </p>
      <p>Service refers to the Website.</p>
      <p>
        Service Provider means any natural or legal person who processes the
        data on behalf of the Company. It refers to third-party companies or
        individuals employed by the Company to facilitate the Service, to
        provide the Service on behalf of the Company, to perform services
        related to the Service or to assist the Company in analyzing how the
        Service is used. For the GDPR, Service Providers are considered Data
        Processors.
      </p>
      <p>
        Third-party Social Media Service refers to any website or any social
        network website through which a User can log in or create an account to
        use the Service.
      </p>
      <p>
        Usage Data refers to data collected automatically, either generated by
        the Service or from the Service infrastructure itself (for example, the
        duration of a page visit).
      </p>
      <p>Website refers to Bump Boxes, accessible from www.bumpboxes.com</p>
      <p>
        You means the individual accessing or using the Service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the Service.
      </p>
      <p>
        Under GDPR (General Data Protection Regulation), You can be called the
        Data Subject or as the User as you are the individual using the Service.
      </p>
      <h2>Collecting and Using Your Personal Data</h2>
      <br />
      <h3>Types of Data Collected</h3>
      <br />
      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can contact or identify You.
        Personally identifiable information may include, but is not limited to:
      </p>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Address, State, Province, ZIP/Postal code, City</li>
      <li>Usage Data</li>
      <br />
      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device`s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service You visit, the time and date of Your visit, the
        time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <br />
      <h3>Information from Third-Party Social Media Services</h3>
      <p>
        The Company may allow You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </p>
      <li>Google</li>
      <li>Facebook</li>
      <li>Twitter</li>
      <br />
      <p>
        If You register through or otherwise grant us access to a Third-Party
        Social Media Service, We may collect Personal data already associated
        with Your Third-Party Social Media Service`s account, such as Your name,
        Your email address, Your activities or Your contact list associated with
        that account.
      </p>
      <p>
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service`s account. If You
        provide such information and Personal Data, during registration or
        otherwise, You are giving the Company permission to use, share, and
        store it in a manner consistent with this Privacy Policy.
      </p>
      <h2>Tracking Technologies and Cookies</h2>
      <br />
      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </p>
      <li>
        Cookies or Browser Cookies. A cookie is a small file placed on Your
        Device. You can instruct Your browser to refuse all Cookies or to
        indicate when a Cookie is being sent. However, if You do not accept
        Cookies, You may not use parts of our Service. Unless you have adjusted
        Your browser setting so it will refuse Cookies, our Service may use
        Cookies.
      </li>
      <br />
      <li>
        Flash Cookies. Certain features of our Service may use local stored
        objects (or Flash Cookies) to collect and store information about Your
        preferences or Your activity on our Service. Flash Cookies are not
        managed by the same browser settings as those used for Browser Cookies.
        For more information on how You can delete Flash Cookies, please read
        “Where can I change the settings for disabling, or deleting local shared
        objects?” available at
        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
      </li>
      <br />
      <li>
        Web Beacons. Certain sections of our Service and our emails may contain
        small electronic files known as web beacons (also called clear gifs,
        pixel tags, and single-pixel gifs) that permit the Company, for example,
        to count users who have visited those pages or opened an email and for
        other related website statistics (for example, recording the popularity
        of a certain section and verifying system and server integrity).
      </li>
      <br />
      <p>
        Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.{' '}
      </p>
      <h3>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </h3>
      <br />
      <ul>Necessary / Essential Cookies</ul>
      <ul>Type: Session Cookies</ul>
      <ul>Administered by: Us</ul>
      <p>
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services You have asked for
        cannot be provided, and We only use these Cookies to provide You with
        those services.
      </p>
      <h3>Cookies Policy / Notice Acceptance Cookies</h3>
      <h4>Cart Reminder Cookies & Communications</h4>
      <p>
        Bump Boxes uses cookies to collect information around abandoned shopping
        carts. A cart is considered abandoned within one hour of inactivity/lack
        of purchase. Once the cart is considered abandoned, an SMS message will
        be sent as a reminder.
      </p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </p>
      <p>Functionality Cookies</p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details, language
        preference, or tracking cart activity. The purpose of these Cookies is
        to provide You with a more personal experience and to avoid You having
        to re-enter your preferences or add items to your cart every time You
        use the Website. The Website uses cookies to help keep track of items
        you put into your shopping cart including when you have abandoned your
        cart and this information is used to determine when to send cart
        reminder messages via SMS.
      </p>
      <h4>Tracking and Performance Cookies</h4>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Third-Parties</p>
      <p>
        Purpose: These Cookies are used to track information about traffic to
        the Website and how users use the Website. The information gathered via
        these Cookies may directly or indirectly identify you as an individual
        visitor. This is because the information collected is typically linked
        to a pseudonymous identifier associated with the device you use to
        access the Website. We may also use these Cookies to test new pages,
        features or new functionality of the Website to see how our users react
        to them.
      </p>
      <p>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </p>
      <h2>Use of Your Personal Data</h2>
      <br />
      <p>The Company may use Personal Data for these purposes:</p>
      <li>
        To provide and maintain our Service, including to monitor the usage of
        our Service.
      </li>
      <br />
      <li>
        To manage Your Account: to manage Your registration as a user of the
        Service. The Personal Data You provide can give You access to different
        functionalities of the Service available to You as a registered user.
      </li>
      <br />
      <li>
        For the performance of a contract: the development, compliance and
        undertaking of the purchase contract for the products, items or services
        You have purchased or of any other contract with Us through the Service.
      </li>
      <br />
      <li>
        To contact You: To contact You by email, telephone calls, SMS, or other
        equivalent forms of electronic communication, such as a mobile
        application`s push notifications regarding updates or informative
        communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable
        for their implementation.
      </li>
      <br />
      <li>
        To provide You with news, special offers and general information about
        other goods, services and events which we offer similar to those that
        you have already purchased or enquired about unless You have opted not
        to receive such information.
      </li>
      <br />
      <li>
        To manage Your requests: To attend and manage Your requests to Us.
      </li>
      <br />
      <li>
        To deliver targeted advertising to You: We may use Your information to
        develop and display content and advertising (and work with third-party
        vendors who do so) tailored to Your interests and/or location and to
        measure its effectiveness.
      </li>
      <br />
      <li>
        For business transfers: We may use Your information to evaluate or
        conduct a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service
        users is among the assets transferred.
      </li>
      <br />
      <li>
        For other purposes: We may use Your information for other purposes, such
        as data analysis, identifying usage trends, determining the
        effectiveness of our promotional campaigns and to evaluate and improve
        our Service, products, services, marketing and your experience.
      </li>
      <br />
      <h3>We may share Your personal information in these situations:</h3>
      <br />
      <li>
        With Service Providers: We may share Your personal information with
        Service Providers to monitor and analyze the use of our Service, to
        advertise on third party websites to You after You visited our Service,
        for payment processing, to contact You.
      </li>
      <br />
      <li>
        For business transfers: We may share or transfer Your personal
        information in connection with, or during negotiations of, any merger,
        sale of Company assets, financing, or acquisition of all or a portion of
        Our business to another company.
      </li>
      <br />
      <li>
        With business partners: We may share Your information with Our business
        partners to offer You certain products, services or promotions.
      </li>
      <br />
      <li>
        With Affiliates: We may share Your information with Our affiliates, in
        which case we will require those affiliates to honor this Privacy
        Policy. Affiliates include Our parent company and any other
        subsidiaries, joint venture partners or other companies We control or
        that are under common control with Us.
      </li>
      <br />
      <li>
        With other users: when You share personal information or otherwise
        interact in the public areas with other users, such information may be
        viewed by all users and may be publicly distributed outside. If You
        interact with other users or register through a Third-Party Social Media
        Service, Your contacts on the Third-Party Social Media Service may see
        Your name, profile, pictures and description of Your activity.
        Similarly, other users can view descriptions of Your activity,
        communicate with You and view Your profile.
      </li>
      <br />
      <li>
        With Your consent: We may disclose Your personal information for any
        other purpose with Your consent.
      </li>
      <br />
      <h3>Retention of Your Personal Data</h3>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we must retain your data to
        comply with laws), resolve disputes, and enforce our legal agreements
        and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period, unless this data
        is used to strengthen the security or to improve the functionality of
        Our Service, or We are legally obligated to retain this data for longer
        time periods.
      </p>
      <h3>Transfer of Your Personal Data</h3>
      <p>
        Your information, including Personal Data, is processed at the Company`s
        operating offices and where the parties involved in the processing are
        located. It means this information may be transferred to — and
        maintained on — computers located outside of Your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and under this Privacy Policy and no transfer
        of Your Personal Data will take place to an organization or a country
        unless adequate controls in place include the security of Your data and
        other personal information.
      </p>
      <br />
      <h2>Disclosure of Your Personal Data</h2>
      <br />
      <h3>Business Transactions</h3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h3>Law enforcement</h3>
      <p>
        Under certain circumstances, the Company may have to disclose Your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h3>Other legal requirements</h3>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <li>Comply with a legal obligation</li>
      <li>Protect and defend the rights or property of the Company</li>
      <li>Prevent or investigate possible wrongdoing with the Service</li>
      <li>Protect the personal safety of Users of the Service or the public</li>
      <li>Protect against legal liability</li>
      <br />
      <h3>Security of Your Personal Data</h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h3>Detailed Information on the Processing of Your Personal Data</h3>
      <p>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service under their Privacy
        Policies.
      </p>
      <h3>Analytics</h3>
      <p>
        We may use third-party Service providers to monitor and analyze the use
        of our Service. These third-party Service providers are listed below.
        You may search for their respective privacy policies. For your
        convenience, a link to what We believe are the privacy policies is
        accessible by clicking on the name of the provider. We do not warrant
        the accuracy of these links and it is your responsibility to find and
        review these providers policies.
      </p>
      <p id='cookieDeclarationContainer' />
      <h2>Email Marketing</h2>
      <p>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may interest You.
        You may opt-out of receiving any, or all, of these communications from
        Us by following the unsubscribe link or instructions provided in any
        email We send or by contacting Us.
      </p>
      <p>
        We may use Email Marketing Service Providers to manage and send emails
        to You. We currently use Klaviyo for this purpose. Their Privacy Policy
        can be viewed at https://www.klaviyo.com/legal/privacy-policy.
      </p>
      <h2>Payments</h2>
      <p>
        We may provide paid products and/or services within the Service. There,
        we may use third-party services for payment processing (e.g. payment
        processors).
      </p>
      <p>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p>
        We currently use Stripe for this purpose. Their Privacy Policy can be
        viewed at: https://stripe.com/privacy
      </p>
      <h2>Behavioral Remarketing</h2>
      <p>
        The Company uses remarketing services to advertise to You after You
        accessed or visited our Service. We and Our third-party vendors use
        cookies and non-cookie technologies to help Us recognize Your Device and
        understand how You use our Service so We can improve our Service to
        reflect Your interests and serve You advertisements likely to interest
        You.
      </p>
      <p>
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service under their Privacy
        Policies and to enable Us to:
      </p>
      <li>Measure and analyze traffic and browsing activity on Our Service</li>
      <br />
      <li>
        Show advertisements for our products and/or services to You on
        third-party websites or apps
      </li>
      <br />
      <li>Measure and analyze the performance of Our advertising campaigns</li>
      <br />
      <p>
        Some of these third-party vendors may use non-cookie technologies that
        may not be affected by browser settings that block cookies. Your browser
        may not permit You to block such technologies. You may be able to use
        the following third-party tools to decline the collection and use of
        information to serve You interest-based advertising:
      </p>
      <li>
        The NAI`s opt-out platform: http://www.networkadvertising.org/choices/
      </li>
      <br />
      <li>The EDAA`s opt-out platform http://www.youronlinechoices.com/</li>
      <br />
      <li>
        The DAA`s opt-out platform: http://optout.aboutads.info/?c=2&lang=EN
      </li>
      <p>
        You may opt-out of all personalized advertising by enabling privacy
        features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
        Out of Ads Personalization (Android). See Your mobile device Help system
        for more information.
      </p>
      <p>
        We may share information, such as hashed email addresses (if available)
        or other online identifiers collected on Our Service with these
        third-party vendors. This allows Our third-party vendors to recognize
        and deliver You ads across devices and browsers. To read more about the
        technologies used by these third-party vendors and their cross-device
        capabilities please refer to the Privacy Policy of each vendor listed
        below.
      </p>
      <h4>The third-party vendors We use are:</h4>
      <li>Google Ads (AdWords)</li>
      <br />
      <li>
        Google Ads (AdWords) remarketing service is provided by Google Inc.
      </li>
      <br />
      <p>
        You can opt-out of Google Analytics for Display Advertising and
        customise the Google Display Network ads by visiting the Google Ads
        Settings page: http://www.google.com/settings/ads
      </p>
      <p>
        Google also recommends installing the Google Analytics Opt-out Browser
        Add-on - https://tools.google.com/dlpage/gaoptout - for your web
        browser. Google Analytics Opt-out Browser Add-on provides visitors with
        the ability to prevent their data from being collected and used by
        Google Analytics.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy & Terms web page: https://policies.google.com/privacy
      </p>
      <h2>Facebook</h2>
      <p>Facebook remarketing service is provided by Facebook Inc.</p>
      <p>
        You can learn more about interest-based advertising from Facebook by
        visiting this page: https://www.facebook.com/help/516147308587266
      </p>
      <p>
        To opt-out from Facebook`s interest-based ads, follow these instructions
        from Facebook: https://www.facebook.com/help/568137493302217
      </p>
      <p>
        Facebook adheres to the Self-Regulatory Principles for Online
        Behavioural Advertising established by the Digital Advertising Alliance.
        You can also opt-out from Facebook and other participating companies
        through the Digital Advertising Alliance in the USA
        http://www.aboutads.info/choices/, the Digital Advertising Alliance of
        Canada in Canada http://youradchoices.ca/ or the European Interactive
        Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/,
        or opt-out using your mobile device settings.
      </p>
      <p>
        For more information on the privacy practices of Facebook, please visit
        Facebook`s Data Policy: https://www.facebook.com/privacy/explanation
      </p>
      <h2>Usage, Performance and Miscellaneous</h2>
      <p>
        We may use third-party Service Providers to better improve our Service.
      </p>
      <h4>Invisible reCAPTCHA</h4>
      <p>
        We may use an invisible captcha service named reCAPTCHA. reCAPTCHA is
        operated by Google.
      </p>
      <p>
        The reCAPTCHA service may collect information from You and from Your
        Device for security.
      </p>
      <p>
        The information gathered by reCAPTCHA is held under the Privacy Policy
        of Google: https://www.google.com/intl/en/policies/privacy/
      </p>
      <h4>Google Places</h4>
      <p>
        Google Places returns information about places using HTTP requests. It
        is operated by Google
      </p>
      <p>
        Google Places service may collect information from You and from Your
        Device for security.
      </p>
      <p>
        The information gathered by Google Places is held under the Privacy
        Policy of Google: https://www.google.com/intl/en/policies/privacy/
      </p>
      <h4>GDPR Privacy</h4>
      <p>Legal Basis for Processing Personal Data under GDPR</p>
      <p>We may process Personal Data under these conditions:</p>
      <li>
        Consent: You have given Your consent for processing Personal Data for
        one or more specific purposes.
      </li>
      <br />
      <li>
        Performance of a contract: Provision of Personal Data is necessary for
        the performance of an agreement with You and/or for any pre-contractual
        obligations thereof.
      </li>
      <br />
      <li>
        Legal obligations: Processing Personal Data is necessary for compliance
        with a legal obligation to which the Company is subject.
      </li>
      <br />
      <li>
        Vital interests: Processing Personal Data is necessary to protect Your
        vital interests or of another natural person.
      </li>
      <br />
      <li>
        Public interests: Processing Personal Data is related to a task carried
        out in the public interest or in exercising official authority vested in
        the Company.
      </li>
      <br />
      <li>
        Legitimate interests: Processing Personal Data is necessary for the
        legitimate interests pursued by the Company.
      </li>
      <br />
      <p>
        The Company will gladly help to clarify the specific legal basis that
        applies to the processing, and in particular whether the provision of
        Personal Data is a statutory or contractual requirement, or a
        requirement to enter into a contract.
      </p>
      <h4>Your Rights under the GDPR</h4>
      <p>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </p>
      <p>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </p>
      <li>
        Request access to Your Personal Data. The right to access, update or
        delete the information We have on You. Whenever made possible, you can
        access, update or request deletion of Your Personal Data directly within
        Your account settings section. If you cannot perform these actions
        yourself, please contact Us to assist You. This also enables You to
        receive a copy of the Personal Data We hold about You.
      </li>
      <br />
      <li>
        Request correction of the Personal Data We hold about You. You have the
        right to have any incomplete or inaccurate information We hold about You
        corrected.
      </li>
      <br />
      <li>
        Object to processing of Your Personal Data. This right exists where We
        are relying on a legitimate interest as the legal basis for Our
        processing and there is something about Your particular situation, which
        makes You want to object to our processing of Your Personal Data on this
        ground. You may also object where We are processing Your Personal Data
        for direct marketing purposes.
      </li>
      <br />
      <li>
        Request erasure of Your Personal Data. You may ask Us to delete or
        remove Personal Data when there is no good reason for Us to continue
        processing it.
      </li>
      <br />
      <li>
        Request the transfer of Your Personal Data. We will provide to You, or
        to a third-party You have chosen, Your Personal Data in a structured,
        commonly used, machine-readable format. This right only applies to
        automated information which You initially provided consent for Us to use
        or where We used the information to perform a contract with You.
      </li>
      <br />
      <li>
        Withdraw Your consent. You have the right to withdraw Your consent on
        using your Personal Data. If You withdraw Your consent, We may not
        provide You with access to certain specific functionalities of the
        Service.
      </li>
      <br />
      <h4>Exercising of Your GDPR Data Protection Rights</h4>
      <p>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. We may ask You to verify Your identity
        before responding to such requests. If You make a request, We will try
        our best to respond to You soon.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <h2>Facebook Fan Page</h2>
      <br />
      <h4>Data Controller for the Facebook Fan Page</h4>
      <p>
        The Company is the Data Controller of Your Personal Data collected while
        using the Service. As operator of the Facebook Fan Page
        https://www.facebook.com/BumpBoxes/, the Company and the operator of the
        social network Facebook are Joint Controllers.
      </p>
      <p>
        The Company has entered into agreements with Facebook that define the
        terms for the Facebook Fan Page, among other things. These terms are
        mostly based on the Facebook Terms of Service:
        https://www.facebook.com/terms.php
      </p>
      <p>
        Visit the Facebook Privacy Policy https://www.facebook.com/policy.php
        for more information about how Facebook manages Personal data or contact
        Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations,
        1601 Willow Road, Menlo Park, CA 94025, United States.
      </p>
      <h4>Facebook Insights</h4>
      <p>
        We use the Facebook Insights function in connection with the operation
        of the Facebook Fan Page and based on the GDPR, to obtain anonymized
        statistical data about Our users.
      </p>
      <p>
        For this purpose, Facebook places a Cookie on the device of the user
        visiting Our Facebook Fan Page. Each Cookie contains a unique identifier
        code and remains active for two years, unless it is deleted before the
        end of this period.
      </p>
      <p>
        Facebook receives, records and processes the information stored in the
        Cookie, especially when the user visits the Facebook services, services
        provided by other members of the Facebook Fan Page and services by other
        companies that use Facebook services.
      </p>
      <p>
        For more information on the privacy practices of Facebook, please visit
        Facebook Privacy Policy here:
        https://www.facebook.com/privacy/explanation
      </p>
      <h2>CCPA Privacy</h2>
      <p>
        This privacy notice section for California residents supplements the
        information in Our Privacy Policy and it applies solely to all visitors,
        users, and others who reside in the State of California.
      </p>
      <h3>Categories of Personal Information Collected</h3>
      <p>
        We collect information that identifies, relates to, describes,
        references, can be associated with, or could reasonably be linked,
        directly or indirectly, with a particular Consumer or Device. The
        following is a list of categories of personal information which we may
        collect or may have been collected from California residents within the
        last twelve (12) months.
      </p>
      <p>
        The categories and examples provided in the list below are those defined
        in the CCPA. This does not mean that all examples of that category of
        personal information were in fact collected by Us, but reflects our good
        faith belief to the best of our knowledge that some of that information
        from the applicable category may be and may have been collected. For
        example, certain categories of personal information would be collected
        only if You provided such personal information directly to Us.
      </p>
      <h4>Category A: Identifiers.</h4>
      <p>
        Examples: A real name, alias, postal address, unique personal
        identifier, online identifier, Internet Protocol address, email address,
        account name, driver`s license number, passport number, or other similar
        identifiers.
      </p>
      <p>Collected: Yes.</p>
      <h4>
        Category B: Personal information categories in the California Customer
        Records statute (Cal. Civ. Code § 1798.80(e)).
      </h4>
      <p>
        Examples: A name, signature, Social Security number, physical
        characteristics or description, address, telephone number, passport
        number, driver`s license or state identification card number, insurance
        policy number, education, employment, employment history, bank account
        number, credit card number, debit card number, or any other financial
        information, medical information, or health insurance information. Some
        personal information in this category may overlap with other categories.
      </p>
      <p>Collected: Yes.</p>
      <h4>
        Category C: Protected classification characteristics under California or
        federal law.
      </h4>
      <p>
        Examples: Age (40 years or older), race, color, ancestry, national
        origin, citizenship, religion or creed, marital status, medical
        condition, physical or mental disability, sex (including gender, gender
        identity, gender expression, pregnancy or childbirth and related medical
        conditions), sexual orientation, veteran or military status, genetic
        information (including familial genetic information).
      </p>
      <p>Collected: No.</p>
      <h4>Category D: Commercial information.</h4>
      <p>
        Examples: Records and history of products or services purchased or
        considered.
      </p>
      <p>Collected: Yes.</p>
      <h4>Category E: Biometric information.</h4>
      <p>
        Examples: Genetic, physiological, behavioral, and biological
        characteristics, or activity patterns used to extract a template or
        other identifier or identifying information, such as, fingerprints,
        faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
        other physical patterns, and sleep, health, or exercise data.
      </p>
      <p>Collected: Yes.</p>
      <h4>Category F: Internet or other similar network activity.</h4>
      <p>Examples: Interaction with our Service or advertisement.</p>
      <p>Collected: Yes.</p>
      <h4>Category G: Geolocation data.</h4>
      <p>Examples: Approximate physical location.</p>
      <p>Collected: Yes.</p>
      <h4>Category H: Sensory data.</h4>
      <p>
        Examples: Audio, electronic, visual, thermal, olfactory, or similar
        information.
      </p>
      <p>Collected: No.</p>
      <h4>Category I: Professional or employment-related information.</h4>
      <p>Examples: Current or past job history or performance evaluations.</p>
      <p>Collected: No.</p>
      <h4>
        Category J: Non-public education information (per the Family Educational
        Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
      </h4>
      <p>
        Examples: Education records directly related to a student maintained by
        an educational institution or party acting on its behalf, such as
        grades, transcripts, class lists, student schedules, student
        identification codes, student financial information, or student
        disciplinary records.
      </p>
      <p>Collected: No.</p>
      <h4>Category K: Inferences drawn from other personal information.</h4>
      <p>
        Examples: Profile reflecting a person`s preferences, characteristics,
        psychological trends, predispositions, behavior, attitudes,
        intelligence, abilities, and aptitudes.
      </p>
      <p>Collected: Yes.</p>
      <h3>Under CCPA, personal information does not include:</h3>
      <br />
      <li>Publicly available information from government records</li>
      <br />
      <li>Deidentified or aggregated consumer information</li>
      <br />
      <li>Information excluded from the CCPA`s scope, such as:</li>
      <br />
      <li>
        Health or medical information covered by the Health Insurance
        Portability and Accountability Act of 1996 (HIPAA) and the California
        Confidentiality of Medical Information Act (CMIA) or clinical trial data
      </li>
      <br />
      <li>
        Personal Information covered by certain sector-specific privacy laws,
        including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
        Act (GLBA) or California Financial Information Privacy Act (FIPA), and
        the Driver`s Privacy Protection Act of 1994
      </li>
      <br />
      <h2>Sources of Personal Information</h2>
      <p>
        We obtain the categories of personal information listed above from these
        categories of sources:
      </p>
      <li>
        Directly from You. For example, from the forms You complete on our
        Service, preferences You express or provide through our Service, or from
        Your purchases on our Service.
      </li>
      <br />
      <li>
        Indirectly from You. For example, from observing Your activity on our
        Service.
      </li>
      <br />
      <li>
        Automatically from You. For example, through cookies We or our Service
        Providers set on Your Device as You navigate through our Service.
      </li>
      <br />
      <li>
        From Service Providers. For example, third-party vendors to monitor and
        analyze the use of our Service, third-party vendors to deliver targeted
        advertising to You, third-party vendors for payment processing, or other
        third-party vendors We use to provide the Service to You.
      </li>
      <br />
      <h3>
        Use of Personal Information for Business Purposes or Commercial Purposes
      </h3>
      <p>
        We may use or disclose personal information We collect for “business
        purposes” or “commercial purposes” (as defined under the CCPA), which
        may include these examples:
      </p>
      <li>To operate our Service and provide You with our Service.</li>
      <br />
      <li>
        To provide You with support and to respond to Your inquiries, including
        to investigate and address Your concerns and monitor and improve our
        Service.
      </li>
      <br />
      <li>
        To fulfill or meet the reason You provided the information. For example,
        if You share Your contact information to ask about our Service, We will
        use that personal information to respond to Your inquiry. If You
        personally inform purchase a product or service, We will use that
        information to process Your payment and facilitate delivery.
      </li>
      <br />
      <li>
        To respond to law enforcement requests and as required by law, court
        order, or governmental regulations.
      </li>
      <br />
      <li>
        As described to You when collecting Your personal information or as
        otherwise set forth in the CCPA.
      </li>
      <br />
      <li>For internal administrative and auditing purposes.</li>
      <br />
      <li>
        To detect security incidents and protect against malicious, deceptive,
        fraudulent or illegal activity, including, when necessary, to prosecute
        those responsible for such activities.
      </li>
      <br />
      <p>
        The examples provided above are illustrative and not intended to be
        exhaustive. For more details on how we use this information, please
        refer to the “Use of Your Personal Data” section.
      </p>
      <p>
        If We collect additional categories of personal information or use the
        personal information We collected for materially different, unrelated,
        or incompatible purposes We will update this Privacy Policy.
      </p>
      <h3>
        Disclosure of Personal Information for Business Purposes or Commercial
        Purposes
      </h3>
      <p>
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months these categories of personal information for business or
        commercial purposes:
      </p>
      <li>Category A: Identifiers</li>
      <br />
      <li>
        Category B: Personal information categories in the California Customer
        Records statute (Cal. Civ. Code § 1798.80(e))
      </li>
      <br />
      <li>Category D: Commercial information</li>
      <br />
      <li>Category F: Internet or other similar network activity</li>
      <br />
      <p>
        The categories listed above are those defined in the CCPA. This does not
        mean that all examples of that category of personal information were
        disclosed, but reflects our good faith belief to the best of our
        knowledge that some of that information from the applicable category may
        be and may have been disclosed.
      </p>
      <p>
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </p>
      <h3>Sale of Personal Information</h3>
      <p>
        As defined in the CCPA, “sell” and “sale” mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a consumer`s personal information by the business to a third
        party for valuable consideration. This means We may have received some
        kind of benefit for sharing personal information, but not necessarily a
        monetary benefit.
      </p>
      <p>
        The categories listed below are those defined in the CCPA. This does not
        mean that all examples of that category of personal information were in
        fact sold, but reflects our good faith belief to the best of our
        knowledge that some of that information from the applicable category may
        be and may have been shared for value in return.
      </p>
      <p>
        We may sell and may have sold in the last twelve (12) months these
        categories of personal information:
      </p>
      <li>Category A: Identifiers</li>
      <br />
      <li>
        Category B: Personal information categories in the California Customer
        Records statute (Cal. Civ. Code § 1798.80(e))
      </li>
      <br />
      <li>Category D: Commercial information</li>
      <br />
      <li>Category F: Internet or other similar network activity</li>
      <br />
      <h3>Share of Personal Information</h3>
      <p>
        We may share Your personal information identified in the above
        categories with these categories of third parties:
      </p>
      <li>Service Providers</li>
      <br />
      <li>Payment processors</li>
      <br />
      <li>Our affiliates</li>
      <br />
      <li>Our business partners</li>
      <br />
      <li>
        Third party vendors to whom You or Your agents authorize Us to disclose
        Your personal information in connection with products or services We
        provide to You
      </li>
      <br />
      <p>
        The above excludes text messaging originator opt-in data and consent;
        this information will not be shared with any third parties.
      </p>
      <h3>Sale of Personal Information of Minors Under 16 Years of Age</h3>
      <p>
        We do not knowingly collect personal information from minors under the
        age of 16 through our Service, although certain third party websites we
        link to may do so. These third-party websites have their own terms of
        use and privacy policies and we encourage parents and legal guardians to
        monitor their children`s Internet usage and instruct their children to
        never provide information on other websites without their permission.
      </p>
      <p>
        We do not sell the personal information of Consumers We actually know
        are less than 16 years of age, unless We receive affirmative
        authorization (the “right to opt-in”) from either the Consumer who is
        between 13 and 16 years of age, or the parent or guardian of a Consumer
        less than 13 years of age. Consumers who opt-in to the sale of personal
        information may opt-out of future sales. To exercise the right to
        opt-out, You (or Your authorized representative) may submit a request to
        Us by contacting Us.
      </p>
      <p>
        If You have reason to believe that a child under the age of 13 (or 16)
        has provided Us with personal information, please contact Us with
        sufficient detail to enable Us to delete that information.
      </p>
      <h2>Your Rights under the CCPA</h2>
      <p>
        The CCPA provides California residents with specific rights regarding
        their personal information. If You are a resident of California, You
        have the following rights:
      </p>
      <li>
        The right to notice. You have the right to be notified which categories
        of Personal Data are being collected and the purposes for which the
        Personal Data is being used.
      </li>
      <br />
      <li>
        The right to request. Under CCPA, You have the right to request that We
        disclose information to You about Our collection, use, sale, disclosure
        for business purposes and share of personal information. Once We receive
        and confirm Your request, We will disclose to You:
      </li>
      <br />
      <li>The categories of personal information We collected about You</li>
      <br />
      <li>
        The categories of sources for the personal information We collected
        about You
      </li>
      <br />
      <li>
        Our business or commercial purpose for collecting or selling that
        personal information
      </li>
      <br />
      <li>
        The categories of third parties with whom We share that personal
        information
      </li>
      <br />
      <li>
        The specific pieces of personal information We collected about You
      </li>
      <br />
      <li>
        If we sold Your personal information or disclosed Your personal
        information for a business purpose, We will disclose to You:
      </li>
      <br />
      <li>The categories of personal information categories sold</li>
      <br />
      <li>The categories of personal information categories disclosed</li>
      <br />
      <li>
        The right to say no to the sale of Personal Data (opt-out). You have the
        right to direct Us to not sell Your personal information. To submit an
        opt-out request please contact Us.
      </li>
      <br />
      <li>
        The right to delete Personal Data. You have the right to request the
        deletion of Your Personal Data, subject to certain exceptions. Once We
        receive and confirm Your request, We will delete (and direct Our Service
        Providers to delete) Your personal information from our records, unless
        an exception applies. We may deny Your deletion request if retaining the
        information is necessary for Us or Our Service Providers to:
      </li>
      <br />
      <li>
        Complete the transaction for which We collected the personal
        information, provide a good or service You requested, take actions
        reasonably anticipated within the context of our ongoing business
        relationship with You, or otherwise perform our contract with You.
      </li>
      <br />
      <li>
        Detect security incidents, protect against malicious, deceptive,
        fraudulent, or illegal activity, or prosecute those responsible for such
        activities.
      </li>
      <br />
      <li>
        Debug products to identify and repair errors that impair existing
        intended functionality.
      </li>
      <br />
      <li>
        Exercise free speech, ensure the right of another consumer to exercise
        their free speech rights, or exercise another right provided for by law.
      </li>
      <br />
      <li>
        Comply with the California Electronic Communications Privacy Act (Cal.
        Penal Code § 1546 et. seq.).
      </li>
      <br />
      <li>
        Engage in public or peer-reviewed scientific, historical, or statistical
        research in the public interest that adheres to all other applicable
        ethics and privacy laws, when the information`s deletion may likely
        render impossible or seriously impair the research`s achievement, if You
        previously provided informed consent.
      </li>
      <br />
      <li>
        Enable solely internal uses reasonably aligned with consumer
        expectations based on Your relationship with Us.
      </li>
      <br />
      <li>Comply with a legal obligation.</li>
      <br />
      <li>
        Make other internal and lawful uses of that information compatible with
        the context in which You provided it.
      </li>
      <br />
      <li>
        The right not to be discriminated against. You have the right not to be
        discriminated against for exercising any of Your consumer`s rights,
        including by:
      </li>
      <br />
      <li>Denying goods or services to You</li>
      <br />
      <li>
        Charging different prices or rates for goods or services, including the
        use of discounts or other benefits or imposing penalties
      </li>
      <br />
      <li>
        Providing a different level or quality of goods or services to You
      </li>
      <br />
      <li>
        Suggesting You will receive a different price or rate for goods or
        services or a different level or quality of goods or services
      </li>
      <br />
      <h2>Exercising Your CCPA Data Protection Rights</h2>
      <p>
        To exercise any of Your rights under the CCPA, and if You are a
        California resident, You can contact Us:
      </p>
      <p>By email: info@bumpboxes.com</p>
      <p>
        By visiting this page on our website:
        https://bumpboxes.com/privacy-policy
      </p>
      <p>By mail: 7719 North Pioneer Lane, Peoria, Illinois 61615</p>
      <p>
        Only You, or a person registered with the California Secretary of State
        You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </p>
      <p>Your request to Us must:</p>
      <li>
        Provide sufficient information that allows Us to reasonably verify You
        are the person about whom We collected personal information or an
        authorized representative
      </li>
      <br />
      <li>
        Describe Your request with sufficient detail that allows Us to properly
        understand, evaluate, and respond to it
      </li>
      <br />
      <h4>
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </h4>
      <li>Verify Your identity or authority to make the request</li>
      <br />
      <li>And confirm that the personal information relates to You</li>
      <br />
      <p>
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The period to
        provide the required information may be extended once by an additional
        45 days when reasonable necessary and with prior notice.
      </p>
      <p>
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request`s receipt.
      </p>
      <p>
        For data portability requests, We will select a format to provide Your
        personal information readily useable and should allow You to transmit
        the information from one entity to another entity without hindrance.
      </p>
      <h2 id='doNotSellData' ref={doNotSellDataRef}>
        Do Not Sell My Personal Information
      </h2>
      <p>
        You have the right to opt-out of the sale of Your personal information.
        Once We receive and confirm a verifiable consumer request from You, we
        will stop selling Your personal information. To exercise Your right to
        opt-out, please contact Us.
      </p>
      <p>
        The Service Providers we partner with (for example, our analytics or
        advertising partners) may use technology on the Service that sells
        personal information as defined by the CCPA law. If you wish to opt out
        of the use of Your personal information for interest-based advertising
        purposes and these potential sales as defined under CCPA law, you may
        follow the instructions below.
      </p>
      <p>
        Any opt out is specific to the browser You use. You may need to opt out
        on every browser You use.
      </p>
      <h3>Website</h3>
      <p>
        You may be able to opt out of receiving ads personalized as served by
        our Service Providers by following our instructions presented on the
        Service:
      </p>
      <li>
        The NAI`s opt-out platform: http://www.networkadvertising.org/choices/
      </li>
      <br />
      <li>The EDAA`s opt-out platform http://www.youronlinechoices.com/</li>
      <br />
      <li>
        The DAA`s opt-out platform: http://optout.aboutads.info/?c=2&lang=EN
      </li>
      <br />
      <p>
        The opt out will place a cookie on Your computer unique to the browser
        You use to opt out. If you change browsers or delete the cookies saved
        by your browser, You will need to opt out again.
      </p>
      <h3>Mobile Devices</h3>
      <p>
        Your mobile device may give You the ability to opt out of the use of
        information about the apps You use to serve You ads targeted to Your
        interests:
      </p>
      <li>
        “Opt out of Interest-Based Ads” or “Opt out of Ads Personalization” on
        Android devices
      </li>
      <br />
      <li>“Limit Ad Tracking” on iOS devices</li>
      <br />
      <p>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on Your mobile device.
      </p>
      <p>
        “Do Not Track” Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </p>
      <p>Our Service does not respond to Do Not Track signals.</p>
      <p>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites You do not want to be
        tracked. You can enable or disable DNT by visiting the preferences or
        settings page of Your web browser.
      </p>
      <h3>Children&apos;s Privacy</h3>
      <br />
      <p>
        Our Service addresses no one under the age of 13. We do not knowingly
        collect personally identifiable information from anyone under the age of
        13. If You are a parent or guardian and You know Your child has provided
        Us with Personal Data, please contact Us. If We learn We have collected
        Personal Data from anyone under the age of 13 without verification of
        parental consent, We try to remove that information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent`s consent before We collect and use that
        information.
      </p>
      <p>Your California Privacy Rights (California`s Shine the Light law)</p>
      <p>
        Under California Civil Code Section 1798 (California`s Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties` direct marketing
        purposes.
      </p>
      <p>
        If you`d like to request more information under the California Shine the
        Light law, and if You are a California resident, You can contact Us
        using the contact information below.
      </p>
      <p>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </p>
      <p>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p>
        To request removal of such data, and if You are a California resident,
        You can contact Us using the contact information below, and include the
        email address associated with Your account.
      </p>
      <p>
        Know Your request does not guarantee complete or comprehensive removal
        of content or information posted online and that the law may not permit
        or require removal in certain circumstances.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to other websites not operated by Us. If
        You click on a third party link, You will be directed to that third
        party`s site. We strongly advise You to review the Privacy Policy of
        every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <br />
      <p>
        We may update Our Privacy Policy occasionally. We will notify You of any
        changes by posting the new Privacy Policy on this page and, if we deem
        additional notice appropriate, by other means we deem appropriate. It is
        your duty to visit this page to review any updates to the Privacy
        Policy.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        before the change becoming effective and update the “Last updated” date
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when posted on
        this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy, You can contact us:
      </p>
      <p>By email: info@bumpboxes.com</p>
      <p>
        By visiting this page on our website:
        https://bumpboxes.com/privacy-policy
      </p>
      <p>By mail: 7719 North Pioneer Lane, Peoria, Illinois 61615</p>
    </Col>
  </Row>
);

export default PrivacyText;
