import React from 'react';
import './style.css';
import { Col, Row } from 'react-bootstrap';
import CartTotal from '../cart-total';

const ProgressBarCheckout = (props) => {
  let firstPageText = props.firstPage;
  if (props.useAlt) firstPageText = props.firstPageAlt;
  if (props.useAltTwo) firstPageText = props.firstPageAltTwo;

  function renderPageContent() {
    return (
      <div className='progressBarCheckout'>
        {props.showCartTotal ? (
          <CartTotal
            title='Checkout'
            totalCost={props.totalCost.toFixed(2)}
            subTotal={props.subTotal.toFixed(2)}
            tax={props.taxesAndFees.toFixed(2)}
            totalDiscount={props.totalDiscount.toFixed(2)}
            shippingInsuranceCost={props.shippingInsuranceCost.toFixed(2)}
            cartInfo={props.cartInfo}
            isInfinityPlan={props.isInfinityPlan}
            shipping={props.shipping}
            isCorporateCheckout={props.isCorporateCheckout}
          />
        ) : null}

        <Row className='progressBarRow'>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Row className='arrowFirst progressTextHighlight'>
              <Col xs={12}>
                <button
                  className='progressBarButton'
                  type='button'
                  aria-label='Go to Step 1'
                  onClick={() => props.setRenderPage(props.firstPage, 25)}
                  disabled={!props.isFirstPageReady()}
                >
                  <h6>{firstPageText}</h6>
                </button>
              </Col>
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Row>
              <Col
                xs={12}
                className={`arrowMiddle ${
                  props.pageDisplayed === props.secondPage ||
                  props.pageDisplayed === props.thirdPage
                    ? 'progressTextHighlight'
                    : 'progressTextNormal'
                }`}
              >
                <button
                  className='progressBarButton'
                  type='button'
                  aria-label='Go to Step 2'
                  onClick={() => props.setRenderPage(props.secondPage, 50)}
                  disabled={
                    !props.isFirstPageReady() && !props.isSecondPageReady()
                  }
                >
                  <h6>{props.secondPage}</h6>
                </button>
              </Col>
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Row>
              <Col
                xs={12}
                className={`arrowLast ${
                  props.pageDisplayed === props.thirdPage
                    ? 'progressTextHighlight'
                    : 'progressTextNormal'
                }`}
              >
                <button
                  className='progressBarButton'
                  type='button'
                  aria-label='Go to Step 3'
                  onClick={() => props.setRenderPage(props.thirdPage, 75)}
                  disabled={
                    !props.isFirstPageReady() &&
                    !props.hasSecondPageBeenSuccessfullyCompleted
                  }
                >
                  <h6>{props.thirdPage}</h6>
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='progressBarContainer' id={props.id}>
          {props.renderCurrentPage()}
          {props.pageDisplayed === props.secondPage ? (
            <Row className='mb-4 previousStepRow'>
              <Col xs={6} sm={6} md={6} lg={6} className='previousStepRowCol'>
                <button
                  onClick={() => props.setRenderPage(props.firstPage, 25)}
                  className='returnBtn'
                  type='button'
                  aria-label='Go to Previous Step - Step 1'
                >
                  {'<'} RETURN TO PREVIOUS STEP
                </button>
              </Col>
            </Row>
          ) : null}
          {props.pageDisplayed === props.thirdPage ? (
            <Row className='mb-4 previousStepRow'>
              <Col xs={6} sm={6} md={6} lg={6} className='previousStepRowCol'>
                <button
                  onClick={() => props.setRenderPage(props.secondPage, 50)}
                  className='returnBtn'
                  type='button'
                  aria-label='Go to Previous Step - Step 2'
                >
                  {'<'} RETURN TO PREVIOUS STEP
                </button>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
    );
  }

  return renderPageContent();
};

export default ProgressBarCheckout;
