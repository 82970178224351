/* eslint-disable camelcase */
import Environment from './Environment';
import loadExternalScript from './LoadExternalScript';
import logger from './Logger';

function sendToDatalayer() {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

function sendDatalayerVariables(paramObject) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(paramObject);
}

function loadGtag() {
  loadExternalScript(
    `https://www.googletagmanager.com/gtag/js?id=${Environment.getGoogleAnalyticsTrackingId}`,
  )
    .then(() => {
      window.dataLayer = window.dataLayer || [];
      sendToDatalayer('js', new Date());
      sendToDatalayer('config', Environment.getGoogleAdWordsId, {
        allow_enhanced_conversions: true,
      });

      const gaOptions = {
        allowLinker: true,
        allow_enhanced_conversions: true,
      };
      if (Environment.getEnv === 'PRODUCTION') {
        gaOptions.optimize_id = Environment.getGoogleOptimizeId;
      } else {
        gaOptions.debug_mode = true;
      }
      sendToDatalayer(
        'config',
        Environment.getGoogleAnalyticsTrackingId,
        gaOptions,
      );
      sendToDatalayer('config', Environment.getUATrackingId, gaOptions);
    })
    .catch((err) => {
      logger.error(err, 'gtag script load failed');
    });
}

export default {
  loadGtag,
  sendToDatalayer,
  sendDatalayerVariables,
};
