import React from 'react';
import { Image } from 'react-bootstrap';
import Images from '../../images';
import './style.css';

const MAX_RETRIES = 20;
const RETRY_INTERVAL_MILLIS = 100;

class WebpImage extends React.Component {
  constructor() {
    super();
    this.state = {
      retriesRemaining: MAX_RETRIES,
    };
  }

  /**
   * Check browser webp support
   * @returns {boolean}
   */
  canUseWebP() {
    const elem =
      typeof document === 'object' ? document.createElement('canvas') : {};

    if (elem && elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  // Referencing previous state to comply with no-access-state-in-setstate
  decrementRetriesRemaining() {
    this.setState((prevState) => ({
      retriesRemaining: prevState.retriesRemaining - 1,
    }));
  }

  /**
   * Render jsx to html
   * @returns {XML}
   */
  render() {
    // GOTCHA: This retry exist because we're async loading the images per store.
    // Remove this if we change to syncrhronous loading of the Images data.
    if (
      Images[this.props.imageName] === undefined &&
      this.state.retriesRemaining > 0
    ) {
      setTimeout(() => {
        this.decrementRetriesRemaining();
      }, RETRY_INTERVAL_MILLIS);
    }

    return this.renderActualImage();
  }

  renderActualImage() {
    const {
      imageName,
      alt,
      title,
      style,
      className,
      width,
      height,
      fluid,
    } = this.props;
    const webpSupport = this.canUseWebP();
    let image = Images.logo;
    const imageOptions = Images[imageName || ''];

    let altToUse = alt;
    let titleToUse = title;

    if (imageOptions) {
      // Define image type
      if (webpSupport === true) {
        image = imageOptions.webp;
      } else {
        image = imageOptions.src;
      }

      // Favor an input override if present.
      if (!altToUse) {
        altToUse = imageOptions.alt;
      }

      // Favor an input override if present.
      if (!titleToUse) {
        titleToUse = imageOptions.title;
      }
    }

    return (
      <Image
        src={image}
        alt={altToUse}
        title={titleToUse}
        style={style}
        className={className}
        width={width}
        height={height}
        fluid={fluid}
        crossOrigin='anonymous'
      />
    );
  }
}

export default WebpImage;
