import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';

const RegularHeader = props => (
  <Row>
    <Col className='regular-header' md={12} lg={12}>
      <h1>{props.title}</h1>
    </Col>
  </Row>
);

export default RegularHeader;
