import React from 'react';
import { StructuredData } from '../../components';

const data = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://bumpboxes.com/',
  name: 'Bump Boxes',
  logo: 'https://bumpboxes.com/bumpboxes-logo-optimized.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '7719 N Pioneer Lane',
    addressLocality: 'Peoria',
    addressRegion: 'IL',
    postalCode: '61615',
    addressCountry: 'USA',
  },
  sameAs: [
    'https://www.instagram.com/bumpboxes/',
    'https://www.facebook.com/BumpBoxes/',
    'https://twitter.com/bumpboxes',
    'https://www.pinterest.com/bumpboxes/',
    'https://www.linkedin.com/company/bump-boxes/',
  ],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-888-913-7879',
    contactType: 'Customer Service',
    email: 'info@bumpboxes.com',
  },
};

const SchemaData = () => <StructuredData data={data} />;

export default SchemaData;
