import Environment from './Environment';

// this syntax is used to prevent cyclic dependencies
const BitsyApollo = require('./BitsyApollo').default;

const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  const isGuestCheckout = JSON.parse(localStorage.getItem('isGuestCheckout'));
  if (token && !isGuestCheckout) return true;
  return false;
};

const isAdmin = () => {
  const isAdminItem = localStorage.getItem('isAdmin');
  if (isAdminItem && isLoggedIn()) return true;
  return false;
};

const hasToken = () => {
  const token = localStorage.getItem('token');
  if (token && token !== 'null') return true;
  return false;
};

const clearEverythingButCart = () => {
  // BitsyApollo.clearClientStore();
  const tempCartAsString = BitsyApollo.getTempCartAsString();
  localStorage.clear();
  localStorage.setItem('tempCart', tempCartAsString);
};

const logout = () => {
  BitsyApollo.signOutUser();
  localStorage.clear();
  BitsyApollo.clearClientStore();
};

const clearAndRedirect = () => {
  clearEverythingButCart();
  window.location = `${Environment.getSelfDomain}`;
};

const logoutAndRedirect = () => {
  logout();
  window.location = `${Environment.getSelfDomain}/signin`;
};

const getCompanyFromLocalStorage = () => {
  const company = {
    companyCode: localStorage.getItem('companyCode'),
    companyInfo: localStorage.getItem('companyInfo'),
  };
  if (company.companyInfo) {
    company.companyInfo = JSON.parse(company.companyInfo);
  }
  return company;
};

export default {
  isLoggedIn,
  isAdmin,
  hasToken,
  logout,
  logoutAndRedirect,
  clearAndRedirect,
  clearEverythingButCart,
  getCompanyFromLocalStorage,
};
