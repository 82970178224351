import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, FormCheck } from 'react-bootstrap';
import SMSOptIn from '../sms-opt-in';
import './style.css';

const displayNothing = () => null;

const determineTermsAndConditionsLink = (companyCode) => {
  if (companyCode && companyCode === 'Dominion Energy') {
    return (
      <a
        href='https://public-bitsy-images.s3.amazonaws.com/legal/DominionEnergy_TermsAndConditions.pdf'
        target='_blank'
        rel='noopener noreferrer'
        className='termsAndConditions dominionEnergyTerms'
      >
        Terms & Conditions
      </a>
    );
  }
  return (
    <Link to='/terms-of-service' target='_blank' className='termsAndConditions'>
      Terms & Conditions
    </Link>
  );
};

const DisplayCheckboxes = (props) => {
  if (props.isHideDisplayCheckboxes) {
    return displayNothing();
  }

  return (
    <Col className='checkboxesSignUp'>
      {props.termsAndConditionsError ? (
        <Row className='form-error'>{props.termsAndConditionsError}</Row>
      ) : null}
      {props.showSMSOptIn ? (
        <SMSOptIn
          handleSMSOptInToggle={props.handleSMSOptInToggle}
          isSmsOptInSelected={props.isSmsOptInSelected}
          containerId='signUpSMSOptInContainer'
          giveawaySMSMessage={props.smsOptInMessage}
        />
      ) : null}
      {props.companyCode ? null : (
        <Row>
          <Col className='isSubscribedToNewsletterCol'>
            <FormCheck aria-label='I want shipping updates and Bump Boxes info!'>
              <FormCheck.Label>
                <FormCheck.Input
                  checked={
                    props.formValues
                      ? props.formValues.isSubscribedToNewsletter
                      : true
                  }
                  onChange={props.toggleSubscriptionToNewsletter}
                  id='isSubscribedToNewsletter'
                  isStatic={false}
                />
                {props.subscriptionToNewsletterMessage ||
                  'I want shipping updates and Bump Boxes info!'}
              </FormCheck.Label>
            </FormCheck>
          </Col>
        </Row>
      )}
      <Row>
        <Col className='isAgreedToTermsAndConditionsCol'>
          <FormCheck
            id='isAgreedToTermsAndConditions'
            aria-label='I agree to Terms and Conditions'
          >
            <FormCheck.Label>
              <FormCheck.Input
                checked={
                  props.formValues
                    ? props.formValues.isAgreedToTermsAndConditions
                    : false
                }
                onChange={props.toggleTermsAndConditions}
                isStatic={false}
              />
              I agree to{'  '}
              {determineTermsAndConditionsLink(props.companyCode)}
              {'  '}and{'  '}
              <Link
                to='/privacy-policy'
                target='_blank'
                className='termsAndConditions'
              >
                Privacy Policy
              </Link>
            </FormCheck.Label>
          </FormCheck>
        </Col>
      </Row>
    </Col>
  );
};

const DisplayCheckboxesOrNot = DisplayCheckboxes;

export default DisplayCheckboxesOrNot;
