import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';

let timer;

export default function CartCountdownTimer() {
  useEffect(() => {
    function runCartTimer() {
      // Set the date we're counting down to
      const countDownDate = new Date(
        new Date(Date.now()).getTime() + 15 * 60000,
      ).getTime();

      // Update the count down every 1 second
      timer = setInterval(() => {
        // Get today's date and time
        const now = new Date(Date.now()).getTime();
        // Find the distance between now and the count down date
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // correct for single digit seconds
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }
        const el = document.getElementById('cartTimerOptimize');
        if (el) {
          document.getElementById(
            'cartTimerOptimize',
          ).innerHTML = `${minutes}:${seconds}`;
        }

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(timer);
          document.getElementById('cartTimerOptimize').innerHTML = 'EXPIRED';
        }
      }, 500);
    }

    runCartTimer();

    return function clearTimer() {
      clearInterval(timer);
    };
  });

  return (
    <Container className='countdownContainer'>
      <p className='mt-4'>
        Your cart is reserved for the next 15 minutes.
        <br />
        Checkout now to claim your offer!
        <br />
        Time Remaining: <span id='cartTimerOptimize' className='bold' />
      </p>
    </Container>
  );
}
