import React from 'react';
import Imgix from 'react-imgix';
import './ExternalImage.css';

const ExternalImage = (props) => (
  <Imgix
    src={props.src
      .replace(
        /https:\/\/s3.amazonaws.com\/bitsy-product-images\//i,
        'https://bump-boxes.imgix.net/',
      )
      .replace(
        /https:\/\/bitsy-product-images.s3.amazonaws.com\//i,
        'https://bump-boxes.imgix.net/',
      )}
    htmlAttributes={{
      alt: props.alt || 'Monthly Pregnancy Subscription Box Image',
      title: props.title,
    }}
    className={`externalImage ${props.className || ''}`}
    width={props.width}
    height={props.height}
    sizes={props.sizes || '100vw'}
    imgixParams={{ q: 75, auto: ['format', 'compress'], fit: 'max' }}
  />
);

export default React.memo(ExternalImage);
