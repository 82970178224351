import React from 'react';
import { Col } from 'react-bootstrap';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from './checkout-form';
import Environment from '../../services/Environment';
import Logger from '../../services/Logger';
import loadExternalScript from '../../services/LoadExternalScript';

let stripeIsLoading = true;
let alreadyFetchingStripe = false;

class CreditCardForm extends React.PureComponent {
  // Stripe async loading is based on:
  // https://www.npmjs.com/package/react-stripe-elements#loading-stripejs-asynchronously
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      stripeIsLoading = false;
      this.setState({
        stripe: window.Stripe(Environment.getStripePublicAPIKey),
      });
    }
  }

  beginLoad = () => {
    if (!alreadyFetchingStripe) {
      alreadyFetchingStripe = true;

      const self = this;
      loadExternalScript('https://js.stripe.com/v3/')
        .then(() => {
          stripeIsLoading = false;
          self.setState({
            stripe: window.Stripe(Environment.getStripePublicAPIKey, {
              apiVersion: Environment.getStripeApiVersion,
            }),
          });
        })
        .catch((e) => {
          Logger.error(e);
        });
    }
  };

  render() {
    if (stripeIsLoading && !window.stripe) {
      this.beginLoad();
    }

    return (
      <Col className='credit-card-container' xs={12} sm={12} md={12} lg={12}>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <InjectedCheckoutForm
              stripe={this.state.stripe}
              isCheckoutRevamp={this.props.isCheckoutRevamp}
              handleStripeUpdate={this.props.handleStripeUpdate}
              innerRef={this.props.innerRef}
              isEdit={this.props.isEdit}
              totals={this.props.totals}
              error={this.props.error}
              onChange={this.props.onChange}
              isCheckout={this.props.isCheckout}
              hasBeenSubmitted={this.props.hasBeenSubmitted}
              goToNextStep={this.props.goToNextStep}
              toggleSubscriptionToNewsletter={
                this.props.toggleSubscriptionToNewsletter
              }
              toggleTermsAndConditions={this.props.toggleTermsAndConditions}
              termsAndConditionsError={this.props.termsAndConditionsError}
              isHideDisplayCheckboxes={this.props.isHideDisplayCheckboxes}
              formValuesValidationState={this.props.formValuesValidationState}
              formValues={this.props.formValues}
              handleChange={this.props.handleChange}
              isGuestCheckout={this.props.isGuestCheckout}
              isLoading={this.props.isLoading}
              unknownError={this.props.unknownError}
              hideBillingSameAsShipping={this.props.hideBillingSameAsShipping}
              isBillingSameAsShipping={this.props.isBillingSameAsShipping}
              toggleIsBillingSameAsShipping={
                this.props.toggleIsBillingSameAsShipping
              }
              hideAllBillingAddressToggle={
                this.props.hideAllBillingAddressToggle
              }
              displayBilling={this.props.displayBilling}
              userInfo={this.props.userInfo}
              usePreviousCard={this.props.usePreviousCard}
              toggleUsePreviousCard={this.props.toggleUsePreviousCard}
              loadingStripe={stripeIsLoading}
            />
          </Elements>
        </StripeProvider>
      </Col>
    );
  }
}

export default CreditCardForm;
