/* eslint-disable camelcase */
import Logger from './Logger';
import Environment from './Environment';

const sendMetaPixelEvent = (eventName, eventData, eventID) => {
  if (window.fbq) {
    window.fbq('track', eventName, eventData, eventID);
  }
};

const init = (advancedMatchingData) => {
  if (window.fbq && advancedMatchingData) {
    window.fbq('init', Environment.getFacebookPixelId, advancedMatchingData);
  }
};

const subscribe = (fBData) => {
  const { currency, value } = fBData;
  const subscribeData = {
    currency,
    value,
  };
  sendMetaPixelEvent('Subscribe', subscribeData);
};

const populateFBData = (trackingData, cartInfo) => {
  const contentIds = [];
  if (cartInfo.subscription && cartInfo.subscription.plan) {
    contentIds.push(cartInfo.subscription.plan.sku);
  }
  if (cartInfo.productsAndDetails && cartInfo.productsAndDetails.length) {
    cartInfo.productsAndDetails.forEach((productAndDetails) => {
      contentIds.push(productAndDetails.product.sku);
    });
  }
  let itemTotal = 0;
  if (cartInfo.subscription && cartInfo.subscription.plan) {
    itemTotal += 1;
  }
  if (cartInfo.productsAndDetails && cartInfo.productsAndDetails.length) {
    itemTotal += cartInfo.productsAndDetails.length;
  }
  const fBData = {
    currency: 'USD',
    value: trackingData.total,
    content_ids: contentIds,
    content_type: 'product',
    num_items: itemTotal,
  };

  return fBData;
};

function viewedProduct(product) {
  try {
    const fbData = {
      content_ids: product.sku,
      content_category: 'Shop',
      content_name: product.name,
      content_type: 'product',
      currency: 'USD',
      value: product.actualCost || 0,
    };
    sendMetaPixelEvent('ViewContent', fbData);
  } catch (e) {
    Logger.error(e);
  }
}

function viewedSubscription(plan) {
  try {
    const fbData = {
      content_ids: plan.sku,
      content_category: 'Subscription',
      content_name: plan.sku,
      content_type: 'product',
      currency: 'USD',
      value: plan.costWithCoupon || plan.actualCost,
    };
    sendMetaPixelEvent('ViewContent', fbData);
  } catch (e) {
    Logger.error(e);
  }
}

function FBTransactionComplete(trackingData, cartInfo) {
  try {
    const fBData = populateFBData(trackingData, cartInfo);
    sendMetaPixelEvent('Purchase', fBData, {
      eventID: trackingData.res.justPurchasedPurchaseId.toString(),
    });
    subscribe(fBData);
  } catch (e) {
    Logger.error(e);
  }
}

const checkoutStarted = (cart) => {
  const cartTotal = {
    total: cart.subCosts ? cart.subCosts.overallTotal : 0,
  };
  const fBData = populateFBData(cartTotal, cart);
  sendMetaPixelEvent('InitiateCheckout', fBData);
};

const addToCart = (cart, newCartItem) => {
  const cartTotal = {
    total:
      cart.subCosts && cart.subCosts.overallTotal
        ? cart.subCosts.overallTotal
        : 0,
  };
  const fBData = populateFBData(cartTotal, cart);
  if (newCartItem) {
    fBData.content_name = newCartItem.name || newCartItem.sku;
  }
  sendMetaPixelEvent('AddToCart', fBData);
};

const addLead = () => {
  const fbData = {};
  sendMetaPixelEvent('Lead', fbData);
};

export default {
  init,
  FBTransactionComplete,
  checkoutStarted,
  addToCart,
  subscribe,
  addLead,
  viewedProduct,
  viewedSubscription,
};
