import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import MoneyUtils from '../../services/MoneyUtils';
import './style.css';
import ExternalImage from '../product/ExternalImage';

const UpsellBox = (props) => (
  <>
    <Row className='upsellDetails'>
      <Col xs={6} sm={6} md={6} lg={6} className='upsellText'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <p className='upsellHeader'>{props.header || props.productName}</p>
          </Col>
        </Row>
        {props.productDescription && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div>{props.productDescription}</div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div
              className={
                props.regPrice === props.price ? 'price' : 'priceSlashed'
              }
            >
              ${MoneyUtils.formatAsMoney(props.regPrice)}
            </div>
          </Col>
          {props.regPrice !== props.price ? (
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='price'>
                ${MoneyUtils.formatAsMoney(props.price)}
              </div>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col xs={6} sm={6} md={6} lg={6}>
        <Row>
          <Col className='upsellImage' xs={12} sm={12} md={12} lg={12}>
            <ExternalImage
              src={props.image}
              sizes='(max-width: 414px) 50vw, 25vw'
              alt={props.productName}
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        {props.buttonAction && props.buttonText ? (
          <Button
            active={false}
            block={false}
            onClick={props.buttonAction}
            className='upsellButton'
          >
            {props.buttonText}
          </Button>
        ) : null}
      </Col>
    </Row>
  </>
);

export default React.memo(UpsellBox);
