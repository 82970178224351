import Logger from './Logger';

function fetchNewScript() {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src =
      '//staticw2.yotpo.com/MtI6WMUc1SMDm8DW3ZmRDSTA4TUsLomtzi81ZY7C/widget.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
    script.addEventListener('load', () => {
      resolve('Loaded');
    });
    document.head.appendChild(script);
  });
}

async function fetchNewScriptAndConsumeExceptions() {
  return fetchNewScript().catch((err) => {
    Logger.warn(
      'Yotpo could not be initialized with fetchNewScript. Moving on.',
      err,
    );
  });
}

function runScriptWhenIdIsPresent(id) {
  if (document.getElementById(id)) {
    if (window.yotpo) {
      try {
        const refreshWidgets = window.yotpo
          ? window.yotpo.refreshWidgets.bind(window.yotpo)
          : () => {};
        refreshWidgets();
      } catch (err) {
        Logger.warn(
          'Yotpo could not be initialized with runScriptWhenIdIsPresent. Moving on.',
          err,
        );
      }
    } else {
      return fetchNewScriptAndConsumeExceptions();
    }
  }

  return false;
}

async function initializeScript() {
  if (!window.yotpo) {
    return fetchNewScriptAndConsumeExceptions();
  }

  return false;
}

export default {
  runScriptWhenIdIsPresent,
  initializeScript,
};
