import * as Sentry from '@sentry/react';

const logger = {};
logger.info = function wrapInfo(message, extraInfo) {
  console.log(message); // eslint-disable-line no-console
  Sentry.withScope((scope) => {
    if (extraInfo) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in extraInfo) {
        if (key) {
          scope.setExtra(key, extraInfo[key]);
        }
      }
    }
    Sentry.captureMessage(message, 'info');
  });
};

logger.error = function wrapError(error, extraInfo) {
  console.log('ERROR:', error); // eslint-disable-line no-console
  if (extraInfo) {
    console.log(extraInfo); // eslint-disable-line no-console
  }
  Sentry.withScope((scope) => {
    if (extraInfo) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in extraInfo) {
        if (key) {
          scope.setExtra(key, extraInfo[key]);
        }
      }
    }
    const sentryError = typeof error === 'string' ? new Error(error) : error;
    Sentry.captureException(sentryError);
  });
};

logger.warn = function wrapWarn(message, extraInfo) {
  console.log(message); // eslint-disable-line no-console
  Sentry.withScope((scope) => {
    if (extraInfo) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in extraInfo) {
        if (key) {
          scope.setExtra(key, extraInfo[key]);
        }
      }
    }
    Sentry.captureMessage(message, 'warning');
  });
};

export default logger;
