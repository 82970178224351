import React from 'react';
import { Dropdown, DropdownButton, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import MoneyUtils from '../../services/MoneyUtils';
import './style.css';

const cartTotal = (props) => (
  <Row className='cartTotalRow'>
    <Col xs={12} sm={12} md={12} lg={12} className='dropdownMenu'>
      <DropdownButton
        id='dropdown-basic-button'
        variant='default'
        title={
          <div className='orderSummaryRow'>
            <span>
              <FontAwesomeIcon icon={faShoppingCart} alt='Shopping Cart' />
              Order Summary
            </span>
            <span>${MoneyUtils.formatAsMoney(props.totalCost)}</span>
          </div>
        }
      >
        <Dropdown.Item as='span' style={{ padding: 0 }}>
          <Container className='orderSummaryListContainer'>
            <Row>
              <Col xs={7} sm={7} md={7} lg={7}>
                ITEM
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} className='rightCol'>
                QTY
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} className='rightCol'>
                PRICE
              </Col>
            </Row>
            {props.cartInfo.subscription ? (
              <>
                <Row>
                  <Col xs={7} sm={7} md={7} lg={7} className='boldFont'>
                    {props.cartInfo.subscription.plan.title} Subscription
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} className='rightCol'>
                    1
                  </Col>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className='rightCol boldFont'
                  >
                    $
                    {props.cartInfo.subscription.plan.actualCostAsShipping
                      ? MoneyUtils.formatAsMoney(0)
                      : MoneyUtils.formatAsMoney(
                          props.cartInfo.subscription.plan.originalCost,
                        )}
                  </Col>
                </Row>
                <Row>
                  {props.isInfinityPlan ? (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      {props.cartInfo.subscription.plan.type === 'BOX' ? (
                        <span className='orderSummaryListBrand'>
                          Billed monthly.{' '}
                        </span>
                      ) : (
                        <span className='orderSummaryListBrand'>
                          Billed every 21 days.{' '}
                        </span>
                      )}
                      <Link
                        to='/terms-of-service'
                        target='_blank'
                        className='termLink'
                      >
                        See Terms
                      </Link>
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null}
            {props.cartInfo.productsAndDetails.length
              ? props.cartInfo.productsAndDetails.map((product) => (
                  <Row key={product.product.name}>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      className='cartTotalName boldFont'
                    >
                      {product.product.name}
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} className='rightCol'>
                      {product.quantity}
                    </Col>
                    <Col
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      className='rightCol boldFont'
                    >
                      ${MoneyUtils.formatAsMoney(product.amountToCharge)}
                    </Col>
                    <Col className='orderSummaryListBrand'>
                      {product.product.brand}
                    </Col>
                  </Row>
                ))
              : null}
          </Container>
          <Container>
            <Row>
              <Col>Subtotal</Col>
              <Col className='rightCol'>
                ${MoneyUtils.formatAsMoney(props.subTotal)}
              </Col>
            </Row>
            <Row>
              <Col>Shipping</Col>
              <Col className='rightCol'>
                ${MoneyUtils.formatAsMoney(props.shipping)}
              </Col>
            </Row>
            <Row>
              <Col>Protection</Col>
              <Col className='rightCol'>
                ${MoneyUtils.formatAsMoney(props.shippingInsuranceCost)}
              </Col>
            </Row>
            <Row>
              <Col>Tax</Col>
              <Col className='rightCol'>
                ${MoneyUtils.formatAsMoney(props.tax)}
              </Col>
            </Row>
            {props.totalDiscount > 0 ? (
              <Row>
                <Col>{props.isCorporateCheckout ? 'Benefit' : 'Discounts'}</Col>
                <Col className='rightCol'>
                  {' '}
                  - ${MoneyUtils.formatAsMoney(props.totalDiscount)}
                </Col>
              </Row>
            ) : null}

            <hr />
            <Row className='boldFont'>
              <Col>Total</Col>
              <Col className='rightCol'>
                ${MoneyUtils.formatAsMoney(props.totalCost)} (USD)
              </Col>
            </Row>
          </Container>
        </Dropdown.Item>
      </DropdownButton>
    </Col>
    <Col xs={12} sm={12} md={12} lg={12}>
      <h1 id='cart-total-title'>{props.title}</h1>
    </Col>
  </Row>
);

export default React.memo(cartTotal);
