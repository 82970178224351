import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Images from '../../images';

import './style.css';

export default function SubComparisonTable() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Table
      bordered
      hover
      responsive
      id='subComparisonTable'
      className='mt-4 subComparisonTable'
    >
      <thead>
        <tr>
          <th className='featureColumn'>Special Offers</th>
          <th>
            <Link to='/products/six-month-pregnancy-subscription-box'>
              <Image
                src={Images.subSelection6.src}
                alt={Images.subSelection6.alt}
              />
              6 Month Subscription
            </Link>
          </th>
          <th>
            <Link to='/products/nine-month-pregnancy-subscription-box'>
              <Image
                src={Images.subSelection9.src}
                alt={Images.subSelection9.alt}
              />
              9 Month Subscription
            </Link>
          </th>
          <th>
            <Link to='/products/twelve-month-pregnancy-subscription-box'>
              <Image
                src={Images.subSelection12.src}
                alt={Images.subSelection12.alt}
              />
              12 Month Subscription
            </Link>
          </th>
          <th>
            <Link to='/products/month-to-month-bump'>
              <Image
                src={Images.subSelectionMonthly.src}
                alt={Images.subSelectionMonthly.alt}
              />
              Month to Month Subscription
            </Link>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className='featureColumn'>
            FREE Gift in First Box
            <p>Choose Your Gift at Checkout</p>
          </th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>-</td>
        </tr>
        <tr>
          <th className='featureColumn'>Discount on First Box</th>
          <td>50% Off</td>
          <td>40% Off</td>
          <td>30% Off</td>
          <td>-</td>
        </tr>
        <tr>
          <th className='featureColumn'>
            5-8 Products in Each Box
            <p>($60+ Value/Box)</p>
          </th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <th className='featureColumn'>Tailored to Your Due Date</th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <th className='featureColumn'>
            Transitions to Baby Box Once Baby is Born
          </th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <th className='featureColumn'>Exclusive Add-Ons</th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <th className='featureColumn'>Free Shipping</th>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <th className='featureColumn'>Value</th>
          <td>$360+</td>
          <td>$540+</td>
          <td>$720+</td>
          <td>$60+</td>
        </tr>
      </tbody>
    </Table>
  );
}
