import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

let tooltipContent = '';

function renderTooltip(tooltipProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id='button-tooltip' {...tooltipProps}>
      {tooltipContent}
    </Tooltip>
  );
}

const TooltipComponent = (props) => {
  tooltipContent = props.tooltipContent;

  return (
    <OverlayTrigger
      placement={props.placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <FontAwesomeIcon className='questionsCircle' icon={props.icon} />
    </OverlayTrigger>
  );
};

export default React.memo(TooltipComponent);
