import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './style.css';

let intervalId;

class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: '00',
      seconds: '00',
    };
  }

  componentDidMount() {
    const timerInStorage = localStorage.getItem(this.props.timerName);

    if (timerInStorage && timerInStorage !== '0') {
      intervalId = setInterval(this.updateTimer, 1000);
    }
    if (!timerInStorage || timerInStorage === '0') {
      localStorage.setItem(this.props.timerName, this.props.startTimeInSeconds);
      intervalId = setInterval(this.updateTimer, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }

  secondsToMinutesAndSeconds(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds - minutes * 60).toFixed(0);
    this.setState({
      minutes: (minutes < 10 ? '0' : '') + minutes,
      seconds: (seconds < 10 ? '0' : '') + seconds,
    });
  }

  updateTimer = () => {
    let timer = localStorage.getItem(this.props.timerName);
    if (timer && timer === '0') {
      timer = 0;
      clearInterval(intervalId);
      localStorage.removeItem(this.props.timerName);
      const isTimerUp = true;
      this.props.timersUpAction(isTimerUp);
    } else if (timer && timer > 0) {
      timer = parseInt(timer, 10) - 1;
      localStorage.setItem(this.props.timerName, timer);
    }
    this.secondsToMinutesAndSeconds(timer);
    return timer;
  };

  render() {
    return this.props.displayTimer ? (
      <div className='countdownContainer'>
        {this.props.onExit ? (
          <Button
            autoFocus
            onClick={this.props.onExit}
            variant='incognito'
            className='modal-title-item float-right link'
            aria-label='Close window'
          >
            <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
          </Button>
        ) : null}
        <Row className='timerRow'>
          <Col xs={12} sm={12} md={4} lg={4} className='timerCol'>
            <span>
              <span className='timer minutes'>{this.state.minutes}</span>
              <span className='minutesText'>MIN</span>
            </span>
            :
            <span>
              <span className='timer seconds'>{this.state.seconds}</span>
              <span className='secondsText'>SEC</span>
            </span>
          </Col>
        </Row>
      </div>
    ) : null;
  }
}

export default Countdown;
