import Environment from '../services/Environment';

const { store } = Environment;
const allImages = {};

if (store === 'bitsy') {
  import('./bitsy').then((res) => {
    Object.assign(allImages, res.default);
  });
}

if (store === 'wtebump') {
  import('./wtebump').then((res) => {
    Object.assign(allImages, res.default);
  });
}

if (store === 'pnmbump') {
  import('./pnmbump').then((res) => {
    Object.assign(allImages, res.default);
  });
}

const commonImages = {
  imageEditor: {
    imageEditorTemplateDPA1: {
      src: require('./image-editor/DPATemplate1.jpg'),
      name: 'Letterboard Template 1',
      exportFileName: 'Letterboard1',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 290, y: 344 },
      bottomRightTextBox: { x: 984, y: 1018 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate1-thumb.jpg'),
      },
    },
    imageEditorTemplateDPA2: {
      src: require('./image-editor/DPATemplate4.jpg'),
      name: 'Letterboard Template 2',
      exportFileName: 'Letterboard2',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 481, y: 527 },
      bottomRightTextBox: { x: 1144, y: 1124 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate4-thumb.jpg'),
      },
    },
    imageEditorTemplateDPA3: {
      src: require('./image-editor/DPATemplate2.jpg'),
      name: 'Letterboard Template 3',
      exportFileName: 'Letterboard3',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 311, y: 301 },
      bottomRightTextBox: { x: 1100, y: 1050 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate2-thumb.jpg'),
      },
    },
    imageEditorTemplateDPA4: {
      src: require('./image-editor/DPATemplate7.jpg'),
      name: 'Letterboard Template 4',
      exportFileName: 'Letterboard4',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 559, y: 367 },
      bottomRightTextBox: { x: 1172, y: 958 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate7-thumb.jpg'),
      },
    },
    imageEditorTemplateDPA5: {
      src: require('./image-editor/DPATemplate8.jpg'),
      name: 'Letterboard Template 5',
      exportFileName: 'Letterboard5',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 318, y: 409 },
      bottomRightTextBox: { x: 1166, y: 1252 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate8-thumb.jpg'),
      },
    },
    imageEditorTemplateDPA6: {
      src: require('./image-editor/DPATemplate10.png'),
      name: 'Letterboard Template 6',
      exportFileName: 'Letterboard6',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 366, y: 460 },
      bottomRightTextBox: { x: 1129, y: 1225 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate10-thumb.png'),
      },
    },
    imageEditorTemplateDPA7: {
      src: require('./image-editor/DPATemplate11.png'),
      name: 'Letterboard Template 7',
      exportFileName: 'Letterboard7',
      alt: 'Template',
      defaultFontColor: '#E3E3DB',
      width: 1500,
      height: 1500,
      topLeftTextBox: { x: 328, y: 327 },
      bottomRightTextBox: { x: 1184, y: 1171 },
      casingOverride: 'uppercase',
      thumb: {
        src: require('./image-editor/DPATemplate11-thumb.png'),
      },
    },
  },
  babyNames: {
    landing: {
      src: require('./baby-names/babyNameMatcherLanding.png'),
      alt: 'Picking a baby name made easier!',
    },
  },
  dueDateCalculator: {
    src: require('./due-date-calc/due-date-christine.png'),
    alt: 'CEO Christine holding a Bump Box while pregnant',
  },
  yourBabyReport: {
    src: require('./due-date-calc/your-baby-report.png'),
    alt: 'Letter tiles spelling out We Are Having a Baby',
  },
  testimonial1: {
    src: require('./bump/testimonials/testimonial-1.jpg'),
    alt: 'Couple holding bump box and dad box',
  },
  testimonial2: {
    src: require('./bump/testimonials/testimonial-2.jpg'),
    alt: 'Front view of an opened bump box with items inside',
  },
  testimonial3: {
    src: require('./bump/testimonials/testimonial-3.jpg'),
    alt: 'Couple waving and holding bump box as well as dad box',
  },
  testimonial4: {
    src: require('./bump/testimonials/testimonial-4.jpg'),
    alt: 'Person holding dad box items',
  },
  manifesto: require('./manifesto/manifesto-hero.jpg'),
  bumpBoxImg: require('./bump/logos/bumpLogo.png'),
  bitsyGray: require('./nav-images/bitsyLogoGray.png'),
  bbLogo: require('./nav-images/bumpBoxLogo.png'),
  aboutHero: require('./about-us/aboutHero.png'),
  aboutHeroMobile: require('./about-us/aboutHeroMobile.png'),
  registryHero: require('./registry/BumpBoxes_Web_Registry_Webslider_Desktop.png'),
  registryHeroMobile: require('./registry/BumpBoxes_Web_Registry_Webslider_Mobile.png'),
  registryBox: require('./registry/BumpBoxes_Registry_ClosedBox.png'),
  seenBump: {
    src: require('./bump/as-seen-in/thebump.png'),
    webp: require('./bump/as-seen-in/thebump.png'),
  },
  seenBuzzfeed: {
    src: require('./bump/as-seen-in/buzzfeed.png'),
    webp: require('./bump/as-seen-in/buzzfeed.png'),
  },
  seenInStyle: {
    src: require('./bump/as-seen-in/instyle.png'),
    webp: require('./bump/as-seen-in/instyle.png'),
  },
  seenScarymommy: {
    src: require('./bump/as-seen-in/scarymommy.png'),
    webp: require('./bump/as-seen-in/scarymommy.png'),
  },
  seenPregnancyandnewborn: {
    src: require('./bump/as-seen-in/pregnancyandnewborn.png'),
    webp: require('./bump/as-seen-in/pregnancyandnewborn.png'),
  },
  seenWhattoexpect: {
    src: require('./bump/as-seen-in/whattoexpect.png'),
    webp: require('./bump/as-seen-in/whattoexpect.png'),
  },
  seenProjectNursery: {
    src: require('./bump/as-seen-in/projectnursery.png'),
    webp: require('./bump/as-seen-in/projectnursery.png'),
  },
  seenAmeda: {
    src: require('./breast-pump/logosGrayscale/grayscale-ameda.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-ameda.png'),
  },
  seenArdo: {
    src: require('./breast-pump/logosGrayscale/grayscale-ardo.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-ardo.png'),
  },
  seenElvie: {
    src: require('./breast-pump/logosGrayscale/grayscale-elvie.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-elvie.png'),
  },
  seenEvenflo: {
    src: require('./breast-pump/logosGrayscale/grayscale-evenflo.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-evenflo.png'),
  },
  seenMedela: {
    src: require('./breast-pump/logosGrayscale/grayscale-medela.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-medela.png'),
  },
  seenLansinoh: {
    src: require('./breast-pump/logosGrayscale/grayscale-lansinoh.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-lansinoh.png'),
  },
  seenMotif: {
    src: require('./breast-pump/logosGrayscale/grayscale-motif.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-motif.png'),
  },
  seenSpectra: {
    src: require('./breast-pump/logosGrayscale/grayscale-spectra.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-spectra.png'),
  },
  seenWillow: {
    src: require('./breast-pump/logosGrayscale/grayscale-willow.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-willow.png'),
  },
  seenMomcozy: {
    src: require('./breast-pump/logosGrayscale/grayscale-momcozy.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-momcozy.png'),
  },
  seenCimilre: {
    src: require('./breast-pump/logosGrayscale/grayscale-cimilre.png'),
    webp: require('./breast-pump/logosGrayscale/grayscale-cimilre.png'),
  },
  wobLogo: require('./badges/WOB-logo.png'),
  incLogo: require('./badges/Inc5000-logo.png'),
  careersHero: require('./careers/careers-hero.png'),
  careersHeroMobile: require('./careers/careers-hero-mobile.png'),
  pressHero: require('./press/press-hero.png'),
  pressHeroMobile: require('./press/press-hero-mobile.png'),
  giveawayModalImage: require('./bump/giveaway/giveaway-modal.jpg'),
  giveawayHeroImage: require('./bump/giveaway/giveaway-hero.jpg'),
  referralSharingModalImage: require('./referral-sharing/referral-sharing-popup.jpg'),
  extensionsHero: require('./extensions/extensions-hero.png'),
  prenatalVitamins: {
    prenatalVitaminsBanner: require('./prenatal-vitamins/prenatalLandingPageBanner.jpg'),
    prenatalVitaminsBanner2: require('./prenatal-vitamins/prenatalLandingPageBanner2.jpg'),
    metaImage: {
      src: require('./prenatal-vitamins/prenatalLandingPageWebsliderMobile.png'),
    },
    bottleImage: {
      src: require('./prenatal-vitamins/prenatalFrontBottle.png'),
    },
    howItWorks: [
      {
        src: require('./prenatal-vitamins/prenatalLandingPageHowItWorksStep1.png'),
        alt:
          'Prenatal Vitamins How It Works Step 1 - dark pink cell phone icon on top of lighter pink circle',
        text: 'Order your affordable \n prenatal vitamins.',
      },
      {
        src: require('./prenatal-vitamins/prenatalLandingPageHowItWorksStep2.png'),
        alt:
          'Prenatal Vitamins How It Works Step 2 - pink package icon on top of lighter pink circle.',
        text: 'Get prenatals shipped \n to your door.',
      },
      {
        src: require('./prenatal-vitamins/prenatalLandingPageHowItWorksStep3.png'),
        alt:
          'Prenatal Vitamins How It Works Step 3 - vitamin icon on stop of pink circle.',
        text: 'Stay \n nourished.',
      },
    ],
    testimonials: [
      {
        src: require('./prenatal-vitamins/prenatalLandingPageTestimonial1.png'),
        alt: 'Testimonial 1 - vitamin bottle spilling onto pink satan.',
        text: `"Please do something incredible for your holistic well-being and purchase these vitamins.
        They have impacted me so positively, and I look forward to continue staying a Bump Boxes supporter
         for many years to come."`,
      },
      {
        src: require('./prenatal-vitamins/prenatalLandingPageTestimonial2.png'),
        alt:
          'Testimonial 2 - vitamin bottle spill onto pick square with white flowers around it.',
        text: `"Yes to health and wellness! I've been looking for a great multi for many years,
        and I have finally found one that can grow with me throughout all stages in my life."`,
      },
      {
        src: require('./prenatal-vitamins/prenatalLandingPageTestimonial3.png'),
        alt:
          'Testimonial 3 - hand holding a prenatal vitams bottle in front of pink background',
        text: `"I appreciate knowing more about where my vitamins are sourced and why.
        I appreciate the choices about how and where to source the ingredients are evidence-based.
        Their customer service is the best!"`,
      },
    ],
  },
  cancellationOffer: {
    src: require('./cancellation-flow/cancellation-offer-modal.jpg'),
    webp: require('./cancellation-flow/cancellation-offer-modal.webp'),
    alt: 'Mom and baby sitting with their Bitsy box',
  },
};

const bumpImages = {
  logo: {
    src: require('./bump/logos/bumpLogo.png'),
    webp: require('./bump/logos/bumpLogo.webp'),
    alt: 'Bump Boxes Logo',
  },
  mobileLogo: {
    src: require('./bump/logos/BumpBoxes_secondary-mark_black_200x50-02.png'),
    webp: require('./bump/logos/BumpBoxes_secondary-mark_black_200x50-02.webp'),
    alt: 'Bump Boxes Logo',
  },
  guarantee: require('./bump/logos/guarantee.png'),
  brownBoxImg: {
    src: require('./bump/brown-box/brownBox.png'),
    alt: 'Bump Box inside discreet brown box packaging',
  },
  giftBagImg: {
    src: require('./bump/brown-box/giftBag.png'),
    alt: 'Bump Box gift bag packaging',
  },
  all3GwpImg: {
    src: require('./bump/gwp/all3gwp.png'),
    alt: 'I want all 3 Gifts!',
  },
  giftMessageImg: {
    src: require('./bump/brown-box/giftMessage.png'),
    alt: 'Bump Box Gift Message',
  },
  pregJournalImg: {
    src: require('./bump/brown-box/pregJournal.jpg'),
    alt: 'Bump Box gift bag packaging',
  },
  footer: {
    propertyLogo: require('./bump/logos/BumpBoxes_secondary-mark_black_200x50-02.png'),
  },
  collageImage1: {
    src: require('./bump/collage/social1.jpg'),
    webp: require('./bump/collage/social1.webp'),
    alt: 'Woman holding an open Bump Box on her left side',
  },
  collageImage2: {
    src: require('./bump/collage/social2.jpg'),
    webp: require('./bump/collage/social2.webp'),
    alt: 'Couple kissing with open Bump Box and Dad Box on their laps',
  },
  collageImage3: {
    src: require('./bump/collage/social3.jpg'),
    webp: require('./bump/collage/social3.webp'),
    alt: 'Woman standing and holding up open Bump Box on her right side',
  },
  collageImage4: {
    src: require('./bump/collage/social4.jpg'),
    webp: require('./bump/collage/social4.webp'),
    alt: 'Woman sitting and showing open Bump Box to the front',
  },
  collageImage5: {
    src: require('./bump/collage/social5.jpg'),
    webp: require('./bump/collage/social5.webp'),
    alt:
      'Top down view of open Bump Box with partial view of pregnant person sitting on the bed',
  },
  collageImage6: {
    src: require('./bump/collage/social6.jpg'),
    webp: require('./bump/collage/social6.webp'),
    alt: 'Woman sitting and holding open Bump Box in front of her',
  },
  collageImage7: {
    src: require('./bump/collage/social7.jpg'),
    webp: require('./bump/collage/social7.webp'),
    alt: 'Woman standing and holding open Bump Box to her left side',
  },
  collageImage8: {
    src: require('./bump/collage/social8.jpg'),
    webp: require('./bump/collage/social8.webp'),
    alt: 'Woman standing and holding open Bump Box to her right side',
  },
  giftCard: require('./bump/gift-card/GiftCard.png'),
  chooseYourPlan: {
    src: require('./bump/how-it-works/how-it-works_step1.svg'),
    alt: 'Choose Your Plan cartoon image of subscription box',
  },
  tailorToDate: {
    src: require('./bump/how-it-works/how-it-works_step2.svg'),
    alt: 'Enjoy Pregnancy cartoon image of calendar',
  },
  receiveYourBox: {
    src: require('./bump/how-it-works/how-it-works_step3.svg'),
    alt: 'Get Your Box cartoon image of mailbox',
  },
  aLookInside: {
    src: require('./bitsy/look-inside/Bitsy_Look_Inside.jpg'),
    webp: require('./bitsy/look-inside/Bitsy_Look_Inside.webp'),
    alt: 'Opened Bitsy Box showing individual products',
  },
  premiumUpgrade: require('./bump/product-images/premium-upgrade.jpg'),
  subBoxImg: require('./bump/product-images/subscriptionimg.png'),
  subHighLight: require('./bump/icon/Bump-Hearts-02.png'),
  subSelectionMonthly: {
    src: require('./bump/select-subscription/Month_To_Month_Monthly_Subscription.png'),
    webp: require('./bump/select-subscription/Month_To_Month_Monthly_Subscription.webp'),
    alt: 'Bump Boxes monthly plan',
  },
  subSelection3: {
    src: require('./bump/select-subscription/3_Monthly_Subscription.png'),
    webp: require('./bump/select-subscription/3_Monthly_Subscription.webp'),
    alt: 'Bump Boxes three month plan ',
  },
  subSelection6: {
    src: require('./bump/select-subscription/6_Monthly_Subscription.png'),
    webp: require('./bump/select-subscription/6_Monthly_Subscription.webp'),
    alt: 'Bump Boxes six month plan',
  },
  subSelection9: {
    src: require('./bump/select-subscription/9_Monthly_Subscription.png'),
    webp: require('./bump/select-subscription/9_Monthly_Subscription.webp'),
    alt: 'Bump Boxes nine month plan',
  },
  subSelection12: {
    src: require('./bump/select-subscription/12_Monthly_Subscription.png'),
    webp: require('./bump/select-subscription/12_Monthly_Subscription.webp'),
    alt: 'Bump Boxes twelve month plan',
  },
  prenatalPlan: {
    src: require('./bump/select-subscription/Prenatals_FrontBottle.png'),
    webp: require('./bump/select-subscription/Prenatals_FrontBottle.webp'),
    alt: 'Bump Boxes prenatal vitamins plan',
  },
  noProducts: require('./bitsy/shop/no_products.jpg'),
  bumpHealthLogo: require('./bump/corporate-portal/logo-bumphealth.png'),
  wspRewardsLogo: require('./bump/corporate-portal/logo-rewards-at-wsp.png'),
  wspLiveWellLogo: require('./bump/corporate-portal/logo-wsp-live-well.png'),
  homescreenSlider: require('./bump/hero/bumpboxes-webslider-wall-min.jpg'),
  homescreenSliderMobile: require('./bump/hero/homepage_webslider-mobile-wall-min.jpg'),
  referAFriend: require('./bump/hero/bump-raf-background.jpg'),
  press: require('./bump/press/press-page_webslider-03.png'),
  pastBox1: {
    src: require('./bump/past-boxes/Store_Image_1.jpg'),
    webp: require('./bump/past-boxes/Store_Image_1.webp'),
    alt: 'Opened Bump Box showing products including Glow bath soak',
  },
  pastBox2: {
    src: require('./bump/past-boxes/Store_Image_2.jpg'),
    webp: require('./bump/past-boxes/Store_Image_2.webp'),
    alt:
      'Opened Bump Box showing seven previous products including You Are My Sunshine onesie',
  },
  pastBox3: {
    src: require('./bump/past-boxes/Store_Image_3.jpg'),
    webp: require('./bump/past-boxes/Store_Image_3.webp'),
    alt:
      'Opened Bump Box showing seven previous products including Tired as a Mother mug',
  },
  pastBox4: {
    src: require('./bump/past-boxes/Store_Image_4.jpg'),
    webp: require('./bump/past-boxes/Store_Image_4.webp'),
    alt:
      'Opened Bump Box showing seven previous products including Glow bath bomb',
  },
  pastBox5: {
    src: require('./bump/past-boxes/Store_Image_5.jpg'),
    webp: require('./bump/past-boxes/Store_Image_5.webp'),
    alt:
      'Opened Bump Box showing seven previous products including Snug Life onesie',
  },
  pastBox6: {
    src: require('./bump/past-boxes/Store_Image_6.jpg'),
    webp: require('./bump/past-boxes/Store_Image_6.webp'),
    alt:
      'Opened Bump Box showing seven previous products including Feeling Fly water bottle',
  },
  giftboxBoy: {
    src: require('./bitsy/gift_boxes/Newborn-Gift-Box-Boy.png'),
    webp: require('./bitsy/gift_boxes/Newborn-Gift-Box-Boy.webp'),
    alt: 'Boy Gift Box',
  },
  portalDefault: {
    desktop: require('./bump/corporate-portal/portal_default_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_default_mobile.png'),
    alt: 'Coprorate portal general office',
  },
  'mariner-wellness-portal': {
    desktop: require('./bump/corporate-portal/portal_mariner_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_mariner_mobile.png'),
    alt: 'Mariner store',
  },
  cvs: {
    desktop: require('./bump/corporate-portal/portal_cvs_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_cvs_mobile.png'),
    alt: 'CVS store',
  },
  mcdonalds: {
    desktop: require('./bump/corporate-portal/portal_mcdonalds_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_mcdonalds_mobile.png'),
    alt: 'McDonalds store',
  },
  walgreens: {
    desktop: require('./bump/corporate-portal/portal_walgreens_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_walgreens_mobile.png'),
    alt: 'Walgreens store',
  },
  'trane-wellness-portal': {
    desktop: require('./bump/corporate-portal/portal_trane_desktop.webp'),
    mobile: require('./bump/corporate-portal/portal_trane_mobile.png'),
    alt: 'Trane store',
  },
  'honeywell-wellness-portal': {
    desktop: require('./bump/corporate-portal/portal_honeywell_desktop.png'),
    mobile: require('./bump/corporate-portal/portal_honeywell_mobile.png'),
    alt: 'Honeywell store',
  },
  pdpSubscriptionBlock: {
    src: require('./bump/productPage/pdpSubscriptionBlock.png'),
    // webp: require('./bump/logos/pdpSubscriptionBlock.webp'),
    alt: 'Bump Boxes Subscription',
  },
};

Object.assign(allImages, commonImages);
if (store === 'bump') {
  Object.assign(allImages, bumpImages);
}
export default allImages;
