import React from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const BitsyButton = props => (
  <Route render={({ history }) => (
    <Button
      type='button'
      onClick={() => props.onClick(history)}
    >
      Click Me!
    </Button>
  )} />
);

export default BitsyButton;
