import DateForm from './date-form';
import EmailInput from './email-input';
import CreditCard from './credit-card';
import CtaButton from './cta-button';
import BitsyButton from './bitsy-button';
import FieldGroup from './field-group';
import Loading from './loading';
import SubscriptionSignupHeader from './subscription-signup-header';
import RegularHeader from './regular-header';
import withError from './generic/HOCs/withError';
import withTracker from './with-tracker';
import WebpImage from './webp-image';
import VisibilityPlaceholder from './visibility-placeholder';
import UpsellBox from './upsell-box';
import SelectSubscription from './select-subscription';
import DisplayCheckboxesOrNot from './terms-and-signUp-checkboxes';
import ProductItem from './product-item';
import Countdown from './countdown';
import FilterDropdown from './filter-dropdown';
import ProgressCircle from './progress-circle';
import ProgressBarCheckout from './progress-bar-checkout';
import Tooltip from './tooltip';
import ShippingInsurance from './shipping-insurance';
import SuggestedAddresses from './suggested-addresses';
import SMSOptIn from './sms-opt-in';
import PreCheckoutPopup from './preCheckout-popup';
import CustomizeSubDropdown from './customize-sub-dropdown';
import ProductDetailModal from './product-details-modal';
import Hero from './hero';
import CartCountdownTimer from './cart-countdown-timer';
import CrossPromoBlock from './cross-promo-block';
import SubComparisonTable from './subscription-comparison-table';
import CompanyLocations from './company-locations';

import {
  SignInOrSignupForm,
  AddressInput,
  BitsyModal,
  SignUpForm,
  SignInForm,
  CouponAndGiftCardForm,
  Cart,
  Testimonials,
  HomepageManifesto,
  AsSeenIn,
  GetTheBox,
  HowItWorks,
  SocialProof,
  WteFooter,
  PnmagFooter,
  Footer,
  AdminNav,
  CompanyNav,
  PartnershipNavigation,
  Navigation,
  CancelSurvey,
  PastSubs,
  Guarantee,
  SelectSubscriptionBoxes,
  SubscriptionDetailsForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  CorporateGiftForm,
  ContactForm,
  PartnershipsForm,
  VerticalTimeline,
  SearchForm,
  ShopPageMenu,
  BitsyMobileMenu,
  BitsyMenu,
  BumpMenu,
  RegistryMenu,
  UpdateCCForm,
  CartTotal,
  Trimesters,
  FAQAccordion,
  BreastPumpFooter,
  TopBanner,
  ReferralSharing,
  ReferralSharingCopyLink,
  ConfirmPasswordForm,
  PregnancyAnnouncementIdeas,
  TypeaheadToggleButton,
  InsuranceCardUpload,
} from './splitComponents';

import { StructuredData, Breadcrumbs } from './structured-data';

const components = {
  SignInForm,
  SignUpForm,
  DateForm,
  AddressInput,
  EmailInput,
  CreditCard,
  CtaButton,
  BitsyButton,
  BitsyModal,
  FieldGroup,
  Loading,
  SignInOrSignupForm,
  CouponAndGiftCardForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  Footer,
  CorporateGiftForm,
  ContactForm,
  PartnershipsForm,
  SubscriptionSignupHeader,
  RegularHeader,
  SearchForm,
  StructuredData,
  Breadcrumbs,
  SubscriptionDetailsForm,
  withTracker,
  withError,
  PastSubs,
  Guarantee,
  WebpImage,
  SelectSubscription,
  Cart,
  Testimonials,
  AsSeenIn,
  GetTheBox,
  HowItWorks,
  SocialProof,
  WteFooter,
  AdminNav,
  CompanyNav,
  PartnershipNavigation,
  Navigation,
  VisibilityPlaceholder,
  VerticalTimeline,
  CancelSurvey,
  CartTotal,
  UpdateCCForm,
  UpsellBox,
  ShopPageMenu,
  BitsyMobileMenu,
  BitsyMenu,
  BumpMenu,
  RegistryMenu,
  DisplayCheckboxesOrNot,
  Trimesters,
  ProductItem,
  Countdown,
  FAQAccordion,
  BreastPumpFooter,
  FilterDropdown,
  ProgressCircle,
  ProgressBarCheckout,
  Tooltip,
  ShippingInsurance,
  SuggestedAddresses,
  SMSOptIn,
  PreCheckoutPopup,
  CustomizeSubDropdown,
  ProductDetailModal,
  TopBanner,
  Hero,
  CartCountdownTimer,
  CrossPromoBlock,
  PregnancyAnnouncementIdeas,
  SubComparisonTable,
  CompanyLocations,
  TypeaheadToggleButton,
  InsuranceCardUpload,
};

export default components;
export {
  SignInForm,
  SignUpForm,
  DateForm,
  AddressInput,
  EmailInput,
  CreditCard,
  CtaButton,
  BitsyButton,
  BitsyModal,
  FieldGroup,
  Loading,
  SignInOrSignupForm,
  CouponAndGiftCardForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  Footer,
  CorporateGiftForm,
  ContactForm,
  PartnershipsForm,
  SubscriptionSignupHeader,
  RegularHeader,
  SearchForm,
  StructuredData,
  Breadcrumbs,
  SubscriptionDetailsForm,
  withTracker,
  withError,
  PastSubs,
  Guarantee,
  WebpImage,
  SelectSubscription,
  SelectSubscriptionBoxes,
  Cart,
  Testimonials,
  HomepageManifesto,
  AsSeenIn,
  GetTheBox,
  HowItWorks,
  SocialProof,
  WteFooter,
  PnmagFooter,
  AdminNav,
  CompanyNav,
  PartnershipNavigation,
  Navigation,
  VisibilityPlaceholder,
  VerticalTimeline,
  CancelSurvey,
  UpdateCCForm,
  CartTotal,
  UpsellBox,
  BitsyMenu,
  BumpMenu,
  RegistryMenu,
  ShopPageMenu,
  BitsyMobileMenu,
  DisplayCheckboxesOrNot,
  Trimesters,
  ProductItem,
  Countdown,
  FAQAccordion,
  BreastPumpFooter,
  FilterDropdown,
  ProgressCircle,
  ProgressBarCheckout,
  Tooltip,
  ShippingInsurance,
  SuggestedAddresses,
  SMSOptIn,
  PreCheckoutPopup,
  CustomizeSubDropdown,
  ProductDetailModal,
  TopBanner,
  Hero,
  CartCountdownTimer,
  ReferralSharing,
  ReferralSharingCopyLink,
  ConfirmPasswordForm,
  CrossPromoBlock,
  PregnancyAnnouncementIdeas,
  SubComparisonTable,
  CompanyLocations,
  TypeaheadToggleButton,
  InsuranceCardUpload,
};
