import Environment from '../services/Environment';
import Images from '../images';

const store = Environment.store || 'bitsy';

const isPartnerSite = store === 'wtebump' || store === 'pnmbump';
const isBitsy = store === 'bitsy';

// Sets any content with 3+ options
let namesUrlWithoutHttps;
let namesNameNoSpaces;
let homeTitle;
let homeDescription;
let metaDefaultTitlePrefix;
let metaDefaultTitleSuffix;
let metaDefaultDescriptionPrefix;
let metaDefaultDescriptionSuffix;
let subscriptionPlanSelectionTitle;
let subscriptionPlanSelectionDescription;
if (isPartnerSite) {
  namesUrlWithoutHttps = `${
    store === 'wtebump' ? 'whattoexpect' : 'pnmag'
  }.bumpboxes.com`;
  namesNameNoSpaces =
    store === 'wtebump' ? 'WTEandBumpBoxes' : 'PNMagAndBumpBoxes';
  homeTitle = 'Monthly products for an enjoyable pregnancy';
  homeDescription = `${
    store === 'wtebump'
      ? 'What To Expect has partnered with Bump Boxes to bring you'
      : 'You get'
  } must-have products tailored to your due date and designed to pamper you throughout pregnancy!`;
  metaDefaultTitlePrefix =
    'Pregnancy Gift Baskets, Best Gifts For Expecting Mothers';
  metaDefaultTitleSuffix = `${
    store === 'wtebump' ? 'WTE' : 'PNMag'
  } + Bump Boxes`;
  metaDefaultDescriptionPrefix =
    'The #1 pregnancy subscription box for an expecting mom. Monthly deliveries of pregnancy products for every stage of your pregnancy tailored to your due date!';
  metaDefaultDescriptionSuffix = ' Give Mama a Bump Box!';
  subscriptionPlanSelectionTitle = "You've got a lot on your plate.";
  subscriptionPlanSelectionDescription =
    'Monthly deliveries of healthy pregnancy, mom and baby products for every stage of your pregnancy tailored to your due date!';
} else if (isBitsy) {
  namesUrlWithoutHttps = 'bitsyboxes.com';
  namesNameNoSpaces = 'BitsyBoxes';
  homeTitle = 'Make Growing Up Fun';
  homeDescription =
    'Monthly delivery of products tailored to your little one’s age 0-3 years old';
  metaDefaultTitlePrefix =
    'New Mom Gift Baskets, Best Gifts For New Mothers, Baby Box';
  metaDefaultTitleSuffix = ' | Bitsy Boxes';
  metaDefaultDescriptionPrefix =
    'Bitsy Box is a monthly after birth care package for new mothers! Looking for what to get a new mom? Bitsy is the best gift to give to your post pregnant wife, best gifts for newborn, baby essentials first time mom.';
  metaDefaultDescriptionSuffix = ' Give Mama a Bitsy Box!';
  subscriptionPlanSelectionTitle = 'Choose Your Subscription Plan';
  subscriptionPlanSelectionDescription =
    "Monthly deliveries of fun mom and baby products for every milestone from due date to baby's third birthday!";
} else {
  namesUrlWithoutHttps = 'bumpboxes.com';
  namesNameNoSpaces = 'BumpBoxes';
  homeTitle = 'Celebrate Pregnancy';
  homeDescription =
    'Safe & healthy products for expecting moms delivered monthly';
  metaDefaultTitlePrefix = 'The #1 Pregnancy Subscription Box';
  metaDefaultTitleSuffix = ' | Bump Boxes';
  metaDefaultDescriptionPrefix =
    'Protect your pregnant belly with pregnancy products from Earth Mama, Puracy, and Pearhead.';
  metaDefaultDescriptionSuffix = ' Give Mama a Bump Box!';
  subscriptionPlanSelectionTitle = 'Pregnancy Subscription';
  subscriptionPlanSelectionDescription =
    'You want safe products to protect your baby during pregnancy, but so many products have harsh chemicals in them. We choose every single product in our boxes carefully.';
}

const content = {
  names: {
    helpEmail: isBitsy ? 'hello@bitsyboxes.com' : 'info@bumpboxes.com',
    dataProtectionOfficerEmail: 'dpo@bumpboxes.com',
    socialMediaLinks: {
      instagram: `https://www.instagram.com/${isBitsy ? 'bitsy' : 'bump'}boxes`,
      facebook: `https://www.facebook.com/${isBitsy ? 'Bitsy' : 'Bump'}Boxes`,
      twitter: 'https://twitter.com/bumpboxes',
      pinterest: 'https://www.pinterest.com/bumpboxes',
      linkedin: 'https://www.linkedin.com/company/bump-boxes',
      youtube: 'https://www.youtube.com/channel/UC-REq4CAc5aH9mA_MkE7oog',
    },
    shortName: isBitsy ? 'Bitsy' : 'Bump Boxes',
    singleName: isBitsy ? 'Bitsy' : 'Bump',
    nameNoSpaces: namesNameNoSpaces,
    fancyNameSingular: isBitsy ? 'Bitsy Box' : 'Bump Box',
    fancyNamePlural: isBitsy ? 'Bitsy Boxes' : 'Bump Boxes',
    mainUrl: `https://${namesUrlWithoutHttps}`,
    urlWithoutHttps: namesUrlWithoutHttps,
    oppositeMainUrl: isBitsy
      ? 'https://bumpboxes.com'
      : 'https://bitsyboxes.com',
  },
  home: {
    title: homeTitle,
    description: homeDescription,
  },
  meta: {
    defaultTitlePrefix: metaDefaultTitlePrefix,
    defaultTitleSuffix: metaDefaultTitleSuffix,
    defaultDescriptionPrefix: metaDefaultDescriptionPrefix,
    defaultDescriptionSuffix: metaDefaultDescriptionSuffix,
    defaultImg: '/metaImg.jpg',
  },
  referAFriendMeta: {
    defaultTitlePrefix: 'Get a FREE box',
    defaultDescription:
      'Get your 1st box FREE when you use my link & subscribe to the #1 pregnancy subscription box! Every single item is always pregnancy-safe & ships free!',
  },
  subscriptionPlanSelection: {
    title: subscriptionPlanSelectionTitle,
    description: subscriptionPlanSelectionDescription,
  },
  testimonials: {
    title: `What Our ${isBitsy ? 'Parents' : 'Moms'} and Gifters are Saying`,
    cards: isBitsy
      ? [
          {
            content:
              'We love Bitsy Boxes!!! My son is 2 months old and this is his second box and all the goodies that come monthly are set for his age group!',
            name: 'Rosemary',
          },
          {
            content:
              'We just got our first bitsy box and I don’t know who’s more in love, my daughter or me!',
            name: 'Sarah',
          },
          {
            content:
              'Bitsy boxes have products tailored to your baby’s age. Noah had so much fun going through each item. Go check them out!!',
            name: 'Jessica',
          },
          {
            content:
              'I got the bitsy box out of curiosity for my two month old daughter. We got our first box yesterday and I really loved it. Can’t wait for our next box.',
            name: 'Denise',
          },
        ]
      : [
          {
            content:
              'I surprised my wife and soon to be mother of our baby boy Parker James with these monthly care packages and she looks forward to them more and more every month.',
            name: 'Cayla',
            image: {
              src: Images.testimonial1.src,
              alt: Images.testimonial1.alt,
            },
          },
          {
            content:
              'My husband gifted me Bump Boxes and I look forward to my box every single month! They include quality products that I didn’t even know I needed/wanted half of the time! I love it.. can I stay pregnant forever??',
            name: 'Thoren',
            image: {
              src: Images.testimonial2.src,
              alt: Images.testimonial2.alt,
            },
          },
          {
            content:
              'As you can see the kids were {{{thrilled}}} with their Bump Boxes! It’s the first child for them, and the first grandchild for us… we are all over the moon! What a really thoughtful gift for the expectant couple!! I highly recommend this exceptional company!',
            name: 'Kate',
            image: {
              src: Images.testimonial3.src,
              alt: Images.testimonial3.alt,
            },
          },
          {
            content:
              'My Son and DIL Love their boxes! Meant So much for my son to get a Daddy box and be included!',
            name: 'Melissa',
            image: {
              src: Images.testimonial4.src,
              alt: Images.testimonial4.alt,
            },
          },
        ],
  },
  getTheBox: {
    title: isBitsy
      ? 'Discover Safe Products for Your Little One'
      : 'Over 1,000,000 Pregnancy-Safe Boxes Shipped to Moms',
  },
  aLookInsideTheSubscription: { title: 'A Look Inside the Subscription' },
  subscriptionDetails: {
    dateShort: isBitsy ? 'Birthday' : 'Due Date',
    date: isBitsy ? "What is Baby's birthday?" : "What is Mama's due date?",
  },
  footer: {
    copyright: isBitsy ? '© Bitsy Boxes' : '© Bump Boxes',
  },
  estimatedSavings: {
    title: 'Save time and money with Bump Boxes!',
    subtitle: 'The average subscriber saves:',
    timeSavings: 483,
    dollarSavings: 2400,
    buttonText: 'SUBSCRIBE AND SAVE',
  },
};

export default content;
