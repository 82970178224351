/* eslint-disable camelcase */
import Environment from './Environment';
import Content from '../constants/Content';
import Logger from './Logger';

const getProductImageByColor = (media, color) => {
  try {
    if (!media || !media.length) {
      return 'https://s3.amazonaws.com/bitsy-product-images/default-product-image.png';
    }
    if (!color || color === 'NONE') return media[0].url;

    const colorIndex = media.map((mediaItem) => mediaItem.color).indexOf(color);

    if (colorIndex === -1) {
      return media[0].url;
    }

    return media[colorIndex].url;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const getSubImage = (plan) => {
  try {
    if (plan.imageUrl) {
      return plan.imageUrl;
    }

    let imageUrl = '';
    if (Environment.store.includes('bump')) {
      imageUrl =
        'https://s3.amazonaws.com/bitsy-product-images/subscription-plan-images/bump/';
    } else {
      imageUrl =
        'https://s3.amazonaws.com/bitsy-product-images/subscription-plan-images/bitsy/';
    }

    if (plan.title.startsWith('Twelve Month')) {
      imageUrl += '12-month.png';
    } else if (plan.title.startsWith('Nine Month')) {
      imageUrl += '9-month.png';
    } else if (plan.title.startsWith('Six Month')) {
      imageUrl += '6-month.png';
    } else if (plan.title.startsWith('Three Month')) {
      imageUrl += '3-month.png';
    } else {
      imageUrl += 'monthly.png';
    }

    return imageUrl;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const getProductUrlFallbackForMinimumMonthInfinityPlan = (plan) => {
  if (plan.title.includes('Six')) {
    return `${Environment.getSelfDomain}/products/${
      plan.productUrlPrefix || ''
    }six-month-pregnancy-subscription-box`;
  }
  if (plan.title.includes('Nine')) {
    return `${Environment.getSelfDomain}/products/${
      plan.productUrlPrefix || ''
    }nine-month-pregnancy-subscription-box`;
  }
  if (plan.title.includes('Twelve')) {
    return `${Environment.getSelfDomain}/products/${
      plan.productUrlPrefix || ''
    }twelve-month-pregnancy-subscription-box`;
  }
  return `${Environment.getSelfDomain}/gift`;
};

const getProductUrlFallbackForMonthtoMonthPlan = (plan) => {
  return `${Environment.getSelfDomain}/products/${
    plan.productUrlPrefix || ''
  }month-to-month-bump`;
};

const getProductUrlFallbackForThreeMonthPlan = (plan) => {
  return `${Environment.getSelfDomain}/products/${
    plan.productUrlPrefix || ''
  }three-month-pregnancy-subscription-auto-renew`;
};

const getProductUrlForSubscription = (subscription) => {
  try {
    const { plan } = subscription;
    if (plan.linkUrl) {
      return plan.linkUrl;
    }

    if (plan.months === 'INFINITY' && !plan.title.includes('Month to Month')) {
      return getProductUrlFallbackForMinimumMonthInfinityPlan(plan);
    }
    if (plan.title.includes('Month to Month')) {
      return getProductUrlFallbackForMonthtoMonthPlan(plan);
    }
    if (plan.title.includes('Three Month')) {
      return getProductUrlFallbackForThreeMonthPlan(plan);
    }
    return `${Environment.getSelfDomain}/gift`;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

const getSubscriptionSku = (plan) => {
  const providerNameForSku = {
    bump: 'Bump Boxes ',
    bitsy: 'Bitsy ',
    wtebump: 'WTE Bump Boxes ',
    pnmbump: 'PNMag Bump Boxes ',
  };

  const providerName = providerNameForSku[Environment.store];

  return `${plan.title}${
    plan.months === 'INFINITY' ? ' ' : ' Prepaid '
  }${providerName}Subscription`;
};

const getDataForCart = (cart, includeViewedOn) => {
  try {
    const items = [];
    if (cart.subscription) {
      const { subscription } = cart;
      const imageUrl = getSubImage(subscription.plan);
      const productUrl = getProductUrlForSubscription(subscription);
      const ItemPrice = cart?.coupon
        ? subscription.plan.costWithCoupon
        : subscription.plan.actualCost;
      items.push({
        SKU:
          subscription.plan.sku ||
          `${subscription.plan.title} ${Content.names.shortName} Subscription`,
        ProductName:
          subscription.plan.sku ||
          `${subscription.plan.title} ${Content.names.shortName} Subscription`,
        Quantity: 1,
        ItemPrice,
        RowTotal: cart?.coupon
          ? subscription.plan.costWithCoupon
          : subscription.plan.actualCost,
        ProductURL: productUrl,
        ImageURL: imageUrl,
      });
    }
    if (cart.productsAndDetails) {
      cart.productsAndDetails.forEach((productsAndDetailsItem) => {
        const { product } = productsAndDetailsItem;
        const mediaUrl = getProductImageByColor(
          product.media,
          productsAndDetailsItem.color,
        );
        let itemPrice = product.actualCost || 0;
        if (
          productsAndDetailsItem.amountToCharge != null &&
          Number.isFinite(Number(productsAndDetailsItem.amountToCharge))
        ) {
          itemPrice = productsAndDetailsItem.amountToCharge;
        } else if (productsAndDetailsItem.isBundledGWPProduct) {
          itemPrice = product?.ownPromoData?.bundledGWPCost || 0;
        } else if (productsAndDetailsItem.isAddedCouponProduct) {
          itemPrice = product?.ownPromoData?.cost || 0;
        }
        const viewedProductData = {
          SKU: product.sku || product.name,
          ProductName: product.name,
          Quantity: productsAndDetailsItem.quantity,
          ItemPrice: itemPrice,
          ProductURL: `${
            Environment.getSelfDomain
          }/products/${product.urlName?.toLowerCase()}`,
          ImageURL: mediaUrl,
          Color:
            productsAndDetailsItem.color !== 'NONE'
              ? productsAndDetailsItem.color
              : '',
          Variation:
            productsAndDetailsItem.variation !== 'NONE'
              ? productsAndDetailsItem.variation
              : '',
          ID: product._id,
          isBreastPump: productsAndDetailsItem.isBreastPump,
        };

        if (includeViewedOn) {
          viewedProductData.ViewedOn = new Date();
        }
        items.push(viewedProductData);
      });
    }
    return items;
  } catch (e) {
    Logger.error(e);
    return e;
  }
};

// removes plus signs, dashes, parantheses, and spaces
const formatPhoneNumberOnlyNumbers = (number) => {
  if (!number) {
    return '';
  }
  return number
    .replace(/[+]/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/\s/g, '');
};

const getCountryAndAreaCodeAndNumber = (number) => {
  if (!number) {
    return {
      countryCode: '',
      areaCode: '',
      phoneNumber: '',
    };
  }
  const formattedNumber = formatPhoneNumberOnlyNumbers(number);
  if (formattedNumber.length === 11) {
    return {
      countryCode: formattedNumber.substr(0, 1),
      areaCode: formattedNumber.substr(1, 3),
      phoneNumber: formattedNumber.substr(4, 7),
    };
  }
  return {
    countryCode: '1',
    areaCode: formattedNumber.substr(0, 3),
    phoneNumber: formattedNumber.substr(3, 7),
  };
};

const getCartUrl = () => {
  return `${Environment.getSelfDomain}/guest/checkout/review`;
};

const getE164FormattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  const phoneParts = getCountryAndAreaCodeAndNumber(phoneNumber);

  return `+${phoneParts.countryCode}${phoneParts.areaCode}${phoneParts.phoneNumber}`;
};

/**
 * Returns an object with the properties of a product.
 *
 * @param {Object} product - The product object.
 * @param {number} price - The price of the product.
 * @param {number} quantity - The quantity of the product.
 * @returns {Object} - An object with the properties of the product.
 */
const getProductProperties = (product, price, quantity) => {
  return {
    sku: product.sku,
    quantity,
    name: product.name,
    brand: product.brand,
    price,
    description: product.description,
  };
};

/**
 * Retrieves the products, including subscription, for a cart.
 *
 * @param {Object} cart - The cart object.
 * @returns {Array} - An array of product properties.
 */
const getProductPropertiesFromCart = (cart) => {
  const productProperties = [];
  cart.productsAndDetails?.forEach((productsAndDetailsItem) => {
    const price =
      productsAndDetailsItem.amountToCharge ||
      productsAndDetailsItem.product.actualCost;

    productProperties.push(
      getProductProperties(
        productsAndDetailsItem.product,
        price,
        productsAndDetailsItem.quantity,
      ),
    );
  });

  if (cart.subscription) {
    const { plan } = cart.subscription;
    let price = plan.actualCost;
    if (cart.coupon) {
      if (
        cart.coupon?._id.toString() ===
        plan.couponToAutoPopulate?._id.toString()
      ) {
        price = plan.costWithCoupon;
      }
    }
    productProperties.push({
      sku: plan.sku,
      quantity: 1,
      name: plan.sku,
      brand: 'Bump Boxes',
      price,
      description: plan.sku,
    });
  }

  return productProperties;
};

/**
 * Returns an object with the properties of a product for tiktok events.
 *
 * @param {Object} product - The product object.
 * @param {number} price - The price of the product.
 * @param {number} quantity - The quantity of the product.
 * @returns {Object} - An object with the properties of the product.
 */
const getProductPropertiesForTikTok = (product, price, quantity) => {
  return {
    content_id: product.sku,
    quantity,
    content_name: product.name,
    brand: product.brand,
    price,
    description: product.description,
  };
};

/**
 * Checks if the cart is valid for subscription-only checkout.
 *
 * A cart is considered valid for subscription-only checkout if:
 * - It has a subscription.
 * - It has no products in the cart or only has GWP (Gift With Purchase) products.
 *
 * @param {Object} cart - The cart object to be validated.
 * @param {Object} cart.subscription - The subscription details of the cart.
 * @param {Array} cart.productsAndDetails - The list of products and their details in the cart.
 * @param {boolean} cart.productsAndDetails[].isAddedCouponProduct - Indicates if the product is a GWP product.
 * @returns {boolean} - Returns true if the cart is valid for subscription-only checkout, otherwise false.
 */
const isCartValidForSubscriptionOnlyCheckout = (cart) => {
  const hasSubscription = !!cart.subscription;
  const noProductsInCart = !cart.productsAndDetails?.length;
  const onlyGWPProductsInCart = cart.productsAndDetails?.every(
    (product) => product.isAddedCouponProduct,
  );
  const noInvalidProductsInCart = noProductsInCart || onlyGWPProductsInCart;
  return hasSubscription && noInvalidProductsInCart;
};

export default {
  getProductImageByColor,
  getSubImage,
  getDataForCart,
  getCountryAndAreaCodeAndNumber,
  getSubscriptionSku,
  getCartUrl,
  getProductUrlForSubscription,
  getE164FormattedPhoneNumber,
  getProductPropertiesFromCart,
  getProductPropertiesForTikTok,
  isCartValidForSubscriptionOnlyCheckout,
};
