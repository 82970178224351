// Adapted from https://stackoverflow.com/questions/42847126/script-load-in-react
const loadExternalScript = async (src, async, type, id) => {
  let asyncOrNot = true;
  let scriptType = 'text/javascript';
  if (async) {
    asyncOrNot = async;
  }
  if (type) {
    scriptType = type;
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = scriptType;
    script.async = asyncOrNot;

    if (id) {
      script.id = id;
    }

    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (e) => {
      reject(new Error(`Failed to load ${e.target.src}. ${JSON.stringify(e)}`));
    });
    document.body.appendChild(script);
  });
};

export default loadExternalScript;
