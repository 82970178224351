import Content from '../constants/Content';

/**
 * Gets the productionized url for the given path.
 *
 * @param pathname path components such as /product or /terms-of-service. Default path is "/" and refers to base url.
 */
const getCanonicalUrl = (pathname = '/') => {
  let newPath = '';

  if (!pathname || pathname === '/') {
    newPath = Content.names.mainUrl;
  } else {
    // URL encode special characters, but leave path slashes alone. Generally
    // worried about paths that contain an & from product names.
    newPath =
      Content.names.mainUrl +
      encodeURIComponent(decodeURIComponent(pathname)).replace(/%2F/g, '/');
    // The old products pages were /product/ (singular) but the new site is
    // /products/ (plural). If a bot re-crawls the old version, indicate the
    // new canonical version as plural.
    newPath = newPath.replace('/product/', '/products/');
    if (newPath.endsWith('/')) {
      newPath = newPath.slice(0, -1);
    }
  }
  return newPath.toLowerCase();
};

/**
 * Gets a properly formatted title.
 *
 * @param title The portion of the title that will be added after the prefix.
 */
const getTemplatedTitle = (title) => {
  if (!title) {
    return Content.meta.defaultTitlePrefix + Content.meta.defaultTitleSuffix;
  }
  return title + Content.meta.defaultTitleSuffix;
};

const getTemplatedDescription = (description) => {
  if (!description) {
    return (
      Content.meta.defaultDescriptionPrefix +
      Content.meta.defaultDescriptionSuffix
    );
  }
  return description + Content.meta.defaultDescriptionSuffix;
};

const getTrimmedDescription = (description) => {
  let metaDescription = '';
  if (description && description.trim().length) {
    metaDescription = description
      .replace(/(<([^>]+)>)/gi, '')
      .substring(0, 152);

    metaDescription += '...';
  }
  return metaDescription;
};

const getProductSku = (product) => {
  if (product.subscriptionPlan) {
    return product.subscriptionPlan.sku;
  }
  return product.sku;
};

export default {
  getCanonicalUrl,
  getTemplatedTitle,
  getTemplatedDescription,
  getTrimmedDescription,
  getProductSku,
};
