import React from 'react';

const StructuredData = ({ data }) => (
  <script
    type='application/ld+json'
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
  />
);

const Breadcrumbs = ({ data }) => {
  const urls = data.map((item, index) => ({
    '@type': 'ListItem',
    position: index,
    item: `https://bumboxes.com${item.url}`,
    name: item.name,
  }));

  const breadcrumb = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [urls],
  };

  return <StructuredData data={breadcrumb} />;
};

export { StructuredData, Breadcrumbs };
