import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import FieldGroup from '../field-group';
import SMSOptIn from '../sms-opt-in';
import './style.css';

const EmailInput = (props) => (
  <>
    {!props.hideEmailLabel ? (
      <p className='signUpHelperText signUpTextShipping'>
        <FontAwesomeIcon icon={faPaperPlane} className='sectionIcon' />
        {props.digitalOnlyEmailLabel
          ? 'Order updates will be sent to:'
          : 'Shipping notifications will be sent to:'}
      </p>
    ) : null}
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className='nameField emailInput'>
        <FieldGroup
          controlId={`guestEmail-${props.parent}`}
          formValuesValidationState={props.formValuesValidationState}
          hasBeenSubmitted={props.hasBeenSubmitted}
          type='email'
          parent={props.parent}
          name='email'
          value={props.formValues.email}
          handleChange={props.handleChange}
          helpText='Email is required.'
          label='Email'
          readOnly={props.readOnly}
          className={props.readOnly ? 'readOnly' : null}
        />
      </Col>
    </Row>
    {!props.hidePhoneNumber ? (
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className='addFormPadding phonenumberInput'
        >
          <FieldGroup
            controlId='phoneNumber'
            formValuesValidationState={props.formValuesValidationState}
            hasBeenSubmitted={props.hasBeenSubmitted}
            type='tel'
            name='phoneNumber'
            value={props.formValues.phoneNumber}
            handleChange={props.handleChange}
            helpText='Please enter a valid phone number.'
            label='Phone Number'
            parent={props.parent}
          />
          <p>Please enter 10 digits only, e.g. 8889137879</p>
        </Col>
      </Row>
    ) : null}
    {props.showSMSOptIn && (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <SMSOptIn
            handleSMSOptInToggle={props.handleSMSOptInToggle}
            isSmsOptInSelected={props.isSmsOptInSelected}
            giveawaySMSMessage={props.giveawaySMSMessage}
          />
        </Col>
      </Row>
    )}
  </>
);

export default EmailInput;
