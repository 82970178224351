import Auth from './Auth';

const goToCart = (props) => {
  props.onCartUpdate();
  if (Auth.isLoggedIn()) {
    props.history.push('/checkout/review');
  } else {
    props.history.push('/guest/checkout/review');
  }
};

export default {
  goToCart,
};
