import { StatsigClient } from '@statsig/js-client';

import Environment from './Environment';

const client = new StatsigClient(
  Environment.getStatsigKey,
  {},
  { environment: { tier: Environment.getStatsigEnvironment } }, // (optional) Configure SDK via StatsigOptions here
);

client.initializeAsync();

const Features = {
  checkGate: (featureName) => client.checkGate(featureName),
  logEvent: (eventName, metadata) => client.logEvent(eventName, metadata),
  getConfig: (configName) => client.getDynamicConfig(configName),
  updateUserAsync: (user, options = {}) =>
    client.updateUserAsync(user, options),
  getClient: () => client,
  getExperiment: (experimentName) => client.getExperiment(experimentName),

  featureIsEnabled: (featureName) => {
    return client.checkGate(featureName);
  },
};

export default Features;
