/* eslint-disable camelcase */
window.uetq = window.uetq || [];

const pageView = (page) => {
  window.uetq.push('event', 'page_view', { page_path: page });
};

const getItemsArray = (cartInfo) => {
  const lineItems = [];
  if (cartInfo.subscription && cartInfo.subscription.plan) {
    lineItems.push({
      price:
        cartInfo.subscription.plan.costWithCoupon ||
        cartInfo.subscription.plan.actualCost,
      quantity: 1,
      id: cartInfo.subscription.plan._id,
    });
  }
  if (cartInfo.productsAndDetails && cartInfo.productsAndDetails.length) {
    cartInfo.productsAndDetails.forEach((productAndDetails) => {
      lineItems.push({
        price:
          productAndDetails.promoItem && productAndDetails.product.ownPromoData
            ? productAndDetails.product.ownPromoData.cost
            : productAndDetails.product.actualCost || 0,
        quantity: productAndDetails.quantity || 1,
        id: productAndDetails.product._id,
      });
    });
  }
  return lineItems;
};

const getProductIdArray = (lineItems) => {
  const idArray = [];
  lineItems.forEach((lineItem) => {
    idArray.push(lineItem.id);
  });
  return idArray;
};

const addToCart = (itemToAddWithData) => {
  window.uetq.push('event', 'add_to_cart', {
    ecomm_prodid: [itemToAddWithData.productId],
    ecomm_pagetype: 'product',
    ecomm_totalvalue: itemToAddWithData.amountToCharge,
    revenue_value: itemToAddWithData.amountToCharge,
    currency: 'USD',
    items: [
      {
        id: itemToAddWithData.productId,
        quantity: itemToAddWithData.quantity,
        price: itemToAddWithData.amountToChargeForIndividualItem,
      },
    ],
  });
};

const purchaseComplete = (purchaseData, cartInfo) => {
  const items = getItemsArray(cartInfo);
  const productIds = getProductIdArray(items);
  window.uetq.push('event', 'purchase', {
    transaction_id: purchaseData.res.justPurchasedPurchaseId,
    ecomm_prodid: productIds,
    ecomm_pagetype: 'purchase',
    ecomm_totalvalue: parseFloat(purchaseData.total, 10),
    revenue_value: parseFloat(purchaseData.total, 10),
    currency: 'USD',
    items,
  });
};

export default {
  pageView,
  addToCart,
  purchaseComplete,
};
